import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import ActionButton from "../formInputs/buttons/ActionButton";
import { GUARDIAN_DASHBOARD } from "../../utils/auth/config";

import "../../styles/DcsdModalStyling.scss";

/**
 * @name DcsdFormModal
 * @param {*} param0
 * @returns a modal with various options
 * buttonCount - If you pass in buttonCount1 you will see one button
 * buttonCount- If you pass in buttonCount2 you will see 2 buttons
 * buttonLabel1 - This is the label for buttonCount1
 * buttonLabel2 - This is the label for buttonCount2
 * hasCloseX - bool. if passed in as false, then upper "X" (close) button will not be visible
 *
 */
const DcsdFormModal = ({
    ariaLabel,
    children,
    button1Label,
    button2Label,
    handleSubmit,
    hasCloseX,
    id = "",
    loader,
    open,
    setOpen,
    title = "Demo: You need a title.... Don't forget!"
}) => {
    const handleCloseDialog = () => {
        setOpen("false");
    };

    return (
        <Modal
            aria-label={ariaLabel}
            backdrop="static" // this "static" is so dialog will NOT close if outside click
            centered // this puts the Modal in the center of the page (up/down)
            scrollable
            onHide={handleCloseDialog}
            show={open === id}
            size="xl"
        >
            <div className="outer-container">
                {/* "closeButton" is the property passed into the header to enable "X" at top of modal to close*/}
                <Modal.Header closeButton={hasCloseX}>
                    {" "}
                    {/* true / false */}
                    <div className="text-color">
                        <Modal.Title>{title}</Modal.Title>
                    </div>
                </Modal.Header>
                <div className="text-color body">
                    <Modal.Body>{children}</Modal.Body>
                </div>
                {id === "looksIncorrect" && (
                    <Modal.Footer>
                        <a href={GUARDIAN_DASHBOARD}>
                            <ActionButton
                                ariaLabel="Back to Dashboard"
                                label={button1Label}
                                onClick={() => {
                                    sessionStorage.clear();
                                    return true;
                                }}
                            />
                        </a>
                    </Modal.Footer>
                )}
                {id === "duplicateParentName" && (
                    <Modal.Footer>
                        <ActionButton
                            ariaLabel="Open Incorrect dialog"
                            className="action-button-cancel"
                            label={button1Label}
                            onClick={() => {
                                setOpen("incorrect-name");
                            }}
                        />
                        <ActionButton
                            ariaLabel="Submit information button"
                            disable={loader}
                            label={button2Label}
                            onClick={() => {
                                handleSubmit();
                                setOpen("false");
                            }}
                        />
                    </Modal.Footer>
                )}
                {id === "incorrect-name" && (
                    <Modal.Footer>
                        <a href={GUARDIAN_DASHBOARD} rel="noreferrer">
                            <ActionButton
                                ariaLabel="Back to Dashboard"
                                label={button1Label}
                                onClick={() => {
                                    sessionStorage.clear();
                                    return true;
                                }}
                            />
                        </a>
                    </Modal.Footer>
                )}
            </div>
        </Modal>
    );
};

DcsdFormModal.propTypes = {
    ariaLabel: PropTypes.string,
    button1Label: PropTypes.string,
    button2Label: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object]),
    handleSubmit: PropTypes.func,
    hasCloseX: PropTypes.bool,
    heading: PropTypes.string,
    id: PropTypes.string,
    loader: PropTypes.bool,
    message1: PropTypes.string,
    message2: PropTypes.string,
    open: PropTypes.string,
    setOpen: PropTypes.func.isRequired,
    title: PropTypes.string
};

DcsdFormModal.defaultProps = {
    ariaLabel: "",
    children: null,
    handleSubmit: null,
    hasCloseX: true,
    loader: false
};

export default DcsdFormModal;
