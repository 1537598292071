import PropTypes from "prop-types";
import ServiceWrapper from "../utils/ServiceWrapper";
import { SERVICE_HOST } from "../utils/auth/config";

/**
 * Data Access for the User API
 * @name UserDao
 * @param props
 * @return {null|*}
 */
const ArcGisDao = (props) => {
    const { action, params, token } = props;
    const bearer = `Bearer ${token}`;
    const ARCGIS_LOCATION_SEARCHABLE_GET = `${SERVICE_HOST}/arcgis-sb/v0/arcgis/by_address/index.json`;
    // @TODO: add "Access-Control-Max-Age" as an allowed header on the User Service
    const options = {
        headers: {
            Authorization: bearer
        },
        withCredentials: true
    };
    switch (action) {
        case "arcGisSearchableRead":
            options.method = "GET";
            options.url = ARCGIS_LOCATION_SEARCHABLE_GET;
            if (params) {
                options.params = params;
            }
            break;
        default:
            return null;
    }

    return ServiceWrapper.serviceCall({
        options,
        ...props
    });
};

ArcGisDao.propTypes = {
    action: PropTypes.string.isRequired,
    icId: PropTypes.number,
    params: PropTypes.oneOfType([PropTypes.object]),
    token: PropTypes.string.isRequired,
    username: PropTypes.string
};

ArcGisDao.defaultProps = {
    icId: null,
    params: null,
    username: null
};

export default ArcGisDao;
