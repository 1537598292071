import React from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "../../styles/CheckboxButton.scss";

/**
 * A Button that looks and acts like a checkbox
 * @name CheckboxButton
 * @param {func} handleClick
 * @param {string} id
 * @param {bool} isChecked
 * @param {bool} isDisabled
 * @param {string|null} label
 * @param {string} name
 * @param {string} valu
 * @return {JSX.Element}
 * @constructor
 */
const CheckboxButton = ({ handleChange, id, isChecked, isDisabled, label, name, school, value }) => {
    const getSchoolName = (name) => {
        const { educationalTrack } = school;
        let schoolName = name;
        if (educationalTrack !== "NOT_APPLICABLE") {
            const tmpArr = educationalTrack.split("_");
            const tmp = `${tmpArr[0].charAt(0).toUpperCase()}${tmpArr[0].slice(1).toLowerCase()} ${tmpArr[1].charAt(0).toUpperCase()}${tmpArr[1].slice(1).toLowerCase()}`;
            schoolName = `${name} - ${tmp}`;
        }

        return schoolName;
    };

    const checkClass = isChecked ? " checked" : "";
    const getTooltip = () => {
        const { locationDto, locationId } = school;
        const { city, name, state, street, url, zip } = locationDto;

        return (
            <div className="tooltip-school-container">
                <img
                    alt={getSchoolName(name) || ""}
                    height="90"
                    src={`${window.location.origin}/images/${locationId}.png`}
                    width="90"
                />
                <div className="school-details">
                    <h6>{getSchoolName(name)}</h6>
                    <div className="spacer"> </div>
                    <span>
                        <i className="bi bi-globe2" /> {url || ""}
                    </span>
                    <br />
                    <span>
                        <i className="bi bi-geo-alt-fill" /> {`${street}, ${city}, ${state} ${zip}`}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <OverlayTrigger
            delay={{ hide: 5, show: 300 }}
            overlay={(props) => (
                <Tooltip className="tooltip-school" {...props}>
                    {getTooltip()}
                </Tooltip>
            )}
            placement="top"
        >
            <div className={`checkbox-small-button${checkClass}`}>
                <label htmlFor={id} className="text-truncate">
                    <input
                        id={id}
                        checked={isChecked}
                        disabled={isDisabled}
                        name={name}
                        onChange={handleChange}
                        tabIndex={0}
                        type="checkbox"
                        value={value}
                    />
                    {getSchoolName(label)}
                </label>
            </div>
        </OverlayTrigger>
    );
};

CheckboxButton.propTypes = {
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    school: PropTypes.oneOfType([PropTypes.object]).isRequired,
    value: PropTypes.string
};

CheckboxButton.defaultProps = {
    id: "",
    isChecked: false,
    isDisabled: false,
    label: "",
    name: "",
    value: ""
};

export default CheckboxButton;
