import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "../contextProvider/ContextProvider";
import CheckboxReason from "../formInputs/CheckboxReason";

import "../../styles/ReasonsBlock.scss";

const SelectedSchoolReasons = ({ formDispatch, formState, reasons, school }) => {
    const { state } = useContext(GlobalContext);
    const { oeActiveDistrictWindow, oeStudentInfo } = state || {};

    const [kinderOffered, setKinderOffered] = useState(null);
    const [langOffered, setLangOffered] = useState(null);
    const [showKinder, setShowKinder] = useState(false);

    const { schoolDto } = school;

    const getKinderName = (val) => {
        if (val === "K_FULL") {
            return "Kindergarten - Full Day";
        } else if (val === "K_HALF_AM") {
            return "Kindergarten - Half Day AM";
        } else if (val === "K_HALF_PM") {
            return "Kindergarten - Half Day PM";
        }

        return val;
    };

    const handleKinderChange = (e) => {
        const { checked, name, value } = e.target;
        const tmpFormState = structuredClone(formState);
        const { schoolChoiceDetailDtos } = tmpFormState;
        const nameArr = name.split("-");
        const schoolKey = nameArr[1];
        const matchKinders = schoolChoiceDetailDtos.filter(
            (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(schoolKey, 10)
        );
        if (checked) {
            if (matchKinders && matchKinders.length > 0) {
                const { kinderChoice } = matchKinders[0];
                if (kinderChoice && kinderChoice.length > 0) {
                    matchKinders[0].kinderChoice.push(value);
                } else {
                    const newKinder = [value];
                    matchKinders[0].kinderChoice = newKinder;
                }
            }
        } else {
            if (matchKinders && matchKinders.length > 0) {
                const match2 = matchKinders[0].kinderChoice.filter(
                    (item) => item.toLowerCase() !== value.toLowerCase()
                );
                matchKinders[0].kinderChoice = match2;
            }
        }
        formDispatch({
            type: "reset",
            payload: { ...tmpFormState }
        });

        return true;
    };

    const handleLanguageChange = (e) => {
        const { checked, name, value } = e.target;
        const tmpFormState = structuredClone(formState);
        const { schoolChoiceDetailDtos } = tmpFormState;
        const nameArr = name.split("-");
        const schoolKey = nameArr[1];
        const schoolMatch = schoolChoiceDetailDtos.filter(
            (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(schoolKey, 10)
        );
        if (schoolMatch && schoolMatch.length > 0) {
            if (checked) {
                if (schoolMatch[0].languageChoice && schoolMatch[0].languageChoice.length > 0) {
                    schoolMatch[0].languageChoice.push(value);
                } else {
                    schoolMatch[0].languageChoice = [value];
                }
            } else {
                const match2 = schoolMatch[0].languageChoice.filter(
                    (item) => item.toLowerCase() !== value.toLowerCase()
                );
                schoolMatch[0].languageChoice = match2;
            }
        }

        formDispatch({
            type: "reset",
            payload: { ...tmpFormState }
        });
    };

    const handleReasonChange = (e) => {
        const { checked, name, value } = e.target;
        const tmpFormState = structuredClone(formState);
        const { schoolChoiceDetailDtos } = tmpFormState;
        const nameArr = name.split("-");
        const schoolKey = nameArr[1];
        const reasonKey = nameArr[3];
        const schoolMatch = schoolChoiceDetailDtos.filter(
            (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(schoolKey, 10)
        );
        if (schoolMatch && schoolMatch.length > 0) {
            const existingSchoolChoice = oeStudentInfo.schoolChoiceDetailDtos.filter(
                (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(schoolDto.key, 10)
            );
            let existingReasons = [];
            if (existingSchoolChoice && existingSchoolChoice.length > 0) {
                existingReasons = existingSchoolChoice[0].reasonsForChoosing.filter(
                    (obj) => parseInt(obj.questionId, 10) === parseInt(reasonKey, 10)
                );
            }
            const reasonObj = reasons.filter((obj) => parseInt(obj.key, 10) === parseInt(reasonKey, 10));
            if (nameArr[4] && nameArr[4] === "comments") {
                if (reasonObj && reasonObj.length > 0) {
                    const match2 = schoolMatch[0].reasonsForChoosing.filter(
                        (obj) => parseInt(obj.questionId, 10) === parseInt(reasonKey, 10)
                    );
                    if (match2 && match2.length > 0) {
                        match2[0].comments = value;
                    }
                }
                formDispatch({
                    type: "reset",
                    payload: { ...tmpFormState }
                });

                return true;
            }
            if (checked) {
                if (schoolMatch[0].reasonsForChoosing && schoolMatch[0].reasonsForChoosing.length > 0) {
                    schoolMatch[0].reasonsForChoosing.push({
                        comments: existingReasons && existingReasons.length > 0 ? existingReasons[0]?.comments : "",
                        key: existingReasons && existingReasons.length > 0 ? existingReasons[0].key : null,
                        locationKey: schoolDto.locationDto.key,
                        questionId: reasonKey,
                        questionnaireType: "NEW_CHOICE",
                        schoolChoiceId:
                            existingReasons && existingReasons.length > 0 ? existingReasons[0].schoolChoiceId : null,
                        schoolYearKey: oeActiveDistrictWindow.schoolYearKey,
                        studentId: oeStudentInfo.studentDto.key
                    });
                } else {
                    const newReason = {
                        comments: existingReasons && existingReasons.length > 0 ? existingReasons[0]?.comments : "",
                        key: existingReasons && existingReasons.length > 0 ? existingReasons[0]?.key : null,
                        locationKey: schoolDto.locationDto.key,
                        questionId: reasonKey,
                        questionnaireType: "NEW_CHOICE",
                        schoolChoiceId:
                            existingReasons && existingReasons.length > 0 ? existingReasons[0].schoolChoiceId : null,
                        schoolYearKey: oeActiveDistrictWindow.schoolYearKey,
                        studentId: oeStudentInfo.studentDto.key
                    };
                    schoolMatch[0].reasonsForChoosing = [newReason];
                }
            } else {
                if (schoolMatch[0].reasonsForChoosing && schoolMatch[0].reasonsForChoosing.length > 0) {
                    const match2 = schoolMatch[0].reasonsForChoosing.filter(
                        (obj) => parseInt(obj.questionId, 10) !== parseInt(reasonKey, 10)
                    );
                    schoolMatch[0].reasonsForChoosing = match2;
                }
            }
        }
        formDispatch({
            type: "reset",
            payload: { ...tmpFormState }
        });

        return true;
    };

    useEffect(() => {
        if (school) {
            const { tags } = schoolDto.locationDto;
            const langsOffered = tags.filter((obj) => obj.type === "LANGUAGE");
            if (langsOffered && langsOffered.length > 0) {
                setLangOffered(langsOffered);
            } else {
                setLangOffered(null);
            }
            const kindsOffered = tags.filter((obj) => obj.type === "KINDER_OFFERED");
            if (kindsOffered && kindsOffered.length > 0) {
                setKinderOffered(kindsOffered);
            } else {
                setKinderOffered(null);
            }
        }
    }, [school, schoolDto.locationDto]);

    useEffect(() => {
        if (oeStudentInfo) {
            const { studentDto } = oeStudentInfo;
            const { gradeApplying } = studentDto;
            if (gradeApplying === "K") {
                setShowKinder(true);
            } else {
                setShowKinder(false);
            }
        }
    }, [oeStudentInfo]);

    return (
        <>
            {school ? (
                <div className="reasons-background">
                    <div className="reasons-label">
                        <div>Reasons for Applying: {schoolDto.locationDto.name} *</div>
                    </div>
                    <hr />
                    <div>
                        <p>
                            Please indicate{" "}
                            <u>
                                <strong>at least one</strong>
                            </u>{" "}
                            reason for applying for Open Enrollment at this school. You may indicate as many reasons as
                            are applicable, and you may adjust at any time during the parent window.
                        </p>
                    </div>
                    <div className="reasons-flex">
                        {reasons &&
                            reasons.map((reason, index) => {
                                let comments = "";
                                let isChecked = false;
                                const uniqueKey = `reason-${schoolDto.key}-${index}`;
                                const { schoolChoiceDetailDtos } = formState;
                                const match = schoolChoiceDetailDtos.filter(
                                    (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(schoolDto.key)
                                );
                                if (match && match.length > 0) {
                                    const { reasonsForChoosing } = match[0];
                                    const match2 = reasonsForChoosing.filter(
                                        (obj) => parseInt(obj.questionId, 10) === parseInt(reason.key, 10)
                                    );
                                    if (match2 && match2.length > 0) {
                                        isChecked = true;
                                        comments = match2[0].comments;
                                    }
                                }

                                return (
                                    <CheckboxReason
                                        comments={comments}
                                        handleChange={handleReasonChange}
                                        id={`school-${schoolDto.key}-reason-${reason.key}`}
                                        isChecked={isChecked}
                                        key={uniqueKey}
                                        label={reason.questionText}
                                        name={`school-${schoolDto.key}-reason-${reason.key}`}
                                        value={reason.key}
                                    />
                                );
                            })}
                    </div>
                    <div className="input-subtext mb-3" style={{ margin: "0 auto" }}>
                        Note: If you select &ldquo;Other&rdquo; as a reason for applying, you must enter a comment in
                        order to submit your school choice.
                    </div>
                    {showKinder && kinderOffered && kinderOffered.length > 0 && (
                        <>
                            <br />
                            <div className="reasons-label">
                                <div>Kinder Options: *</div>
                            </div>
                            <hr />
                            <div>
                                <p>
                                    The school choices you have selected may have multiple kindergarten programming
                                    options. Please select all options you are interested in for your student.{" "}
                                    <b>At least one option must be selected.</b>
                                </p>
                            </div>
                            <div className="reasons-container reasons-flex-col">
                                {kinderOffered.map((obj, index) => {
                                    const { schoolChoiceDetailDtos } = formState;
                                    const schoolMatch = schoolChoiceDetailDtos.filter(
                                        (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(schoolDto.key, 10)
                                    );
                                    const { tag } = obj;
                                    const priorityArr = ["1st Choice", "2nd Choice", "3rd Choice"];
                                    const uniqueKey = `language-${schoolDto.key}-${index}`;
                                    let isChecked = false;
                                    const { kinderChoice } = schoolMatch[0];
                                    let pIndex = -1;
                                    if (kinderChoice && kinderChoice?.length > 0) {
                                        pIndex = kinderChoice?.findIndex(
                                            (item) => item.toLowerCase() === tag.toLowerCase()
                                        );
                                    }
                                    if (pIndex !== -1) {
                                        isChecked = true;
                                    }
                                    return (
                                        <CheckboxReason
                                            className="options"
                                            handleChange={handleKinderChange}
                                            id={`school-${schoolDto.key}-kinder-${index}`}
                                            isChecked={isChecked}
                                            key={uniqueKey}
                                            label={getKinderName(tag)}
                                            name={`school-${schoolDto.key}-kinder-${index}`}
                                            priority={priorityArr[pIndex] || ""}
                                            value={tag}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {langOffered && langOffered.length > 0 && (
                        <>
                            <br />
                            <div className="reasons-label">
                                <div>Language Options: *</div>
                            </div>
                            <hr />
                            <div>
                                <p>
                                    The school you have selected has multiple language options. Please select all
                                    options you are interested in for your student, in the order of your preference.{" "}
                                    <b>At least one option must be selected.</b>
                                </p>
                            </div>
                            <div className="reasons-container reasons-flex-col">
                                {langOffered.map((obj, index) => {
                                    const { schoolChoiceDetailDtos } = formState;
                                    const schoolMatch = schoolChoiceDetailDtos.filter(
                                        (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(schoolDto.key, 10)
                                    );
                                    const { tag } = obj;
                                    const priorityArr = ["1st Choice", "2nd Choice", "3rd Choice"];
                                    const uniqueKey = `language-${schoolDto.key}-${index}`;
                                    let isChecked = false;
                                    const { languageChoice } = schoolMatch[0];
                                    let pIndex = -1;
                                    if (languageChoice && languageChoice.length > 0) {
                                        pIndex = languageChoice?.findIndex(
                                            (item) => item.toLowerCase() === tag.toLowerCase()
                                        );
                                    }
                                    if (pIndex !== -1) {
                                        isChecked = true;
                                    }
                                    return (
                                        <CheckboxReason
                                            className="options"
                                            handleChange={handleLanguageChange}
                                            id={`school-${schoolDto.key}-language-${index}`}
                                            isChecked={isChecked}
                                            key={uniqueKey}
                                            label={tag}
                                            name={`school-${schoolDto.key}-language-${index}`}
                                            priority={priorityArr[pIndex] || ""}
                                            value={tag}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            ) : null}
        </>
    );
};

SelectedSchoolReasons.propTypes = {
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    reasons: PropTypes.instanceOf(Array).isRequired,
    school: PropTypes.oneOfType([PropTypes.object])
};

SelectedSchoolReasons.defaultProps = {
    school: null
};

export default SelectedSchoolReasons;
