import React from "react";
import PropTypes from "prop-types";

import "../../styles/AddressBlock.scss";
import "../../styles/global/Global.scss";

/**
 * @name GuardianAddressBlock
 * @param {*} param0
 * @returns a Grey background block with user email and address
 * !!!!!! This is the GUARDIANS address block only. Top left of screen. !!!!!!
 */
const GuardianAddressBlock = ({ guardianInfo, label1, name }) => {
    return (
        <div className="addressBlock-background">
            <div className="address-block-label">{`${label1} Current Information:`}</div>
            <hr />
            {name === "new" && guardianInfo && (
                <>
                    <div className="email-container">
                        <div className="email-label">Email:</div>
                        <div className="email">{guardianInfo?.email ? guardianInfo?.email : "Unavailable"}</div>
                    </div>
                    <div className="email-container">
                        <div className="email-label">Address:</div>
                        <div className="email">
                            {guardianInfo?.addressDto?.addressLine1
                                ? `${guardianInfo?.addressDto?.addressLine1} ${guardianInfo?.addressDto?.city} ${guardianInfo?.addressDto?.state} ${guardianInfo?.addressDto?.zip}`
                                : "Unavailable"}
                        </div>
                    </div>
                    <div className="email-container">
                        <div className="email-label">Phone:</div>
                        <div className="email">{guardianInfo?.cellPhone ? guardianInfo?.cellPhone : "Unavailable"}</div>
                    </div>
                </>
            )}
            {name === "edit" && guardianInfo && (
                <>
                    <div className="email-container">
                        <div className="email-label">Email:</div>
                        <div className="email">{guardianInfo.email ? guardianInfo.email : "Unavailable"}</div>
                    </div>
                    <div className="email-container">
                        <div className="email-label">Address:</div>
                        <div className="email">
                            {guardianInfo.addressDto?.addressLine1
                                ? `${guardianInfo.addressDto?.addressLine1} ${guardianInfo.addressDto?.city} ${guardianInfo.addressDto?.state} ${guardianInfo?.addressDto?.zip}`
                                : "Unavailable"}
                        </div>
                    </div>
                    <div className="email-container">
                        <div className="email-label">Phone:</div>
                        <div className="email">{guardianInfo?.cellPhone ? guardianInfo?.cellPhone : "Unavailable"}</div>
                    </div>
                </>
            )}
            {/* View only 
                SingleExistingParent Dto is being generated at the top of the file.
                If the student has more than one guardian, we will take the guardian that is 
                signed in that has userDetails
                
                - !!! If IC data and has more than 1 parent !!! -
            */}
            {name === "view" && guardianInfo && (
                <>
                    <div className="email-container">
                        <div className="email-label">Email:</div>
                        <div className="email">{guardianInfo.email ? guardianInfo.email : "Unavailable"}</div>
                    </div>
                    <div className="email-container">
                        <div className="email-label">Address:</div>
                        <div className="email">
                            {guardianInfo.addressDto?.addressLine1
                                ? `${guardianInfo.addressDto?.addressLine1} ${guardianInfo.addressDto?.city} ${guardianInfo.addressDto?.state} ${guardianInfo?.addressDto?.zip}`
                                : "Unavailable"}
                        </div>
                    </div>
                    <div className="email-container">
                        <div className="email-label">Phone:</div>
                        <div className="email">{guardianInfo.cellPhone ? guardianInfo.cellPhone : "Unavailable"}</div>
                    </div>
                </>
            )}
        </div>
    );
};

GuardianAddressBlock.propTypes = {
    guardianInfo: PropTypes.oneOfType([PropTypes.object]),
    label1: PropTypes.string,
    name: PropTypes.string
};

GuardianAddressBlock.defaultProps = {
    name: ""
};

export default GuardianAddressBlock;
