// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.body {
  font-weight: 500;
}
.body .age {
  text-decoration: underline;
  font-weight: 600;
}

.title {
  color: #0669B3;
}

.text-color {
  color: #0669B3;
}
.text-color span {
  font-weight: 700;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/styles/global/Colors.scss","webpack://./src/styles/Modals.scss"],"names":[],"mappings":"AAAA;;;EAGI,sBAAA;ACCJ;;ADJA;;;EAGI,sBAAA;ACOJ;;AALA;EACI,gBAAA;AAQJ;AAPI;EACI,0BAAA;EACA,gBAAA;AASR;;AANA;EACI,cDYS;ACHb;;AAPA;EACI,cDSS;ACCb;AATI;EACI,gBAAA;EACA,0BAAA;AAWR","sourcesContent":["*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n// Open Enrollment colors\n$lt-blue: #7DB7CC;\n// $dk-blue: #094166;\n$dk-blue: #19608f;\n$information: #00AEEF;\n$information-hover: #02BFFF;\n$text: #414042;\n$white: #EDEDED;\n$shadow: #525151;\n$red: #CB321A;\n$green: #C7CC68;\n\n$important: #FEBF31;\n$grey0: #EDf0F1;\n$grey1: #D1D3D4;\n$grey2: #A7A9AC;\n$grey3: #808080;\n$grey3a: #707070;\n$grey4: #636161;\n$grey4a: #444343;\n$pearl-blue: #0669B3;\n$pearl-blue-hover: #1786DB;\n$lt-pearl-blue: #63AEDE;\n$lt-pearl-blue-hover: #4589B3;\n","@import \"../styles/global/Colors\";\n@import \"../styles/global/globalMixins\";\n\n// ! Important ! //\n// All styling that needs to be done for Open Enrollment modals (text & layout) is done in this SINGLE file\n.body {\n    font-weight: 500;\n    .age {\n        text-decoration: underline;\n        font-weight: 600;\n    }\n}\n.title {\n    color: $pearl-blue;\n}\n.text-color {\n    color: $pearl-blue;\n    span {\n        font-weight: 700;\n        text-decoration: underline;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
