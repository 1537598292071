import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "../contextProvider/ContextProvider";
import Icon from "../icon/Icon";
import ActionButton from "../formInputs/buttons/ActionButton";

import "../../styles/SchoolCard.scss";

const SchoolCard = ({
    formDispatch,
    formState,
    index,
    oeStudentInfo,
    reasons,
    school,
    selectedSchoolTab,
    setSelectedSchoolTab
}) => {
    const { state } = useContext(GlobalContext);
    const { oeActiveDistrictWindow } = state || {};
    const { enrollmentRound } = oeActiveDistrictWindow;
    const [reasonStatus, setReasonStatus] = useState("IN_PROGRESS");

    const { studentDto } = oeStudentInfo;
    const { status } = school;
    const { gradeApplying } = studentDto;
    const { schoolDto } = school;
    const { educationalTrack, locationDto, locationId } = schoolDto;

    const getStatusText = (status) => {
        let stat = status;
        switch (status) {
            case "ACCEPT_OFFER":
                stat = "Accepted Offer";
                break;
            case "CANCELLED_REQUEST":
                stat = "Cancelled Request";
                break;
            case "CHANGED_ENROLLMENT":
                stat = "Unenrolled";
                break;
            case "CHOICE_CREATED":
                stat = "School Choice Created";
                break;
            case "CHOICE_INCOMPLETE":
                stat = "Choice Incomplete";
                break;
            case "DENY_OFFER":
                stat = "Denied Offer";
                break;
            case "DENY_REQUEST":
                stat = "Denied Request";
                break;
            case "ENROLLED":
                stat = "Enrolled";
                break;
            case "INVALID":
                stat = "Invalid";
                break;
            case "MAKE_OFFER":
                stat = "Offer Made";
                break;
            case "MAKE_OFFER_PENDING":
                stat = "Make Offer Pending";
                break;
            case "NO_RESPONSE":
                stat = "No Response";
                break;
            case "WAIT_LIST":
                stat = "On Wait List";
                break;
            case "REQUEST":
                stat = "Request Made";
                break;
            case "UNENROLLED":
                stat = "Unenrolled";
                break;
            default:
                stat = "Unavailable";
                break;
        }

        return stat;
    };

    const handleRemove = (e) => {
        e.stopPropagation();
        if (["CHANGED_ENROLLMENT", "ENROLLED"].includes(school.status) && enrollmentRound === "ROUND2") {
            return false;
        }
        const tmpFormState = structuredClone(formState);
        const { schoolChoiceDetailDtos } = tmpFormState;
        const match = schoolChoiceDetailDtos.filter(
            (obj) => parseInt(obj.participatingSchoolId, 10) !== parseInt(schoolDto.key, 10)
        );
        tmpFormState.schoolChoiceDetailDtos = match;
        if (selectedSchoolTab > 0 && index <= selectedSchoolTab && schoolChoiceDetailDtos.length > 0) {
            if (selectedSchoolTab > 0) {
                setSelectedSchoolTab(selectedSchoolTab - 1);
            } else {
                setSelectedSchoolTab(0);
            }
        }
        formDispatch({
            type: "reset",
            payload: { ...tmpFormState }
        });
    };

    const getSchoolName = (name) => {
        let schoolName = name;
        if (educationalTrack !== "NOT_APPLICABLE") {
            const tmpArr = educationalTrack.split("_");
            const tmp = `${tmpArr[0].charAt(0).toUpperCase()}${tmpArr[0].slice(1).toLowerCase()} ${tmpArr[1].charAt(0).toUpperCase()}${tmpArr[1].slice(1).toLowerCase()}`;
            schoolName = `${name} - ${tmp}`;
        }

        return schoolName;
    };

    const showTrashCan = () => {
        if (["CHANGED_ENROLLMENT", "ENROLLED"].includes(school.status) && enrollmentRound === "ROUND2") {
            return false;
        }

        return true;
    };

    const showEnrolled = () => {
        if (["ENROLLED"].includes(school.status) && enrollmentRound === "ROUND2") {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (formState && school && studentDto) {
            const { kinderChoice, languageChoice, reasonsForChoosing } = school;
            let isKinderSchool = studentDto.gradeApplying === "K";
            let isLanguageSchool = false;
            let kinderSelected = false;
            let languageSelected = false;
            let reasonSelected = false;

            if (isKinderSchool) {
                const match = locationDto.tags?.filter((obj) => obj.type === "KINDER_OFFERED");
                if (match && match.length > 0) {
                    if (kinderChoice && kinderChoice.length > 0) {
                        kinderSelected = true;
                    } else {
                        kinderSelected = false;
                    }
                }
            } else {
                kinderSelected = true;
            }

            const match3 = locationDto.tags?.filter((obj) => obj.type === "LANGUAGE");
            if (match3 && match3.length > 0) {
                isLanguageSchool = true;
                if (languageChoice && languageChoice.length > 0) {
                    languageSelected = true;
                } else {
                    languageSelected = false;
                }
            }

            if (reasonsForChoosing.length > 0) {
                let otherComment = [];
                const otherReason = reasons.filter((obj) => obj.questionText.toLowerCase() === "other");
                if (otherReason && otherReason.length > 0) {
                    otherComment = reasonsForChoosing.filter(
                        (obj) => parseInt(obj.questionId, 10) === parseInt(otherReason[0].key)
                    );
                }
                if (otherComment && otherComment.length > 0) {
                    const { comments } = otherComment[0];
                    if (comments && comments.length > 0) {
                        reasonSelected = true;
                    } else {
                        reasonSelected = false;
                    }
                } else {
                    reasonSelected = true;
                }
            } else {
                reasonSelected = false;
            }

            if (reasonSelected) {
                if (isLanguageSchool) {
                    if (languageSelected) {
                        if (isKinderSchool) {
                            if (kinderSelected) {
                                setReasonStatus("COMPLETE");
                            } else {
                                setReasonStatus("IN_PROGRESS");
                            }
                        } else {
                            setReasonStatus("COMPLETE");
                        }
                    } else {
                        setReasonStatus("IN_PROGRESS");
                    }
                } else if (isKinderSchool) {
                    if (kinderSelected) {
                        setReasonStatus("COMPLETE");
                    } else {
                        setReasonStatus("IN_PROGRESS");
                    }
                } else {
                    setReasonStatus("COMPLETE");
                }
            } else {
                setReasonStatus("IN_PROGRESS");
            }
        }
    }, [formState, locationDto.tags, reasons, school, studentDto]);

    return (
        <div className="card-container">
            <div className="delete-container">
                {showTrashCan() && (
                    <ActionButton className="action-button-small-trashCan" onClick={handleRemove}>
                        <Icon fill="white" height={15} iconName="TRASHCAN" width={20} />
                    </ActionButton>
                )}
                {showEnrolled() && <div className="enrolled" />}
            </div>
            <div className="school-photo-container">
                <div className="school-logo">
                    <img
                        alt={locationDto.name}
                        height="130"
                        src={`${window.location.origin}/images/${locationId}.png`}
                        width="130"
                    />
                </div>
            </div>
            <div className="school-info-container">
                <div className="info">
                    <div className="school-name">{getSchoolName(locationDto.name)}</div>
                    <div className="school-grade">
                        Applying for Grade:<span>{gradeApplying}</span>
                    </div>
                    <div className="status-container">
                        Status: <span>{getStatusText(status)}</span>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="school-card-button-container">
                    <span className="action-button-reason">
                        <div>Select Reasons</div>
                    </span>
                    <span className="select-button-icon">
                        <Icon iconName={reasonStatus} fill="#FEBf31" height={20} width={20} />
                    </span>
                </div>
            </div>
        </div>
    );
};

SchoolCard.propTypes = {
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    index: PropTypes.number.isRequired,
    oeStudentInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
    reasons: PropTypes.instanceOf(Array).isRequired,
    school: PropTypes.oneOfType([PropTypes.object]).isRequired,
    selectedSchoolTab: PropTypes.number.isRequired,
    setSelectedSchoolTab: PropTypes.func.isRequired
};

export default SchoolCard;
