import React from "react";
import PropTypes from "prop-types";

import "../../../styles/DcsdModalStyling.scss";

const InformationIncorrectListForm = ({ formState }) => {
    return (
        <div className="outer-container">
            <div className="heading">
                We are sorry that something is wrong with the student information for
                <u>
                    <strong>
                        {" "}
                        {formState?.firstName} {formState?.lastName}
                    </strong>
                </u>
            </div>
            <div className="instructions-container">
                <div className="top-sentence">
                    Please follow the steps below before beginning your Open Enrollment request ...{" "}
                </div>
                <div className="number-container">
                    <div className="number">1</div>
                    <div className="instruction">
                        Please contact your student&apos;s school to request a data correction. The school may be able
                        to immediately process this request or it may take some time. Thank you for your patience.
                    </div>
                </div>
                <div className="number-container">
                    <div className="number">2</div>
                    <div className="instruction">
                        Once you&apos;ve received confirmation from the school that the data correction has been
                        completed, please come back to begin your Open Enrollment request where you will have an
                        opportunity to affirm the accuracy of the updated student information.
                    </div>
                </div>
                <div className="number-container">
                    <div className="number">3</div>
                    <div className="instruction">
                        Once you&apos;ve confirmed the accuracy of the information, you are invited to proceed with your
                        Open Enrollment request.
                    </div>
                </div>
            </div>
        </div>
    );
};

InformationIncorrectListForm.propTypes = {
    formState: PropTypes.oneOfType([PropTypes.object])
};

InformationIncorrectListForm.defaultProps = {
    formState: null
};

export default InformationIncorrectListForm;
