import React from "react";
import PropTypes from "prop-types";

import "../../styles/AddressBlock.scss";
import "../../styles/global/Global.scss";

/**
 * @name StudentAddressBlock
 * @param {*} param0
 * @returns a Grey background block with user email and address
 * !!!!!! This is the STUDENTS address block only. Top right of screen. !!!!!!
 */
const StudentAddressBlock = ({ label1, studentIcData }) => {
    return (
        <div className="addressBlock-background">
            <div className="address-block-label">{`${label1} Current Information:`}</div>
            <hr />
            {label1 === "Student's" && (
                <>
                    <div className="email-container">
                        <div className="email-label">Address:</div>
                        {studentIcData ? (
                            <div className="email">{`${studentIcData?.address?.addressLine1} ${studentIcData?.address?.city} ${studentIcData?.address?.state} ${studentIcData?.address?.zip}`}</div>
                        ) : (
                            <div className="email">
                                {studentIcData?.address?.addressLine1
                                    ? `${studentIcData?.address?.addressLine1} ${studentIcData?.address?.city} ${studentIcData?.address?.state} ${studentIcData?.address?.zip}`
                                    : "Unavailable"}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

StudentAddressBlock.propTypes = {
    label1: PropTypes.string,
    studentIcData: PropTypes.oneOfType([PropTypes.object])
};

StudentAddressBlock.defaultProps = {
    label1: "",
    studentIcData: null
};

export default StudentAddressBlock;
