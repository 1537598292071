import React from "react";
/// ////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////
/// ////////////////////// Mandarin ////////////////////////////
/// ////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////
/* eslint-disable */
// eslint-disable-next-line import/prefer-default-export
export const data = {
    /// ////////////////////////////////////////////////////////////
    /// /////////////// Interrupt //////////////////////////////////
    /// ////////////////////////////////////////////////////////////
    Interrupt_header: (
        <b>EngagED家长门户登录流程（内部/外部）</b>
    ),
    Interrupt_p1: (
        <>
            要登录 EngagED 家长门户，您需要将您的 <b>Infinite Campus 家长门户用户名</b>（外部家长：在设置帐户时创建的用户名） <u>和</u> <b>@p.dcsdk12.org</b> 配对。例如：如果您的 Infinite Campus 用户名是 <b>jsmith</b>，您将在接下来的登录屏幕上输入 <b>jsmith@p.dcsdk12.org</b> 作为您的电子邮件地址。您的 <b>密码</b> 与您访问 Infinite Campus 时使用的密码相同（外部家长：在设置帐户时创建的密码）。
        </>
    ),
    Interrupt_p2: (
        <>
            <b>如果您忘记了 DCSD 的登录凭证</b>:<br />
            使用这些链接来{" "}
            <a
                href="https://iaas5idm.fischeridentity.com/identity/self-service/dcs/forgotuserid.jsf"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                找回您的用户名
            </a>{" "}
            和/或{" "}
            <a
                href="https://iaas5idm.fischeridentity.com/identity/self-service/dcs/kiosk.jsf"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                重置您的密码
            </a>
            .
        </>
    ),
    Interrupt_p3: (
        <>
            <b>
                如果您已按照上述步骤操作但仍然遇到困难，请联系我们
            </b>
            :<br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                家长技术支持 - 英文
            </a>
            <br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT@LFLT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                家长技术支持 - 西班牙文
            </a>
            <br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT@LRLT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                家长技术支持 - 中文
            </a>
            <br />
            IT支持中心电话 <b>303.387.0001</b>
        </>
    ),
    Interrupt_note:
        "注意：目前为人父母的学区员工应使用隐身或私密浏览窗口和/或不同的浏览器，按照上述说明进行操作。",
    Interrupt_submit: "继续登录",
    /// /////////////////////////////////////////////////////////
    /// /////////////// Header //////////////////////////////////
    /// /////////////////////////////////////////////////////////
    Main_Nav_Welcome: "欢迎,",
    Main_Nav_HelpfulLinks: "有用的网址",
    Main_Nav_LogOut: "登出",
    Main_Nav_HelpfulLinks_LI_s1: "联系我的学生的护士",
    Main_Nav_HelpfulLinks_LI_s2: "学术日历",
    Main_Nav_HelpfulLinks_LI_s3: "家长资源",
    Main_Nav_HelpfulLinks_LI_s4: "公交路线",
    Main_Nav_HelpfulLinks_LI_s5: "在线支付",
    Main_Nav_HelpfulLinks_LI_s6: "午餐菜单",
    Main_Nav_Exit: "出口",
};
/* eslint-enable */
