import PropTypes from "prop-types";
import ServiceWrapper from "../utils/ServiceWrapper";
import {
    OE_ACTION_CONTROLS,
    OE_ACTIVE_CONTROLS,
    OE_ACTIVE_DISTRICT_WINDOW,
    OE_NEW_STUDENT,
    OE_GUARDIAN,
    OE_GUARDIAN_EMAIL,
    OE_REASONS,
    OE_SCHOOLS_BY_STUDENT_AND_ROUND,
    OE_STUDENT_INFO,
    OE_STUDENT_BIRTHDATE,
    OE_STUDENT
} from "../const/OpenEnrollmentConst";

/**
 * Data Access for the Echeckin Service
 * @name EcheckinDao
 * @param props
 * @return {null|*}
 */
const OpenEnrollmentDao = (props) => {
    const { action, data, guid, key, params, schoolYearKey, token } = props;
    const bearer = `Bearer ${token}`;
    const options = {
        headers: {
            Authorization: bearer,
            "Access-Control-Max-Age": 86400
        },
        withCredentials: true
    };
    switch (action) {
        case "oeActionControls":
            options.method = "GET";
            options.params = params;
            options.url = `${OE_ACTIVE_CONTROLS}/index.json`; // the key is the differentiator
            break;
        case "oeActionControlsByAction":
            options.method = "GET";
            options.params = params;
            options.url = `${OE_ACTION_CONTROLS}/index.json`;
            break;
        case "oeActiveDistrictWindowRead":
            options.method = "GET";
            options.url = OE_ACTIVE_DISTRICT_WINDOW;
            break;
        case "oeGuardianByGuidRead":
            options.method = "GET";
            options.url = `${OE_GUARDIAN}/by_guid/${guid}/detail.json`;
            break;
        case "oeGuardianCreate":
            options.data = data;
            options.method = "POST";
            options.url = `${OE_GUARDIAN}/detail.json`;
            break;
        case "oeGuardianDelete":
            options.method = "DELETE";
            options.url = `${OE_GUARDIAN}/${key}/detail.json`;
            break;
        case "oeGuardianEmail":
            options.method = "GET";
            options.url = `${OE_GUARDIAN_EMAIL}/${key}/${schoolYearKey}/detail.json`;
            break;
        case "oeGuardianUpdate":
            options.data = data;
            options.method = "PUT";
            options.url = `${OE_GUARDIAN}/${key}/detail.json`;
            break;
        case "oeNewStudentPost":
            options.data = data;
            options.method = "POST";
            options.url = `${OE_NEW_STUDENT}/detail.json`;
            break;
        case "existingStudentPut": // for reasons
            options.data = data;
            options.method = "PUT";
            options.url = `${OE_NEW_STUDENT}/details/${key}/detail.json`;
            break;
        case "oeNewStudentPut":
            options.data = data;
            options.method = "PUT";
            options.url = `${OE_NEW_STUDENT}/${key}/detail.json`;
            break;
        case "oeByStudentNumberRead":
            options.method = "GET";
            options.url = `${OE_STUDENT}/${schoolYearKey}/index.json`;
            if (params) {
                options.params = params;
            }
            break;
        case "oeByStudentKeyDelete":
            options.method = "DELETE";
            options.url = `${OE_STUDENT}/${key}/detail.json`;
            break;
        case "oeByStudentKeyRead":
            options.method = "GET";
            options.url = `${OE_STUDENT_INFO}/${key}/detail.json`;
            break;
        case "oeByStudentKeyUpdate":
            options.data = data;
            options.method = "PUT";
            options.url = `${OE_STUDENT_INFO}/${key}/detail.json`;
            break;
        case "oeGetReasons":
            options.method = "GET";
            options.url = OE_REASONS;
            break;
        case "oeSchoolsByStudent":
            options.mathod = "GET";
            if (params) {
                options.params = params;
            }
            options.url = `${OE_SCHOOLS_BY_STUDENT_AND_ROUND}/${key}/${schoolYearKey}/index.json`;
            break;
        case "oeStudentBirthdate":
            options.method = "GET";
            options.url = `${OE_STUDENT_BIRTHDATE}/detail.json`;
            if (params) {
                options.params = params;
            }
            break;
        default:
            return null;
    }

    return ServiceWrapper.serviceCall({
        options,
        ...props
    });
};

OpenEnrollmentDao.propTypes = {
    action: PropTypes.string.isRequired,
    data: PropTypes.objectOf([PropTypes.object]),
    guid: PropTypes.number,
    key: PropTypes.number,
    params: PropTypes.objectOf([PropTypes.object]),
    schoolYearKey: PropTypes.number,
    token: PropTypes.string.isRequired
};

OpenEnrollmentDao.defaultProps = {
    data: null,
    guid: null,
    key: null,
    params: null,
    schoolYearKey: null
};

export default OpenEnrollmentDao;
