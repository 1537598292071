import React from "react";
import PropTypes from "prop-types";
import ActionButton from "../formInputs/buttons/ActionButton";
import DcsdDialog from "../modals/DcsdDialog";
import { GUARDIAN_DASHBOARD } from "../../utils/auth/config";

import "../../styles/SaveBlock.scss";

/**
 * @name SaveBlock
 * @param {bool} enableButton,
 * @param {{}} formState,
 * @param {func} handleSubmit,
 * @param {bool} hasReasons,
 * @param {string} open,
 * @param {bool} preSchool,
 * @param {func} setOpen,
 * @param {bool} studentInfoGate,
 * @param {string} studentKey,
 * @param {string} studentNumber,
 * @param {func} setStudentInfoGate
 * @returns {JSX.Element}
 */
const SaveBlock = ({
    enableButton,
    formState,
    handleSubmit,
    hasReasons,
    includeDelete,
    loader,
    open,
    preSchool,
    setOpen,
    studentInfoGate,
    studentKey,
    studentNumber,
    setStudentInfoGate
}) => {
    const message1 =
        formState && formState?.firstName.length > 0 && formState?.lastName.length > 0 ? (
            <div>
                The Open Enrollment application has not yet been saved for Student{" "}
                <u>
                    <strong>
                        {formState.firstName} {formState.lastName}
                    </strong>
                </u>{" "}
                You will lose your progress if you elect to return to the Parent Dashboard.
            </div>
        ) : (
            "The Open Enrollment application has not yet been saved for your student. You will lose your progress if you elect to return to the Parent Dashboard."
        );

    const getBackToDashboardActions = () => {
        return (
            <>
                <ActionButton
                    ariaLabel="close the dialog"
                    className="action-button-cancel"
                    label="Cancel"
                    onClick={() => {
                        setOpen("false");
                    }}
                />
                <ActionButton
                    ariaLabel="Navigate back to dashboard"
                    className="action-button-delete"
                    label="Back to Dashboard"
                    onClick={() => {
                        sessionStorage.clear();
                        window.location.replace(GUARDIAN_DASHBOARD);
                    }}
                />
            </>
        );
    };

    return (
        <>
            <div className="save-block-container">
                <div className="save-button-container">
                    {includeDelete && (
                        <ActionButton
                            disable={loader}
                            className="action-button-delete"
                            label="Delete Open Enrollment"
                            onClick={() => {
                                setOpen("delete");
                            }}
                        />
                    )}
                    <ActionButton
                        ariaLabel="navigate back to dashboard"
                        onClick={() => setOpen("back-to-dashboard")}
                        label="Back to Dashboard"
                    />
                    {studentNumber && (
                        <>
                            {!studentInfoGate && (
                                <>
                                    <ActionButton
                                        ariaLabel="Open dialog explaining steps if student information is incorrect"
                                        label="Student Information Incorrect"
                                        onClick={() => {
                                            setOpen("looksIncorrect");
                                        }}
                                    />
                                    <ActionButton
                                        ariaLabel="Continue OE process. Navigate to School Choice page"
                                        disable={!enableButton}
                                        label="Student Information Looks Good"
                                        onClick={() => {
                                            setStudentInfoGate(true);
                                        }}
                                    />
                                </>
                            )}
                            {studentNumber && studentInfoGate && (
                                <ActionButton
                                    ariaLabel="Continue OE process. Navigate to School Choice page"
                                    disable={(!hasReasons && !preSchool) || loader}
                                    label="Confirm and Proceed"
                                    onClick={() => handleSubmit()}
                                />
                            )}
                        </>
                    )}
                    {studentKey && (
                        <ActionButton
                            ariaLabel="Continue OE process. Navigate to School Choice page"
                            disable={!enableButton || loader}
                            label="Confirm and Proceed"
                            onClick={() => {
                                handleSubmit();
                            }}
                        />
                    )}
                    {!studentKey && !studentNumber && (
                        <ActionButton
                            ariaLabel="Continue OE process. Navigate to School Choice page"
                            disable={!enableButton || loader}
                            label="Confirm and Proceed"
                            onClick={() => {
                                handleSubmit();
                            }}
                        />
                    )}
                </div>
            </div>
            <DcsdDialog
                actions={getBackToDashboardActions()}
                ariaLabel="Back to Dashboard Confirmation Dialog"
                hasCloseX
                id="back-to-dashboard"
                onHide={() => {
                    setOpen("false");
                }}
                open={open}
                title="Open Enrollment Information Not Saved"
            >
                <div className="heading">Navigate to Guardian Dashboard</div>
                <div>{message1}</div>
            </DcsdDialog>
        </>
    );
};

SaveBlock.propTypes = {
    className: PropTypes.string,
    enableButton: PropTypes.bool.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]),
    disable: PropTypes.bool,
    evidence: PropTypes.oneOfType([PropTypes.object]),
    handleSubmit: PropTypes.func,
    hasReasons: PropTypes.bool,
    includeDelete: PropTypes.bool,
    loader: PropTypes.bool,
    open: PropTypes.string,
    preSchool: PropTypes.bool,
    setOpen: PropTypes.func.isRequired,
    studentInfoGate: PropTypes.bool,
    studentNumber: PropTypes.string,
    studentKey: PropTypes.string,
    setStudentInfoGate: PropTypes.func,
    validatedBirthdayDto: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

SaveBlock.defaultProps = {
    formState: null,
    hasReasons: false,
    includeDelete: false,
    loader: false,
    open: "false",
    preSchool: false,
    setStudentInfoGate: null,
    studentInfoGate: false
};

export default SaveBlock;
