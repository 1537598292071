export const gradeTranslator = (grade) => {
    switch (grade) {
        case "PRE":
        case "C3":
        case "C4":
        case "C5":
        case "CF":
        case "CF4":
        case "CF5":
        case "CH5":
        case "CP3":
        case "CP4":
        case "CP5":
        case "I1":
        case "I2":
        case "I3":
        case "P3":
        case "P4":
        case "P5":
        case "PK":
        case "UH5":
        case "UP4":
        case "UP5":
            return "PRE";
        case "K":
        case "0F":
        case "0H":
        case "00":
            return "K";
        case "FIRST":
        case "01":
            return "01";
        case "SECOND":
        case "02":
            return "02";
        case "THIRD":
        case "03":
            return "03";
        case "FOURTH":
        case "04":
            return "04";
        case "FIFTH":
        case "05":
            return "05";
        case "SIXTH":
        case "06":
            return "06";
        case "SEVENTH":
        case "07":
            return "07";
        case "EIGHTH":
        case "08":
            return "08";
        case "NINTH":
        case "09":
            return "09";
        case "TENTH":
        case "10":
            return "10";
        case "ELEVENTH":
        case "11":
            return "11";
        case "TWELFTH":
        case "12":
            return "12";
        default:
            return "-";
    }
};

export const nextGrade = (grade) => {
    switch (grade) {
        case "PRE":
        case "C3":
        case "C4":
        case "C5":
        case "CF":
        case "CF4":
        case "CF5":
        case "CH5":
        case "CP3":
        case "CP4":
        case "CP5":
        case "I1":
        case "I2":
        case "I3":
        case "P3":
        case "P4":
        case "P5":
        case "PK":
        case "UH5":
        case "UP4":
        case "UP5":
            return "K";
        case "0F":
        case "0H":
        case "00":
        case "K":
            return "01";
        case "01":
            return "02";
        case "02":
            return "03";
        case "03":
            return "04";
        case "04":
            return "05";
        case "05":
            return "06";
        case "06":
            return "07";
        case "07":
            return "08";
        case "08":
            return "09";
        case "09":
            return "10";
        case "10":
            return "11";
        case "11":
            return "12";
        case "12":
            return "NA";
        default:
            return "-";
    }
};

export const numberGradeToString = (grade) => {
    switch (grade) {
        case "PRE":
            return "PRE";
        case "K":
            return "K";
        case "01":
            return "FIRST";
        case "02":
            return "SECOND";
        case "03":
            return "THIRD";
        case "04":
            return "FOURTH";
        case "05":
            return "FIFTH";
        case "06":
            return "SIXTH";
        case "07":
            return "SEVENTH";
        case "08":
            return "EIGHTH";
        case "09":
            return "NINTH";
        case "10":
            return "TENTH";
        case "11":
            return "ELEVENTH";
        case "12":
            return "TWELFTH";
        default:
            return "-";
    }
};
