import React from "react";
import PropTypes from "prop-types";
import StudentImage from "./StudentImage";
import { gradeTranslator } from "../../const/Translations";

import "../../styles/StudentInfoBlock.scss";

/**
 * A component that will render a grey box with student information within
 * @param {*} param0
 * @returns {jsx}
 */
const StudentInfoBlock = ({ studentInfo }) => {
    const { currentLocationName, feederLocationName, futureLocationName, studentDto } = studentInfo;
    const { firstName, gradeName, lastName } = studentDto;
    const gradeNumber = gradeTranslator(gradeName);
    return (
        <div className="student-block-container">
            <div className="photo-container">
                <StudentImage className="ms-2 rounded-3" height={166} />
            </div>
            <div className="student-info-container">
                <div className="student-name">{`${firstName} ${lastName}`}</div>
                <p>
                    Current Grade:<span>{gradeNumber || "Unavailable"}</span>
                </p>
                <hr />
                <p>
                    Current School:{" "}
                    <span>
                        <i>{currentLocationName || "Unavailable"}</i>
                    </span>
                </p>
                <hr />
                <p>
                    Neighborhood School:{" "}
                    <span>
                        <i>{feederLocationName || "Unavailable"}</i>
                    </span>
                </p>
                <hr />
                <p>
                    Scheduled School for Next Year:{" "}
                    <span>
                        <i>{futureLocationName || "Unavailable"}</i>
                    </span>
                </p>
            </div>
        </div>
    );
};

StudentInfoBlock.propTypes = {
    studentInfo: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default StudentInfoBlock;
