// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

.bc-location-cite {
  width: 80%;
  height: 60px;
  background: linear-gradient(90deg, #0669B3, white);
  display: flex;
  align-items: center;
  padding: 0.6em 0 0 0;
}
.bc-location-cite h6 {
  color: white;
  padding-left: 10px;
  font-weight: 500;
  font-size: 1.1em;
}`, "",{"version":3,"sources":["webpack://./src/styles/global/Colors.scss","webpack://./src/styles/BreadCrumb.scss"],"names":[],"mappings":"AAAA;;;EAGI,sBAAA;ACCJ;;AAFA;EACI,UAAA;EACA,YAAA;EACA,kDAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;AAKJ;AAHI;EACI,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AAKR","sourcesContent":["*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n// Open Enrollment colors\n$lt-blue: #7DB7CC;\n// $dk-blue: #094166;\n$dk-blue: #19608f;\n$information: #00AEEF;\n$information-hover: #02BFFF;\n$text: #414042;\n$white: #EDEDED;\n$shadow: #525151;\n$red: #CB321A;\n$green: #C7CC68;\n\n$important: #FEBF31;\n$grey0: #EDf0F1;\n$grey1: #D1D3D4;\n$grey2: #A7A9AC;\n$grey3: #808080;\n$grey3a: #707070;\n$grey4: #636161;\n$grey4a: #444343;\n$pearl-blue: #0669B3;\n$pearl-blue-hover: #1786DB;\n$lt-pearl-blue: #63AEDE;\n$lt-pearl-blue-hover: #4589B3;\n","@import \"global/Colors\";\n\n.bc-location-cite {\n    width: 80%;\n    height: 60px;\n    background: linear-gradient(90deg, $pearl-blue, white);\n    display: flex;\n    align-items: center;\n    padding: .6em 0 0 0;\n\n    & h6 {\n        color: white;\n        padding-left: 10px;\n        font-weight: 500;\n        font-size: 1.1em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
