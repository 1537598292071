import PropTypes from "prop-types";
import ServiceWrapper from "../utils/ServiceWrapper";
import {
    USER_ACTIVE_SCHOOL_YEAR,
    USER_LOCATION_SEARCHABLE_GET,
    USER_SERVICE,
    USER_SCHOOL_YEAR_RANGE
} from "../const/UserConst";

/**
 * Data Access for the User API
 * @name UserDao
 * @param props
 * @return {null|*}
 */
const UserDao = (props) => {
    const { action, params, schoolYearKey, token, username } = props;
    const bearer = `Bearer ${token}`;
    // @TODO: add "Access-Control-Max-Age" as an allowed header on the User Service
    const options = {
        headers: {
            Authorization: bearer
        },
        withCredentials: true
    };
    switch (action) {
        case "activeSchoolYearRead":
            options.method = "GET";
            options.url = USER_ACTIVE_SCHOOL_YEAR;
            break;
        case "locationsSearchableRead":
            options.method = "GET";
            if (params) {
                options.params = params;
            }
            options.url = USER_LOCATION_SEARCHABLE_GET;
            break;
        case "userDetailsRead":
            options.method = "GET";
            options.url = `${USER_SERVICE}/${username}/details.json`;
            break;
        case "userSchoolYearRange":
            options.method = "GET";
            options.url = `${USER_SCHOOL_YEAR_RANGE}/${schoolYearKey}/detail.json`;
            break;
        default:
            return null;
    }

    return ServiceWrapper.serviceCall({
        options,
        ...props
    });
};

UserDao.propTypes = {
    action: PropTypes.string.isRequired,
    icId: PropTypes.number,
    params: PropTypes.oneOfType([PropTypes.object]),
    schoolYearKey: PropTypes.number,
    token: PropTypes.string.isRequired,
    username: PropTypes.string
};

UserDao.defaultProps = {
    icId: null,
    params: null,
    schoolYearKey: null,
    username: null
};

export default UserDao;
