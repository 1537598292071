import React from "react";
/**
 * @todo Need to switch out this for a different bootstrap dialog
 * @returns a simple looking dialog to tell the user they dont have access to this page
 */
const NotFound = () => {
    return (
        <>
            <div>Page not found. Current page is unavailable</div>
            <hr className="mt-5" />
        </>
    );
};

export default NotFound;
