import React, { useContext } from "react";
import { GlobalContext } from "../contextProvider/ContextProvider";
import { logout } from "../../utils/auth/Auth";
import Icon from "../icon/Icon";
import { GUARDIAN_DASHBOARD } from "../../utils/auth/config";
import T from "../translations/Translations";
import { SetAllLocationDtos, SetSchoolYearDto } from "../contextProvider/ContextSetter";

import "../../styles/Header.scss";

/**
 * This is returning the header content with buttons and links
 * @name Header
 * @return {JSX.Element}
 */
const Header = () => {
    const { state } = useContext(GlobalContext);
    const { userDetails } = state || {};

    // const [anchorEl, setAnchorEl] = useState(null);
    // UserDetails is returning an object with keys, name, employeeNumber,
    // givenName, displayName, email, surname, uid, category, position, etc.
    const { givenName, surname } = userDetails || {};

    /**
     * Set the SchoolYearDto in context
     */
    SetSchoolYearDto();

    /**
     * Set the locationDtos in context
     */
    SetAllLocationDtos();

    // This is for the @mui dropdown "Helpful Links"
    // const open = Boolean(anchorEl);

    const backToDashboard = () => {
        // remove all sessionStorage
        sessionStorage.clear();
        window.location.replace(GUARDIAN_DASHBOARD);
    };

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    return (
        <nav className="header-container">
            <div className="header-dcsd-icon">
                <a
                    aria-label="Guardian Dashboard Page Link"
                    href={GUARDIAN_DASHBOARD}
                    onClick={() => {
                        sessionStorage.clear();
                        return true;
                    }}
                    rel="noopener noreferrer"
                    style={{
                        textDecoration: "none",
                        color: "black"
                    }}
                    tabIndex={0}
                >
                    <Icon fill="#19608f" height={60} iconName="DCSD" width={60} />
                </a>
            </div>
            <div className="header-right">
                <div className="header-name-container">
                    <h5 className="header-name">
                        {T({ key: "Main_Nav_Welcome" })} {givenName} {surname}
                    </h5>
                </div>

                <div className="nav-button-container">
                    {/*
                /////////////////////////////////////////////////////////////////////
                this is where the "Helpful Links" dropdown button STARTS (from @mui)
                */}
                    {/* <div className="btn-primary m-2 nav-button-links nav-button-links color-override">
                        <button // used to be Button
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            id="basic-button"
                            onClick={handleClick}
                            sx={{
                                color: "white",
                                fontFamily: "Montserrat"
                            }}
                        >
                            {T({ key: "Main_Nav_HelpfulLinks" })}
                            <span>
                                <Icon fill="white" className="nav-button-icon" height={20} iconName="LIST_DCSD" />
                            </span>
                        </button>
                    </div>
                    <div // used to be Menu
                        id="basic-menu"
                        // MenuListProps={{
                        //     "aria-labelledby": "basic-button"
                        // }}
                        onClose={handleClose}
                        open={open}
                        sx={{
                            background: "black",
                            opacity: "80%"
                        }}
                    >
                        <a
                            href="https://perfectforms.dcsdk12.org/PerfectFormsSSO/player.aspx"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <div
                                onClick={handleClose}
                                sx={{
                                    color: "#19608f",
                                    opacity: "100%"
                                }}
                            >
                                <Icon iconName="LINK" fill="#19608f" height={15} />
                                {T({ key: "Main_Nav_HelpfulLinks_LI_s1" })}
                            </div>
                        </a>
                        <a href="https://www.dcsdk12.org/cms/one.aspx?pageId=5758270" rel="noreferrer" target="_blank">
                            <div
                                onClick={handleClose}
                                sx={{
                                    color: "#19608f",
                                    opacity: "100%"
                                }}
                            >
                                <Icon fill="#19608f" height={15} iconName="LINK" />
                                {T({ key: "Main_Nav_HelpfulLinks_LI_s2" })}
                            </div>
                        </a>
                        <a
                            href="https://www.dcsdk12.org/cms/One.aspx?portalId=220484&pageId=5787115"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <div
                                onClick={handleClose}
                                sx={{
                                    color: "#19608f",
                                    opacity: "100%"
                                }}
                            >
                                <Icon fill="#19608f" height={15} iconName="LINK" />
                                {T({ key: "Main_Nav_HelpfulLinks_LI_s3" })}
                            </div>
                        </a>
                        <a href="https://www.dcsdk12.org/cms/one.aspx?pageId=5759841" rel="noreferrer" target="_blank">
                            <div
                                onClick={handleClose}
                                sx={{
                                    color: "#19608f",
                                    opacity: "100%"
                                }}
                            >
                                <Icon fill="#19608f" height={15} iconName="LINK" />
                                {T({ key: "Main_Nav_HelpfulLinks_LI_s4" })}
                            </div>
                        </a>
                        <a
                            href="https://www.myschoolbucks.com/ver2/getmain?requestAction=home"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <div
                                onClick={handleClose}
                                sx={{
                                    color: "#19608f",
                                    opacity: "100%"
                                }}
                            >
                                <Icon fill="#19608f" height={15} iconName="LINK" />
                                {T({ key: "Main_Nav_HelpfulLinks_LI_s5" })}
                            </div>
                        </a>
                        <a href="https://dcsd.nutrislice.com/menus-eula" rel="noreferrer" target="_blank">
                            <div
                                onClick={handleClose}
                                sx={{
                                    color: "#19608f",
                                    opacity: "100%"
                                }}
                            >
                                <Icon fill="#19608f" height={15} iconName="LINK" />
                                {T({ key: "Main_Nav_HelpfulLinks_LI_s6" })}
                            </div>
                        </a>
                    </div> */}
                    {/*
                this is where the "Helpful Links" dropdown button ENDS (from @mui)
                /////////////////////////////////////////////////////////////////////
                */}
                    <button
                        aria-label="Exit Button"
                        className="btn btn-primary m-2 color-override"
                        onClick={backToDashboard}
                        type="button"
                    >
                        <span className="nav-button-text">
                            {T({ key: "Main_Nav_Exit" })}
                            <Icon className="nav-button-icon" fill="white" height={20} iconName="HOME" />
                        </span>
                    </button>
                    <button
                        aria-label="Logout Button"
                        className="btn btn-primary m-2 color-override"
                        onClick={logout}
                        onKeyDown={logout}
                        type="button"
                    >
                        <span className="nav-button-text">
                            {T({ key: "Main_Nav_LogOut" })}
                            <Icon
                                className="nav-button-icon"
                                fill="white"
                                height={20}
                                iconName="LOGOUT"
                                onClick={logout}
                                onKeyDown={logout}
                            />
                        </span>
                    </button>
                </div>
                {/* <HamburgerMenu /> */}
            </div>
        </nav>
    );
};

export default Header;
