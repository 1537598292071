import React from "react";
import PropTypes from "prop-types";
import Icon from "../icon/Icon";
import { gradeTranslator } from "../../const/Translations";

import "../../styles/GradeLevelBlock.scss";

/**
 * A component that will render a set of text with 2 boxes, current grade and applying grade
 * @name GradeBlock
 * @param {{}} formState
 * @returns {JSX.Element}
 */
const GradeBlock = ({ formState, schoolYearRange }) => {
    if (formState.gradeApplying && formState.gradeName) {
        return (
            <div>
                <div className="top-label grade-gutter-90">Open Enrollment Application for Grade:</div>
                <div className="top-label-secondary-text grade-gutter-90">
                    <span className="will-be">
                        Based on the age your student will be in the{" "}
                        <span className="bold">{schoolYearRange?.name}</span> school year,
                    </span>{" "}
                    you will be applying for open enrollment to the grade in the{" "}
                    <span className="yellow-box">yellow box</span> shown below. If the Applying For Grade is incorrect,
                    please contact our Choice Programming Department{" "}
                    <span className="bold">(open-enrollmentusergroup@dcsdk12.org)</span> to discuss your specific
                    circumstances.{" "}
                    <span className="will-be">
                        If the Applying For Grade is incorrect <span className="bold">and</span> you proceed with school
                        selections, your open enrollment request may be voided.
                    </span>
                </div>
                <div className="applying-grade-container grade-gutter-90">
                    <div className="outer">
                        <p>Current Grade</p>
                        <div className="current-grade-number">{gradeTranslator(formState.gradeName)}</div>
                    </div>
                    <Icon
                        className="spaceLeft-spaceRight"
                        fill="#707070"
                        height={50}
                        iconName="RIGHT_ARROW_IN_CIRCLE"
                        width={50}
                    />
                    <div className="outer">
                        <p>Applying for Grade</p>
                        <div className="applying-grade-number">{gradeTranslator(formState.gradeApplying)}</div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

GradeBlock.propTypes = {
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    schoolYearRange: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default GradeBlock;
