import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ActionButton from "../formInputs/buttons/ActionButton";
import { GUARDIAN_DASHBOARD } from "../../utils/auth/config";

import "../../styles/SaveBlock.scss";

/**
 * @name SaveAcceptedOffer
 * @param {{}} formState
 * @param {func} handleSubmit
 * @param {bool} isMutable
 * @returns {JSX.Element}
 */
const SaveAcceptedOffer = ({ formState, handleSubmit, isMutable, loader }) => {
    const [disableIt, setDisableIt] = useState(true);
    const { schoolChoiceDetailDtos } = formState;

    /**
     * Allow saves if at least one school choice is acceptable and at least one has been denied or accepted
     */
    useEffect(() => {
        if (schoolChoiceDetailDtos && schoolChoiceDetailDtos?.length > 0) {
            const match = schoolChoiceDetailDtos.filter((obj) =>
                ["ACCEPT_OFFER", "DENY_OFFER", "MAKE_OFFER"].includes(obj.status)
            );
            const match2 = schoolChoiceDetailDtos.filter((obj) => obj.status === "ACCEPT_OFFER");
            const match3 = schoolChoiceDetailDtos.filter((obj) => obj.status === "DENY_OFFER");
            if (loader) {
                setDisableIt(true);
            } else if (match2 && match2?.length > 0) {
                setDisableIt(false);
            } else if (match && match3 && match?.length > 0 && match3?.length > 0) {
                setDisableIt(false);
            } else {
                setDisableIt(true);
            }
        }
    }, [loader, schoolChoiceDetailDtos]);

    return (
        <div className="save-block-container">
            <div className="save-button-container">
                <a href={GUARDIAN_DASHBOARD}>
                    <ActionButton
                        label="Back to Dashboard"
                        onClick={() => {
                            sessionStorage.clear();
                            return true;
                        }}
                    />
                </a>
                {isMutable && formState.schoolChoiceDetailDtos && formState.schoolChoiceDetailDtos.length > 0 && (
                    <ActionButton disable={disableIt} label="Submit" onClick={handleSubmit} />
                )}
            </div>
        </div>
    );
};

SaveAcceptedOffer.propTypes = {
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isMutable: PropTypes.bool,
    loader: PropTypes.bool
};

SaveAcceptedOffer.defaultProps = {
    isMutable: true,
    loader: false
};

export default SaveAcceptedOffer;
