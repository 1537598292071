import React from "react";

/* eslint-disable */
// eslint-disable-next-line import/prefer-default-export
export const data = {
    /// ////////////////////////////////////////////////////////////
    /// /////////////// Interrupt //////////////////////////////////
    /// ////////////////////////////////////////////////////////////
    Interrupt_header: (
        <b>Процесс входа в родительский портал EngagED (Внутренний/Внешний)</b>
    ),
    Interrupt_p1: (
        <>
            Чтобы войти в родительский портал EngagED, вы объедините ваше{" "}
            <b>имя пользователя в родительском портале Infinite Campus</b>{" "}
            (внешний родитель: имя пользователя, созданное вами при настройке
            аккаунта) <u>и</u> <b>@p.dcsdk12.org</b>. Например: если ваше имя
            пользователя в Infinite Campus - <b>jsmith</b>, вы введете{" "}
            <b>jsmith@p.dcsdk12.org</b> в качестве вашего адреса электронной
            почты на следующем экране входа. Ваш <b>пароль</b> тот же, который
            вы используете для доступа к Infinite Campus (внешний родитель:
            пароль, созданный вами при настройке аккаунта).
        </>
    ),
    Interrupt_p2: (
        <>
            <b>Если вы забыли свои учетные данные для входа в DCSD</b>:<br />
            Используйте эти ссылки, чтобы{" "}
            <a
                href="https://iaas5idm.fischeridentity.com/identity/self-service/dcs/forgotuserid.jsf"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                восстановить свое имя пользователя
            </a>{" "}
            и/или{" "}
            <a
                href="https://iaas5idm.fischeridentity.com/identity/self-service/dcs/kiosk.jsf"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                сбросить свой пароль
            </a>
            .
        </>
    ),
    Interrupt_p3: (
        <>
            <b>
                Если вы выполнили вышеуказанные шаги и продолжаете испытывать
                трудности, пожалуйста, свяжитесь с нами
            </b>
            :<br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                Техническая поддержка для родителей - английский
            </a>
            <br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT@LFLT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                Техническая поддержка для родителей - испанский
            </a>
            <br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT@LRLT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                Техническая поддержка для родителей - китайский
            </a>
            <br />
            Центр технической поддержки по номеру <b>303.387.0001</b>
        </>
    ),
    Interrupt_note:
        "ПРИМЕЧАНИЕ: Сотрудники округа, которые в настоящее время являются родителями, должны следовать приведенным выше инструкциям, используя окно браузера в режиме инкогнито или приватный режим и/или другой браузер.",
    Interrupt_submit: "Перейти к входу",
    /// /////////////////////////////////////////////////////////
    /// /////////////// Header //////////////////////////////////
    /// /////////////////////////////////////////////////////////
    Main_Nav_Welcome: "Добро пожаловать,",
    Main_Nav_HelpfulLinks: "Полезные ссылки",
    Main_Nav_LogOut: "Выйти",
    Main_Nav_HelpfulLinks_LI_s1: "Связаться с медсестрой моего(-их) ученика(-ов)",
    Main_Nav_HelpfulLinks_LI_s2: "Академические календари",
    Main_Nav_HelpfulLinks_LI_s3: "Ресурсы для родителей",
    Main_Nav_HelpfulLinks_LI_s4: "Маршруты автобусов",
    Main_Nav_HelpfulLinks_LI_s5: "Онлайн-платежи",
    Main_Nav_HelpfulLinks_LI_s6: "Меню обедов",
    Main_Nav_Exit: "Выход",
};
/* eslint-enable */
