import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ActionButton from "../formInputs/buttons/ActionButton";
import DcsdDialog from "../modals/DcsdDialog";
import { GUARDIAN_DASHBOARD } from "../../utils/auth/config";

import "../../styles/SaveBlock.scss";

/**
 * @name SaveSchoolChoices
 * @param {{}} formState
 * @param {func} handleSubmit
 * @param {string} open
 * @param {[]]} reasons
 * @param {func} setOpen
 * @param {{}} studentDto
 * @returns {JSX.Element}
 */
const SaveSchoolChoices = ({ formState, handleSubmit, loader, open, reasons, setOpen, studentDto }) => {
    const [disableIt, setDisableIt] = useState(true);

    const getBackToDashboardActions = () => {
        return (
            <>
                <ActionButton
                    className="action-button-cancel"
                    label="Cancel"
                    onClick={() => {
                        setOpen("false");
                    }}
                />
                <ActionButton
                    className="action-button-delete"
                    label="Back to Dashboard"
                    onClick={() => {
                        sessionStorage.clear();
                        window.location.replace(GUARDIAN_DASHBOARD);
                    }}
                />
            </>
        );
    };

    /**
     * Determine if all relevant choices have been made per school
     * This includes Kinder Choices (if student is applying to Kindergarten), Reasons, and Language Choices (if school
     * offers it)
     */
    useEffect(() => {
        if (formState && studentDto) {
            const { schoolChoiceDetailDtos } = formState;
            let isKinderSchool = studentDto.gradeApplying === "K";
            let isLanguageSchool = false;
            let kindersPerSchool = [];
            let kinderSchoolCount = schoolChoiceDetailDtos.length;
            let languageSchoolCount = 0;

            if (isKinderSchool) {
                kindersPerSchool = schoolChoiceDetailDtos.reduce((results, dto) => {
                    const { kinderChoice, schoolDto } = dto;
                    const { locationDto } = schoolDto;
                    const match = locationDto.tags?.filter((obj) => obj.type === "KINDER_OFFERED");
                    if (match && match.length > 0) {
                        if (kinderChoice && kinderChoice?.length > 0) {
                            results.push(true);
                        }
                    }

                    return results;
                }, []);
            } else {
                kindersPerSchool = schoolChoiceDetailDtos;
            }

            const languagesPerSchool = schoolChoiceDetailDtos.reduce((results, dto) => {
                const { languageChoice, schoolDto } = dto;
                const { locationDto } = schoolDto;
                const match = locationDto.tags?.filter((obj) => obj.type === "LANGUAGE");
                if (match && match.length > 0) {
                    isLanguageSchool = true;
                    languageSchoolCount += 1;
                    if (languageChoice && languageChoice?.length > 0) {
                        results.push(true);
                    }
                }

                return results;
            }, []);

            const reasonsPerSchool = schoolChoiceDetailDtos.reduce((results, dto) => {
                const { reasonsForChoosing } = dto;
                if (reasonsForChoosing && reasonsForChoosing?.length > 0) {
                    let otherComment = [];
                    const otherReason = reasons.filter((obj) => obj.questionText.toLowerCase() === "other");
                    if (otherReason && otherReason.length > 0) {
                        otherComment = reasonsForChoosing.filter(
                            (obj) => parseInt(obj.questionId, 10) === parseInt(otherReason[0].key)
                        );
                    }
                    if (otherComment && otherComment.length > 0) {
                        const { comments } = otherComment[0];
                        if (comments && comments.length > 0) {
                            results.push(true);
                        }
                    } else {
                        results.push(true);
                    }
                }

                return results;
            }, []);

            if (reasonsPerSchool?.length === schoolChoiceDetailDtos.length) {
                if (isLanguageSchool) {
                    if (languagesPerSchool.length === languageSchoolCount) {
                        if (isKinderSchool) {
                            if (kindersPerSchool && kindersPerSchool.length === kinderSchoolCount) {
                                setDisableIt(false);
                            } else {
                                setDisableIt(true);
                            }
                        } else {
                            setDisableIt(false);
                        }
                    } else {
                        setDisableIt(true);
                    }
                } else if (isKinderSchool) {
                    if (kindersPerSchool && kindersPerSchool.length === kinderSchoolCount) {
                        setDisableIt(false);
                    } else {
                        setDisableIt(true);
                    }
                } else {
                    setDisableIt(false);
                }
            } else {
                setDisableIt(true);
            }
        }
    }, [formState, reasons, studentDto]);

    return (
        <>
            <div className="save-block-container">
                <div className="save-button-container">
                    <ActionButton
                        disable={loader}
                        className="action-button-delete"
                        label="Delete Open Enrollment"
                        onClick={() => {
                            setOpen("delete");
                        }}
                    />
                    <ActionButton onClick={() => setOpen("back-to-dashboard")} label="Back to Dashboard" />
                    {formState.schoolChoiceDetailDtos && formState.schoolChoiceDetailDtos.length > 0 && (
                        <ActionButton
                            disable={disableIt || loader}
                            label="Save and Email Me My Choices"
                            onClick={handleSubmit}
                        />
                    )}
                </div>
            </div>
            <DcsdDialog
                actions={getBackToDashboardActions()}
                ariaLabel="Back to Dashboard Confirmation Dialog"
                hasCloseX
                id="back-to-dashboard"
                onHide={() => {
                    setOpen("false");
                }}
                open={open}
                title="Open Enrollment Information Not Saved"
            >
                <div className="heading">Navigate to Guardian Dashboard</div>
                <p>
                    The Open Enrollment application has not yet been saved for Student{" "}
                    <strong>
                        {studentDto?.firstName} {studentDto?.lastName}
                    </strong>
                    . You will lose your progress if you elect to return to the Parent Dashboard.
                </p>
            </DcsdDialog>
        </>
    );
};

SaveSchoolChoices.propTypes = {
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    handleSubmit: PropTypes.func,
    loader: PropTypes.bool,
    open: PropTypes.string,
    reasons: PropTypes.instanceOf(Array).isRequired,
    setOpen: PropTypes.func,
    studentDto: PropTypes.oneOfType([PropTypes.object])
};

SaveSchoolChoices.defaultProps = {
    handleSubmit: null,
    loader: false,
    open: "false",
    setOpen: null,
    studentDto: null
};

export default SaveSchoolChoices;
