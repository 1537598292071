import React from "react";

import "../../../styles/Modals.scss";

const InvalidDobModalForm = () => {
    return (
        <>
            <p>
                Based on the birthdate you entered, the{" "}
                <span className="age">student&apos;s age is outside the range for open enrollment</span>. Please contact
                the Choice Programming department to discuss the specific circumstances and/or options for open
                enrollment.
            </p>
        </>
    );
};

export default InvalidDobModalForm;
