import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "../../contextProvider/ContextProvider";
import OptionList from "./OptionList";
import ArcGisDao from "../../../dao/ArcGisDao";
import ActionButton from "../buttons/ActionButton";

/**
 * Display a textfield that responds to user input by showing a list of
 * addresses matching the address entered (after 4 characters)
 * @name OeAutoSuggest
 * @param {string} className
 * @param {func} formDispatch
 * @param {{}} formState
 * @param {string} id
 * @param {string} label
 * @param {string} name
 * @param {func} setSearchFlag
 * @return {JSX.element}
 * @constructor
 */
const OeAutoSuggest = ({
    formDispatch,
    formState,
    hasArcAddress,
    id,
    label,
    name,
    setHasArcAddress,
    setSearchFlag
}) => {
    const { state } = useContext(GlobalContext);
    const { token } = state || {};

    const [activeOption, setActiveOption] = useState(0);
    const [autoSuggest, setAutoSuggest] = useState(null);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [showResetButton, setShowResetButton] = useState(false);

    /**
     * On change, read input value and filter the studentAutoSuggest accordingly
     * @name handleChange
     * @param {{}} e
     */
    const handleChangeAddress1 = (e) => {
        const { value } = e.target;

        if (value.length >= 4 && autoSuggest && autoSuggest.length > 0) {
            const filteredPayload = autoSuggest.filter(
                (dto) => dto && dto.fullAddress.toLowerCase().includes(value.toLowerCase())
            );
            const filteredArray = filteredPayload.reduce((results, dto) => {
                const { city, county, feederArea, fullAddress, gridCode, number, street, suffix, zip } = dto;

                const tempDto = {
                    city,
                    county,
                    feederArea,
                    fullAddress,
                    gridCode,
                    number,
                    street,
                    suffix,
                    zip
                };
                results.push(tempDto);

                return results;
            }, []);
            setFilteredOptions(filteredArray);
            setShowOptions(true);
        } else {
            setShowOptions(false);
            setShowResetButton(false);
        }
        formDispatch({
            type: "text",
            field: "searchAddress",
            payload: value
        });
    };

    /**
     * Clear the search field and all other fields when clicking button
     * Reset the text input to initial and remove the options list
     */
    const resetAutoSuggest = () => {
        setShowOptions(false);
        setShowResetButton(false);
        setFilteredOptions([]);

        const tempFormState = structuredClone(formState);

        tempFormState.searchAddress = "";
        tempFormState.addressLine1 = "";
        tempFormState.addressLine2 = "";
        tempFormState.city = "";
        tempFormState.state = "";
        tempFormState.zip = "";

        formDispatch({
            type: "reset",
            payload: { ...tempFormState }
        });
    };

    /**
     * Showing the reset button in the auto suggest (address list)
     */
    useEffect(() => {
        if (formState.searchAddress?.length > 1) {
            setShowResetButton(true);
        }
    }, [formState.searchAddress, showResetButton]);

    /**
     * If we get a token, call to get the list of addresses after 4 characters
     */
    useEffect(() => {
        if (token && formState?.searchAddress?.length > 3) {
            const options = {
                action: "arcGisSearchableRead",
                params: {
                    address: `${formState.searchAddress}`
                },
                token
            };
            ArcGisDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload && payload.length > 0) {
                        setAutoSuggest(payload);
                    }
                }
            });
        }
    }, [formState.searchAddress, token]);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <label id="label" htmlFor="searchAddress">
                    {label}
                </label>
                <span className="deleteicon">
                    <div className="address-search">
                        <input
                            id={id}
                            name={name}
                            onChange={handleChangeAddress1}
                            type="text"
                            value={formState?.searchAddress || ""}
                            placeholder="Start typing your address here"
                        />
                    </div>
                    {showResetButton && (
                        <div style={{ marginBottom: "15px" }}>
                            <ActionButton
                                label="Reset"
                                onClick={() => {
                                    setHasArcAddress(false);
                                    resetAutoSuggest();
                                }}
                                onKeyDown={() => {
                                    setHasArcAddress(false);
                                    resetAutoSuggest();
                                }}
                                tabIndex={0}
                            />
                        </div>
                    )}
                </span>
            </div>
            <OptionList
                activeOption={activeOption}
                filteredOptions={filteredOptions}
                formDispatch={formDispatch}
                formState={formState}
                hasArcAddress={hasArcAddress}
                resetAutoSuggest={resetAutoSuggest}
                setHasArcAddress={setHasArcAddress} // this is (true/false) to disable fields in StudentForm.
                setActiveOption={setActiveOption}
                setSearchFlag={setSearchFlag}
                setShowOptions={setShowOptions}
                showOptions={showOptions}
                showResetButton={showResetButton}
            />
        </div>
    );
};

OeAutoSuggest.propTypes = {
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    hasArcAddress: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    setHasArcAddress: PropTypes.func,
    setSearchFlag: PropTypes.func,
    tool: PropTypes.string
};

OeAutoSuggest.defaultProps = {
    existingEmployees: null,
    hasArcAddress: false,
    id: "",
    label: "",
    setSearchFlag: null,
    tool: "cite"
};

export default OeAutoSuggest;
