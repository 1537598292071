import React from "react";
import PropTypes from "prop-types";

import "../../styles/FeederTabs.scss";

const FeederTab = ({ count, feeder, feederRegion, handleClick, isFeeder, isSelected }) => {
    const activeClass = isSelected ? " active-circle" : "";
    return (
        <button
            className={isSelected ? "feeder-tab active-feeder" : "feeder-tab"}
            id={feeder}
            name={feeder}
            onClick={handleClick}
        >
            {isFeeder && feederRegion && <div className="current-address">Current Address</div>}
            <div className="feeder-tab-contents">
                {feeder}{" "}
                <div className="selected-count">
                    <div className={`tab-circle${activeClass}`}>{count}</div> <span>selected</span>
                </div>
            </div>
        </button>
    );
};

FeederTab.propTypes = {
    count: PropTypes.number,
    feeder: PropTypes.string.isRequired,
    feederRegion: PropTypes.string,
    handleClick: PropTypes.func,
    isFeeder: PropTypes.bool,
    isSelected: PropTypes.bool
};

FeederTab.defaultProps = {
    className: "feeder-tab",
    count: 0,
    feederRegion: null,
    handleClick: null,
    isFeeder: false,
    isSelected: false
};

export default FeederTab;
