import React from "react";

/* eslint-disable */
// eslint-disable-next-line import/prefer-default-export
export const data = {
    /// ////////////////////////////////////////////////////////////
    /// /////////////// Interrupt //////////////////////////////////
    /// ////////////////////////////////////////////////////////////
    Interrupt_header: (
        <b>EngagED Parent Portal Login Process (Internal/External)</b>
    ),
    Interrupt_p1: (
        <>
            To log in to the EngagED Parent Portal, you will pair your{" "}
            <b>Infinite Campus Parent Portal username</b> (external parent:
            username you created during account setup) <u>and</u>{" "}
            <b>@p.dcsdk12.org</b>. For example: if your Infinite Campus
            username is <b>jsmith</b>, you will enter{" "}
            <b>jsmith@p.dcsdk12.org</b> as your email address on the login
            screen that follows. Your <b>password</b> is the same one you
            use for accessing Infinite Campus (external parent: password you
            created during account setup).
        </>
    ),
    Interrupt_p2: (
        <>
            <b>If you have forgotten your DCSD login credentials</b>:<br />
            Use these links to{" "}
            <a
                href="https://iaas5idm.fischeridentity.com/identity/self-service/dcs/forgotuserid.jsf"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                retrieve your username
            </a>{" "}
            and/or{" "}
            <a
                href="https://iaas5idm.fischeridentity.com/identity/self-service/dcs/kiosk.jsf"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                reset your password
            </a>
            .
        </>
    ),
    Interrupt_p3: (
        <>
            <b>
                If you have followed the steps above and continue to have
                difficulty, please contact us
            </b>
            :<br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                Parent Tech Support - English
            </a>
            <br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT@LFLT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                Parent Tech Support - Spanish
            </a>
            <br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT@LRLT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                Parent Tech Support - Chinese
            </a>
            <br />
            IT Support Center at <b>303.387.0001</b>
        </>
    ),
    Interrupt_note:
        "NOTE: District employees who are current parents should follow the instructions above using an incognito or private browser window and/or different browser.",
    Interrupt_submit: "Proceed to Login",
    /// /////////////////////////////////////////////////////////
    /// /////////////// Header //////////////////////////////////
    /// /////////////////////////////////////////////////////////
    Main_Nav_Welcome: "Welcome,",
    Main_Nav_HelpfulLinks: "Helpful Links",
    Main_Nav_LogOut: "LOGOUT",
    Main_Nav_HelpfulLinks_LI_s1: "Contact My Student(s) Nurse",
    Main_Nav_HelpfulLinks_LI_s2: "Academic Calendars",
    Main_Nav_HelpfulLinks_LI_s3: "Parent Resources",
    Main_Nav_HelpfulLinks_LI_s4: "Bus Routes",
    Main_Nav_HelpfulLinks_LI_s5: "Online Payments",
    Main_Nav_HelpfulLinks_LI_s6: "Lunch Menus",
    Main_Nav_Exit: "EXIT",
};
/* eslint-enable */
