import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import RbA from "../components/rba/RbA.jsx";
import { GlobalContext } from "../components/contextProvider/ContextProvider.jsx";
import ActionButton from "../components/formInputs/buttons/ActionButton.jsx";
import StudentForm from "../components/formComponents/forms/StudentForm.jsx";
import { GUARDIAN_DASHBOARD } from "../utils/auth/config.js";
import GradeBlock from "../components/gradeLevelBlock/GradeBlock.jsx";
import SaveBlock from "../components/saveBlock/SaveBlock.jsx";
import FormReducer from "../utils/FormReducer.jsx";
import StudentInfoDao from "../dao/StudentInfoDao.jsx";
import OpenEnrollmentDao from "../dao/OpenEnrollmentDao.jsx";
import BreadCrumb from "../components/breadCrumb/BreadCrumb.jsx";
import ValidationPatterns from "../utils/ValidationPatterns.jsx";
import { isValidDate } from "../utils/DateFormatter.jsx";
import DcsdFormModal from "../components/modals/DcsdFormModal.jsx";
import DcsdDialog from "../components/modals/DcsdDialog.jsx";
import InvalidDobModalForm from "../components/formComponents/modalForms/InvalidDobModalForm.jsx";
import UserDao from "../dao/UserDao.jsx";
import Header from "../components/header/Header.jsx";
import GuardianAddressBlock from "../components/addressBlock/GuardianAddressBlock.jsx";
import InformationIncorrectListForm from "../components/formComponents/modalForms/InformationIncorrectListForm.jsx";
import WindowAndPermissionDialog from "../components/modals/WindowAndPermissionDialog.jsx";
import Toolbar from "../components/Toolbar.jsx";
import { gradeTranslator } from "../const/Translations.jsx";
import LoadingSvg from "../components/loadingSvg/LoadingSvg.jsx";

import "../styles/Guardian_StudentForm.scss";

/**
 * This will render the form and components for showing/ editing a Open Enrollment record
 * Existing Guardian + Existing Student (OE data)
 * @name StudentKey
 * @return {JSX.Element}
 */
const StudentKey = () => {
    const { dispatch, state } = useContext(GlobalContext);
    const { oeActiveDistrictWindow, studentKey, token, userDetails } = state || {};

    const { key } = useParams();
    const navigate = useNavigate();

    const initialFormState = {
        addressLine1: "",
        addressLine2: "",
        birthdate: "",
        birthMonth: "",
        birthDay: "",
        birthYear: "",
        city: "",
        firstName: "",
        gender: "",
        gradeName: "",
        gradeApplying: "",
        key: null,
        lastName: "",
        middleName: "",
        overrideStatus: "DEFAULT_LOCATION_CHECK",
        parentGuid: null,
        searchAddress: "",
        schoolYearKey: oeActiveDistrictWindow ? oeActiveDistrictWindow.schoolYearKey : "",
        state: "",
        studentNumber: null,
        zip: ""
    };
    const allowedRolesArray = ["EXTERNAL_GUARDIAN", "GUARDIAN"];

    const [birthdateFlag, setBirthdateFlag] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [formState, formDispatch] = useReducer(FormReducer, initialFormState);
    const [guardianInfo, setGuardianInfo] = useState(null);
    const [hasArcAddress, setHasArcAddress] = useState(false); // this is (true/false) to disable fields in StudentForm.
    const [loader, setLoader] = useState(false);
    const [newOeStudent, setNewOeStudent] = useState(false);
    const [oeActionControls, setOeActionControls] = useState(null);
    const [oeReasons, setOeReasons] = useState(null);
    const [open, setOpen] = useState("false");
    const [outOfBounds, setOutOfBounds] = useState(false);
    const [schoolYearRange, setSchoolYearRange] = useState(null); // used to show 2004 - 2005 range
    const [showDobResetButton, setShowDobResetButton] = useState(false);
    const [sorryMessage, setSorryMessage] = useState("");
    const [sorryTitle, setSorryTitle] = useState("");
    const [studentData, setStudentData] = useState(null);
    const [studentInfoGate, setStudentInfoGate] = useState(false);
    const [validatedBirthdayDto, setValidatedBirthdayDto] = useState(null);

    const getDeleteActions = () => {
        return (
            <>
                <ActionButton
                    className="action-button-cancel"
                    label="Cancel"
                    onClick={() => {
                        setOpen("false");
                    }}
                />
                <ActionButton
                    className="action-button-delete"
                    disable={loader}
                    label="Delete Open Enrollment"
                    onClick={() => {
                        handleDelete();
                    }}
                />
            </>
        );
    };

    const getGradeChangeActions = () => {
        return (
            <>
                <ActionButton
                    className="action-button-cancel"
                    label="Cancel"
                    onClick={() => {
                        setOpen("false");
                    }}
                />
                <ActionButton
                    className="action-button-delete"
                    label="Save Changes"
                    onClick={() => {
                        handleSubmit(false);
                    }}
                />
            </>
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (["birthMonth", "birthDay", "birthYear"].includes(name)) {
            let newVal = value;
            setBirthdateFlag(false);
            if (!ValidationPatterns.digitOnly.test(value) && value !== "") {
                return false;
            }
            if (["birthDay", "birthMonth"].includes(name)) {
                newVal = value !== "" ? parseInt(value, 10) : "";
                if (name === "birthDay") {
                    if (newVal !== "" && newVal > 31) {
                        return false;
                    }
                } else if (name === "birthMonth") {
                    if (newVal !== "" && newVal > 12) {
                        return false;
                    }
                }
                if (newVal.toString().length > 0 && newVal > 0) {
                    newVal = newVal.toString().padStart(2, "0");
                }
                formDispatch({
                    type: "text",
                    field: name,
                    payload: newVal
                });

                return true;
            }
        }
        if (name === "zip") {
            if (!ValidationPatterns.digitOnly.test(value) && value !== "") {
                return false;
            }
        }
        formDispatch({
            type: "text",
            field: name,
            payload: value
        });

        return true;
    };

    const handleDelete = () => {
        const options = {
            action: "oeByStudentKeyDelete",
            key,
            token
        };
        setLoader(true);
        OpenEnrollmentDao(options).then((response) => {
            if (response) {
                const { errors } = response.data;
                if (errors && errors?.length > 0) {
                    let errorMessage = "Unable to Delete Student Open Enrollment.";
                    errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                    toast.error(`${errorMessage}`, {
                        autoClose: false,
                        closeOnClick: true,
                        style: { width: "100%", whiteSpace: "break-spaces" }
                    });
                    setOpen("false");

                    return false;
                }
                toast.success(
                    "Open Enrollment application successfully deleted for student. Redirecting to Parent Dashboard. ",
                    {
                        autoClose: false
                    }
                );
                setOpen("false");
                setTimeout(() => {
                    sessionStorage.clear();
                    window.location.replace(GUARDIAN_DASHBOARD);
                }, 3000);

                return true;
            }
            setLoader(false);
        });
    };

    /**
     * @name handleSubmit
     * @returns
     */
    const handleSubmit = (bypass = true) => {
        if (studentKey) {
            const { schoolChoiceDetailDtos, studentDto } = studentData;
            if (bypass && formState.gradeApplying !== studentDto.gradeApplying && schoolChoiceDetailDtos?.length > 0) {
                setOpen("grade-change");
                return false;
            }
            // PUT
            const newStudentData = {
                address: {
                    addressLine1: formState.addressLine1,
                    addressLine2: formState.addressLine2,
                    city: formState.city,
                    state: formState.state,
                    zip: formState.zip
                },
                birthdate: formState.birthdate,
                feederLocationKey: studentDto?.feederLocationKey || null,
                firstName: formState?.firstName,
                gradeApplying: formState.gradeApplying,
                gradeName: formState.gradeName,
                gender: formState.gender,
                gridCode: studentDto?.gridCode || null,
                key: studentKey,
                lastName: formState?.lastName,
                locationKey: studentDto?.locationKey || null,
                middleName: formState?.middleName,
                oodSchoolName: studentDto?.oodSchoolName || null,
                oodSchoolCity: studentDto?.oodSchoolCity || null,
                oodSchoolState: studentDto?.oodSchoolState || null,
                overrideStatus: "DEFAULT_LOCATION_CHECK",
                personId: studentDto?.personId || null,
                parentGuid: userDetails.uid,
                schoolYearKey: oeActiveDistrictWindow?.schoolYearKey,
                studentNumber: studentDto?.studentNumber || null
            };
            if (token && !newOeStudent) {
                const options = {
                    action: "oeNewStudentPut",
                    data: newStudentData,
                    key: studentKey,
                    token
                };
                setLoader(true);
                OpenEnrollmentDao(options).then((response) => {
                    if (response) {
                        const { errors, payload } = response.data;
                        if (payload) {
                            toast.success("Student information successfully updated for Open Enrollment.", {
                                autoClose: 3000
                            });
                            setTimeout(() => {
                                navigate(`/school-choice/${payload?.key}`);
                            }, 3000);
                        }
                        if (errors) {
                            toast.error(errors[0], { autoClose: false });
                        }
                        setNewOeStudent(true); // -> disable text fields
                    }
                    setLoader(false);
                });
            }
        }
    };

    /**
     * Reset the birthdate on button click
     */
    const birthdateReset = () => {
        setValidatedBirthdayDto(null); // reset the DTO to null
        setBirthdateFlag(false); // flag for waiting on api call to validate dob
        setShowDobResetButton(false); // shows reset button
        setStudentInfoGate(false);

        const tempFormState = structuredClone(formState);
        tempFormState.birthdate = "";
        tempFormState.birthDay = "";
        tempFormState.birthMonth = "";
        tempFormState.birthYear = "";
        tempFormState.gradeApplying = "";
        tempFormState.gradeName = "";

        formDispatch({
            type: "reset",
            payload: { ...tempFormState }
        });
    };

    /**
     * When formState.birthdate value is true make a call to BE service
     * that will validate and provide current & applying grades
     * @name getValidDOB
     * @param {string} dob
     * @callback
     */
    const getValidDOB = useCallback(
        (dob) => {
            const options = {
                action: "oeStudentBirthdate",
                params: {
                    birthDate: dob
                },
                token
            };
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload && payload !== null) {
                        setValidatedBirthdayDto(payload);
                    } else {
                        setValidatedBirthdayDto({ error: errors[0] });
                    }
                }
            });
        },
        [token]
    );

    const showGradeBlock = () => {
        return formState && formState.gradeName?.length > 0 && formState.gradeApplying?.length > 0 && schoolYearRange;
    };

    /**
     * Get the schoolYearKey and dispatch to state (contextProvider)
     * this call is from OE current district window
     */
    useEffect(() => {
        if (token && !oeActiveDistrictWindow) {
            const options = {
                action: "oeActiveDistrictWindowRead",
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        dispatch({
                            type: "OeActiveDistrictWindow",
                            oeActiveDistrictWindow: payload
                        });
                    } else if (payload === null) {
                        setOutOfBounds(true);
                        setSorryTitle("Open Enrollment Window Closed");
                        setSorryMessage(
                            "The Open Enrollment window is now closed. Please return during the district's parent-access windows. Thank you."
                        );
                        setOpen("out-of-bounds");
                    }
                }
                setLoader(false);
            });
        }
    }, [dispatch, oeActiveDistrictWindow, token]);

    /**
     * Get the list of reasons to leave or attend a school
     */
    useEffect(() => {
        if (token && !oeReasons) {
            const options = {
                action: "oeGetReasons",
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    setOeReasons(payload);
                }
                setLoader(false);
            });
        }
    }, [oeReasons, token]);

    /**
     * Get IC guardian information (infinite campus)
     */
    useEffect(() => {
        if (token && userDetails && !guardianInfo) {
            const { roleDtos } = userDetails;
            const { role } = roleDtos[0];

            if (role === "GUARDIAN") {
                const options = {
                    action: "guardianInfoRead",
                    username: userDetails?.username,
                    token
                };
                StudentInfoDao(options).then((response) => {
                    if (response) {
                        const { errors, payload } = response.data;

                        if (payload) {
                            setGuardianInfo(payload);
                        }
                        if (errors && errors.length) {
                            setGuardianInfo(null);

                            toast.error("There was a problem loading your data. Please reload the page and try again");
                        }
                    }
                });
            } else {
                setGuardianInfo(userDetails);
            }
        }
    }, [formState, guardianInfo, token, userDetails]);

    /**
     * Get the Action Controls for Open Enrollment
     * Should I check the start and end dates?
     */
    useEffect(() => {
        if (oeActiveDistrictWindow && token && !oeActionControls) {
            const options = {
                action: "oeActionControls",
                params: {
                    differentiator: oeActiveDistrictWindow.key
                },
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        const match = payload.filter((obj) => ["PARENT_REQUEST"].includes(obj.action));
                        if (match && match.length > 0) {
                            setOeActionControls(payload);
                        } else {
                            setOutOfBounds(true);
                            setSorryTitle("Open Enrollment Window Closed");
                            setSorryMessage(
                                "The Open Enrollment window is now closed. Please return during the district's parent-access windows. Thank you. "
                            );
                            setOpen("out-of-bounds");
                        }
                    }
                }
                setLoader(false);
            });
        }
    }, [token, oeActionControls, oeActiveDistrictWindow]);

    /**
     * Get the school year range from DB
     * Used in Edit page in GradeLevelBlock area to display range (ex: 2025 - 2026)
     */
    useEffect(() => {
        if (token && oeActiveDistrictWindow && !schoolYearRange) {
            const nextYear = parseInt(oeActiveDistrictWindow.schoolYearKey, 10) + 1;
            const options = {
                action: "userSchoolYearRange",
                schoolYearKey: nextYear,
                token
            };
            setLoader(true);
            UserDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        setSchoolYearRange(payload);
                    }
                }
                setLoader(false);
            });
        }
    }, [oeActiveDistrictWindow, schoolYearRange, token]);

    /**
     * Get student data object by key
     */
    useEffect(() => {
        if (studentKey && token && oeActiveDistrictWindow && !studentData && !outOfBounds) {
            const options = {
                action: "oeByStudentKeyRead",
                key: studentKey,
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        const { studentDto } = payload;
                        setStudentData(payload);
                        // Check to see if student has an IC account
                        if (studentDto) {
                            const { studentNumber } = studentDto;
                            if (studentNumber && studentNumber.length > 0) {
                                // If they have an IC account (studentNumber) then they should not be able to edit (no
                                // permission) re-route to their view page
                                navigate(`/view/${studentNumber}`);
                            }
                        }
                    }
                } else {
                    setOutOfBounds(true);
                    setSorryMessage(
                        "Sorry, but you do not have permission to view this student. Please return to Parent Dashboard."
                    );
                    setSorryTitle("Access Denied");
                    setOpen("out-of-bounds");
                }
                setLoader(false);
            });
        }
    }, [navigate, oeActiveDistrictWindow, outOfBounds, studentKey, studentData, token]);

    /**
     * Send values to formState
     */
    useEffect(() => {
        if (studentData) {
            const { studentDto } = studentData;
            const {
                address,
                birthdate,
                firstName,
                gradeApplying,
                gradeName,
                gridCode,
                gender,
                key,
                lastName,
                middleName,
                oodSchoolName,
                oodSchoolCity,
                oodSchoolState,
                overrideStatus,
                parentGuid,
                personId,
                studentKey
            } = studentDto;
            const { addressLine1, addressLine2, city, state, zip } = address;
            dispatch({
                type: "StudentKey",
                studentKey: key
            });
            let birthDay = "";
            let birthMonth = "";
            let birthYear = "";
            if (birthdate && birthdate.length > 0) {
                const birthArr = birthdate.split("-");
                if (birthArr && birthArr.length > 0) {
                    birthDay = birthArr[2];
                    birthMonth = birthArr[1];
                    birthYear = birthArr[0];
                }
            }
            const updatedFormState = {
                addressLine1: addressLine1 || "",
                addressLine2: addressLine2 || "",
                city: city || "",
                birthdate: birthdate || "",
                birthDay,
                birthMonth,
                birthYear,
                firstName: firstName || "",
                gradeApplying: gradeApplying || "",
                gender: gender || "",
                gradeName: gradeName || "",
                gridCode: gridCode,
                lastName: lastName || "",
                middleName: middleName || "",
                oodSchoolName: oodSchoolName,
                oodSchoolCity: oodSchoolCity,
                oodSchoolState: oodSchoolState,
                overrideStatus: overrideStatus,
                parentGuid: parentGuid,
                personId: personId,
                schoolYearKey: oeActiveDistrictWindow ? oeActiveDistrictWindow.schoolYearKey : "",
                state: state || "",
                studentKey: studentKey,
                zip: zip || ""
            };
            formDispatch({
                type: "reset",
                payload: { ...updatedFormState }
            });
        }
    }, [dispatch, oeActiveDistrictWindow, studentData]);

    /**
     * Dispatch params
     */
    useEffect(() => {
        if (key && !studentKey) {
            dispatch({
                type: "StudentKey",
                studentKey: key
            });
            setStudentInfoGate(true);
        }
    }, [dispatch, key, studentKey]);

    /**
     * This useEffect is checking the formState for validation.
     * if any of the fields below are not filled out, the save button will not be active
     */
    useEffect(() => {
        if (
            formState.addressLine1?.trim().length &&
            formState?.birthdate.length > 0 &&
            formState.city?.trim().length > 0 &&
            formState.firstName?.trim().length > 0 &&
            formState.gender?.trim().length &&
            formState.lastName?.trim().length > 0 &&
            formState.state?.trim().length > 0 &&
            formState.zip?.trim().length >= 5 &&
            gradeTranslator(formState.gradeName) !== "-" &&
            gradeTranslator(formState.gradeApplying) !== "-"
        ) {
            setEnableButton(true);
            setStudentInfoGate(true);
        } else {
            setEnableButton(false);
        }
    }, [formState]);

    /**
     * If a valid birthdate is entered in UI then get the values from validateBirthdteDto
     * and dispatch (inject) them into formState
     */
    useEffect(() => {
        if (validatedBirthdayDto && validatedBirthdayDto?.currentGrade) {
            const updatedFormState = formState;
            updatedFormState.gradeName = validatedBirthdayDto?.currentGrade;
            updatedFormState.gradeApplying = validatedBirthdayDto?.gradeApplying;
            updatedFormState.birthdate = `${formState.birthYear}-${formState.birthMonth}-${formState.birthDay}`;

            formDispatch({
                type: "reset",
                payload: { ...updatedFormState }
            });
        } else if (validatedBirthdayDto?.error) {
            setOpen("dobRange");
        }
        // eslint-disable-next-line
    }, [formDispatch, validatedBirthdayDto]);

    /**
     * This is only for showing the "Clear Birthdate Fields" button
     */
    useEffect(() => {
        if (formState.birthYear || formState.birthMonth || formState.birthDay) {
            setShowDobResetButton(true);
        } else if (studentData?.studentDto?.birthdate) {
            // If new parent + new student (if we get back a DTO) for "Edit" page
            setShowDobResetButton(true);
        } else {
            setShowDobResetButton(false);
        }
    }, [formState, showDobResetButton, studentData]);

    /**
     * Call getValid DOB if UI month, day, year is filled out.
     */
    useEffect(() => {
        const { birthDay, birthMonth, birthYear } = formState;
        if (parseInt(birthDay, 10) !== 0 || parseInt(birthMonth, 10) !== 0) {
            const studentBirthDate = `${birthYear}-${birthMonth}-${birthDay}`;
            if (studentBirthDate && studentBirthDate.length === 10 && !birthdateFlag) {
                if (isValidDate(studentBirthDate)) {
                    setBirthdateFlag(true);
                    getValidDOB(studentBirthDate);
                } else if (!isValidDate(studentBirthDate)) {
                    toast.error("Please enter a valid date for student birthdate (MM-DD-YYYY).", { autoClose: 5000 });
                }
            }
        }
    }, [birthdateFlag, formDispatch, formState, getValidDOB]);

    return (
        <RbA allowedRoles={allowedRolesArray} redirect="/notFound">
            <Header />
            <Toolbar />
            <BreadCrumb label="Open Enrollment > Student & Guardian Information" />
            <div className="gutter-90">
                {studentData && (
                    <>
                        <div className="address-block-container">
                            <GuardianAddressBlock
                                label1="Guardian's"
                                name="edit"
                                guardianInfo={guardianInfo}
                                formState={formState}
                                userDetails={userDetails}
                            />
                        </div>
                        <div className="mt-5 mb-3">
                            <hr />
                        </div>
                        <StudentForm
                            birthdateFlag={birthdateFlag}
                            birthdateReset={birthdateReset}
                            formState={formState}
                            formDispatch={formDispatch}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            hasArcAddress={hasArcAddress}
                            setHasArcAddress={setHasArcAddress}
                            showDobResetButton={showDobResetButton}
                            studentData={studentData}
                        />
                        {showGradeBlock() && <GradeBlock formState={formState} schoolYearRange={schoolYearRange} />}
                        <SaveBlock
                            enableButton={enableButton}
                            formState={formState}
                            handleSubmit={handleSubmit}
                            includeDelete
                            loader={loader}
                            open={open}
                            setOpen={setOpen}
                            showDobResetButton={showDobResetButton}
                            studentInfoGate={studentInfoGate}
                            studentKey={studentKey}
                        />
                        <ToastContainer style={{ width: "50%" }} />
                        {validatedBirthdayDto?.error && (
                            <DcsdFormModal
                                ariaLabel="Date Of Birth Ineligible"
                                birthdateReset={birthdateReset}
                                buttonCount="1"
                                button1Label="Close"
                                button1Labe2="button2"
                                id="dobRange"
                                title="Date of Birth Ineligible for Open Enrollment"
                                open={open}
                                setOpen={setOpen}
                            >
                                <InvalidDobModalForm />
                            </DcsdFormModal>
                        )}
                        <DcsdFormModal
                            ariaLabel="Popup modal listing 1-3 ways fix students Open Enrollment Record"
                            buttonCount="1"
                            button1Label="Back to Dashboard"
                            id="looksIncorrect"
                            title="Incorrect Student Information"
                            open={open}
                            setOpen={setOpen}
                        >
                            <InformationIncorrectListForm />
                        </DcsdFormModal>
                        <DcsdDialog
                            actions={getGradeChangeActions()}
                            ariaLabel="Previous School Choices will be lost"
                            hasCloseX
                            id="grade-change"
                            onHide={() => {
                                setOpen("false");
                            }}
                            open={open}
                            title="Previous School Choice(s) Will Be Deleted"
                        >
                            <p>
                                The change to birthdate for Student{" "}
                                <strong>
                                    {studentData?.studentDto?.firstName} {studentData?.studentDto?.lastName}
                                </strong>{" "}
                                has resulted in a change in the Applying To grade for Open Enrollment. As a result, you
                                will need to re-select schools of interest after saving this change. Please confirm that
                                you wish to continue.
                            </p>
                            {loader && (
                                <div className="dialog-loader">
                                    Processing... Please Wait <LoadingSvg />
                                </div>
                            )}
                        </DcsdDialog>
                        <DcsdDialog
                            actions={getDeleteActions()}
                            ariaLabel="Delete Open Enrollment Confirmation Dialog"
                            hasCloseX
                            id="delete"
                            onHide={() => {
                                setOpen("false");
                            }}
                            open={open}
                            title="Delete Open Enrollment Confirmation"
                        >
                            <p>
                                Please confirm that you would like to delete the Open Enrollment application for Student{" "}
                                <span>
                                    {studentData?.studentDto.firstName} {studentData?.studentDto.lastName}
                                </span>
                                . This action will remove any associated Open Enrollment school choice data for the
                                student.{" "}
                            </p>
                            {loader && (
                                <div className="dialog-loader">
                                    Processing... Please Wait <LoadingSvg />
                                </div>
                            )}
                        </DcsdDialog>
                    </>
                )}
                {loader && <LoadingSvg />}
                <WindowAndPermissionDialog
                    id="out-of-bounds"
                    open={open}
                    setOpen={setOpen}
                    sorryTitle={sorryTitle}
                    sorryMessage={sorryMessage}
                />
            </div>
        </RbA>
    );
};

export default StudentKey;
