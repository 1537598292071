import React from "react";
/// ///////////////////////////////////////////////////////////////
/// ///////////////////////////////////////////////////////////////
/// ///////////////////////// SPANISH /////////////////////////////
/// ///////////////////////////////////////////////////////////////
/// ///////////////////////////////////////////////////////////////
/* eslint-disable */
// eslint-disable-next-line import/prefer-default-export
export const data = {
    /// ////////////////////////////////////////////////////////////
    /// /////////////// Interrupt //////////////////////////////////
    /// ////////////////////////////////////////////////////////////
    Interrupt_header: (
        <b>Proceso de Inicio de Sesión en el Portal para Padres EngagED (Interno/Externo)</b>
    ),
    Interrupt_p1: (
        <>
            Para iniciar sesión en el Portal para Padres EngagED, emparejará su{" "}
            <b>nombre de usuario del Portal para Padres de Infinite Campus</b> (
            padre externo: nombre de usuario que creó durante la configuración
            de la cuenta) <u>y</u> <b>@p.dcsdk12.org</b>. Por ejemplo: si su
            nombre de usuario de Infinite Campus es <b>jsmith</b>, ingresará{" "}
            <b>jsmith@p.dcsdk12.org</b> como su dirección de correo electrónico
            en la pantalla de inicio de sesión que sigue. Su <b>contraseña</b>{" "}
            es la misma que usa para acceder a Infinite Campus (padre externo:
            contraseña que creó durante la configuración de la cuenta).
        </>
    ),
    Interrupt_p2: (
        <>
            <b>Si ha olvidado sus credenciales de inicio de sesión de DCSD</b>:<br />
            Use estos enlaces para{" "}
            <a
                href="https://iaas5idm.fischeridentity.com/identity/self-service/dcs/forgotuserid.jsf"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                recuperar su nombre de usuario
            </a>{" "}
            y/o{" "}
            <a
                href="https://iaas5idm.fischeridentity.com/identity/self-service/dcs/kiosk.jsf"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                restablecer su contraseña
            </a>
            .
        </>
    ),
    Interrupt_p3: (
        <>
            <b>
                Si ha seguido los pasos anteriores y continúa teniendo
                dificultades, por favor contáctenos
            </b>
            :<br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                Soporte Técnico para Padres - Inglés
            </a>
            <br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT@LFLT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                Soporte Técnico para Padres - Español
            </a>
            <br />
            <a
                href="https://www.k12insight.com/Lets-Talk/embed.aspx?L=PRXB26D5K3LT@LRLT"
                rel="noreferrer"
                style={{ color: "blue" }}
                target="_blank"
            >
                Soporte Técnico para Padres - Chino
            </a>
            <br />
            Centro de Soporte Técnico en <b>303.387.0001</b>
        </>
    ),
    Interrupt_note:
        "NOTA: Los empleados del distrito que son padres actuales deben seguir las instrucciones anteriores utilizando una ventana de navegador de incógnito o privada y/o un navegador diferente.",
    Interrupt_submit: "Proceder a iniciar sesión",
    /// /////////////////////////////////////////////////////////
    /// /////////////// Header //////////////////////////////////
    /// /////////////////////////////////////////////////////////
    Main_Nav_Welcome: "Bienvenido,",
    Main_Nav_HelpfulLinks: "Vínculos útiles",
    Main_Nav_LogOut: "CERRAR SESIÓN",
    Main_Nav_HelpfulLinks_LI_s1: "Contactar el enfermero de mi estudiante",
    Main_Nav_HelpfulLinks_LI_s2: "Calendarios Académicos",
    Main_Nav_HelpfulLinks_LI_s3: "Recursos para padres",
    Main_Nav_HelpfulLinks_LI_s4: "Rutas de autobuses",
    Main_Nav_HelpfulLinks_LI_s5: "Pagos en línea",
    Main_Nav_HelpfulLinks_LI_s6: "Menus de almuerzo",
    Main_Nav_Exit: "SALIR",
};
/* eslint-enable */
