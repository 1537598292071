// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Auto Suggest */
.auto-suggest-contain {
  border: 2px solid #a9a9a9;
  border-radius: 10px;
  width: 30%;
  position: absolute;
  overflow-x: hidden;
  background: #ffffff;
  z-index: 998;
  max-height: 500px;
}

.auto-suggest button {
  text-align: left;
}
.auto-suggest button:hover {
  text-decoration: underline;
}

ul {
  list-style-type: none;
}

span.deleteicon {
  position: relative;
  display: flex;
  justify-content: center;
}

.option-close-button {
  padding: 10px 30px;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/styles/AutoSuggest.scss"],"names":[],"mappings":"AAAA,iBAAA;AACA;EACI,yBAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAEI;EACI,gBAAA;AACR;AAAQ;EACI,0BAAA;AAEZ;;AAGA;EAEI,qBAAA;AADJ;;AAiBA;EACI,kBAAA;EACA,aAAA;EACA,uBAAA;AAdJ;;AAkCA;EACI,kBAAA;EACA,aAAA;EACA,yBAAA;AA/BJ","sourcesContent":["/* Auto Suggest */\n.auto-suggest-contain {\n    border: 2px solid #a9a9a9;\n    border-radius: 10px;\n    width: 30%;\n    position: absolute;\n    overflow-x: hidden;\n    background: #ffffff;\n    z-index: 998;\n    max-height: 500px;\n}\n.auto-suggest {\n    button {\n        text-align: left;\n        &:hover {\n            text-decoration: underline;\n        }\n    }\n}\n\nul {\n    // text-align: left;\n    list-style-type: none;\n    // .names {\n    //     border: 2px solid red;\n    //     border-radius: 10px;\n    //     margin-left: -30 px;\n    //     .single {\n    //         text-align: left;\n    //         text-align: center;\n    //     }\n    //     button {\n    //         border: 2px solid red;\n    //         margin: 5px 0 5px 10px\n    //     }\n    // }\n}\n\nspan.deleteicon {\n    position: relative;\n    display: flex;\n    justify-content: center;\n}\n// span.deleteicon span {\n//     position: absolute;\n//     display: block;\n//     right: 3px;\n//     width: 20px;\n//     height: 20px;\n//     border-radius: 50%;\n//     color: #fff;\n//     background-color: #ccc;\n//     // font: 15px monospace;\n//     text-align: center;\n//     cursor: pointer;\n// }\n// span.deleteicon input {\n//     border: 2px solid red;\n//     padding-right: 18px;\n//     box-sizing: border-box; \n// }\n.option-close-button {\n    padding: 10px 30px;\n    display: flex;\n    justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
