import React from "react";
import PropTypes from "prop-types";
import SchoolCard from "../schoolCard/SchoolCard";

import "../../styles/FeederTabs.scss";

const SelectedSchoolTab = ({
    className,
    feeders,
    formDispatch,
    formState,
    index,
    oeStudentInfo,
    reasons,
    school,
    selectedSchoolTab,
    setSelectedSchoolTab
}) => {
    return (
        <div
            className={className}
            onClick={() => {
                setSelectedSchoolTab(index);
            }}
            onKeyDown={() => {
                setSelectedSchoolTab(index);
            }}
            role="button"
            tabIndex={0}
        >
            <SchoolCard
                feeders={feeders}
                formDispatch={formDispatch}
                formState={formState}
                index={index}
                oeStudentInfo={oeStudentInfo}
                reasons={reasons}
                school={school}
                selectedSchoolTab={selectedSchoolTab}
                setSelectedSchoolTab={setSelectedSchoolTab}
            />
        </div>
    );
};

SelectedSchoolTab.propTypes = {
    className: PropTypes.string,
    feeders: PropTypes.oneOfType([PropTypes.object]).isRequired,
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    index: PropTypes.number.isRequired,
    oeStudentInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
    reasons: PropTypes.instanceOf(Array).isRequired,
    school: PropTypes.oneOfType([PropTypes.object]).isRequired,
    selectedSchoolTab: PropTypes.number.isRequired,
    setSelectedSchoolTab: PropTypes.func.isRequired
};

SelectedSchoolTab.defaultProps = {
    className: "reasons-tab-card-container"
};

export default SelectedSchoolTab;
