import React, { useEffect, useState } from "react";
import Icon from "./icon/Icon";
import T from "./translations/Translations";
import TranslationButton from "./formInputs/buttons/TranslationButton";

/**
 * Provide instructions to the user about entering correct credentials on the following login screen
 * @name Interrupt
 * @return {JSX.Element}
 * @constructor
 */
const Interrupt = () => {
    const [langStr, setLangString] = useState("en");

    const key = sessionStorage.getItem("student-key");
    const number = sessionStorage.getItem("student-number");

    const getNewRoute = () => {
        if (key) {
            if (sessionStorage.getItem("school-choice")) {
                return `/school-choice/${key}`;
            }
            return `/edit/${key}`;
        } else if (number) {
            return `/view/${number}`;
        }

        return "/home";
    };

    useEffect(() => {
        document.getElementsByTagName("body")[0].style =
            "font-kerning: normal; font-weight: 500; line-height: 1.2rem; font-size: 0.9rem; font-family: 'Arial, Helvetica, sans-serif !important";
    }, []);

    return (
        <div
            style={{
                alignItems: "center",
                border: "1px solid grey",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                height: "90%",
                margin: "0 auto",
                marginTop: "30px",
                padding: "30px 40px",
                width: "85%"
            }}
        >
            <section className="translation-buttons-container" style={{ position: "relative", top: "-42px" }}>
                <TranslationButton
                    label="English"
                    onClick={() => {
                        setLangString("en");
                    }}
                />
                <TranslationButton
                    label="Español"
                    onClick={() => {
                        setLangString("es");
                    }}
                />
                <TranslationButton
                    label="普通話"
                    onClick={() => {
                        setLangString("zh");
                    }}
                />
                <TranslationButton
                    label="Русский"
                    onClick={() => {
                        setLangString("ru");
                    }}
                />
            </section>
            <div style={{ height: "220px", marginTop: "-60px" }}>
                <Icon
                    className="ms-3 mt-3 mb-3"
                    height={240}
                    iconName="DOUGLAS_COUNTY_SCHOOL_DISTRICT"
                    title="Douglas County School District"
                    viewBox="0 0 1024 1024"
                    width={240}
                />
            </div>
            <div style={{ height: "20px" }}>&nbsp;</div>
            <div style={{ minWidth: "400px", width: "80%" }}>
                {T({ key: "Interrupt_header", langStr })}
                <br />
                <br />
                {T({ key: "Interrupt_p1", langStr })}
                <br />
                <br />
                {T({ key: "Interrupt_p2", langStr })}
                <br />
                <br />
                {T({ key: "Interrupt_p3", langStr })}
                <br />
                <br />
                <i>{T({ key: "Interrupt_note", langStr })}</i>
            </div>
            <div style={{ height: "40px" }}>&nbsp;</div>
            <div>
                <a href={getNewRoute()} rel="noreferrer" target="_blank">
                    <input
                        style={{
                            borderRadius: "10px",
                            borderWidth: 0,
                            background: "#0669B3",
                            boxShadow: "5px 5px 5px #999999",
                            color: "white",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            height: "50px",
                            width: "400px"
                        }}
                        type="button"
                        value={T({ key: "Interrupt_submit", langStr })}
                    />
                </a>
            </div>
        </div>
    );
};

export default Interrupt;
