import React from "react";
import ActionButton from "../formInputs/buttons/ActionButton";
import { GUARDIAN_DASHBOARD } from "../../utils/auth/config";

import "../../styles/SaveBlock.scss";

/**
 * A Save block with only one button: "Back to Dashboard"
 * @name BackOnly
 * @returns {JSX.Element}
 */
const BackOnly = () => {
    return (
        <div className="save-block-container back-only">
            <div className="save-button-container">
                <a href={GUARDIAN_DASHBOARD}>
                    <ActionButton
                        label="Back to Dashboard"
                        onClick={() => {
                            sessionStorage.clear();
                            return true;
                        }}
                    />
                </a>
            </div>
        </div>
    );
};

export default BackOnly;
