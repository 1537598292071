import React from "react";
import { Route, Routes } from "react-router-dom";
import Interrupt from "./components/Interrupt";
import Layout from "./components/Layout";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import Login from "./components/Login";
import Main from "./components/Main";
import NotFound from "./components/NotFound";
import LoadTestHome from "./components/LoadTestHome";
import LoadTestStudentKey from "./components/LoadTestStudentKey";
import LoadTestStudentNumber from "./components/LoadTestStudentNumber";
import SchoolChoice from "./segments/SchoolChoice";
import StudentNumber from "./segments/StudentNumber";
import StudentKey from "./segments/StudentKey";
import StudentNew from "./segments/StudentNew";

/**
 * Routing for the DUO Bypass Login
 * @name App
 * @return {{}}
 */
const App = () => {
    return (
        <Routes>
            <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Main />} />
            </Route>
            <Route path="/home" element={<PrivateRoute />}>
                <Route path="/home" element={<StudentNew />} />
            </Route>
            <Route path="/edit/:key" element={<PrivateRoute />}>
                <Route path="/edit/:key" element={<StudentKey />} />
            </Route>
            <Route path="/view/:number" element={<PrivateRoute />}>
                <Route path="/view/:number" element={<StudentNumber />} />
            </Route>
            <Route path="/school-choice/:key" element={<PrivateRoute />}>
                <Route path="/school-choice/:key" element={<SchoolChoice />} />
            </Route>
            <Route path="/login-welcome" element={<Interrupt />} />
            <Route path="/notFound" element={<NotFound />} />
            <Route default element={<NotFound />} />
            {/* Development ONLY */}
            {process.env.NODE_ENV !== "production" && (
                <>
                    <Route path="/backdoor" exact element={<Login />} />
                    <Route path="/layout" element={<Layout />} />

                    <Route path="/loadtest" exact element={<NotFound />} />
                    <Route path="/loadtest/home" exact element={<NotFound />} />
                    <Route path="/loadtest/edit" exact element={<NotFound />} />
                    <Route path="/loadtest/view" exact element={<NotFound />} />

                    <Route path="/loadtest/home/:userName" exact element={<PrivateRoute />}>
                        <Route path="/loadtest/home/:userName" exact element={<LoadTestHome />} />
                    </Route>

                    <Route path="/loadtest/edit/:userName/:key" element={<PrivateRoute />}>
                        <Route path="/loadtest/edit/:userName/:key" element={<LoadTestStudentKey />} />
                    </Route>

                    <Route path="/loadtest/view/:userName/:number" element={<PrivateRoute />}>
                        <Route path="/loadtest/view/:userName/:number" element={<LoadTestStudentNumber />} />
                    </Route>
                </>
            )}
        </Routes>
    );
};

export default App;
