import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import FormReducer from "../utils/FormReducer";
import ActionButton from "./formInputs/buttons/ActionButton";

const Login = () => {
    const navigate = useNavigate();

    const initialFormState = {
        username: "",
        studentNumber: "",
        studentKey: ""
    };

    const [formState, formDispatch] = useReducer(FormReducer, initialFormState);

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        formDispatch({
            type: "text",
            field: name,
            payload: value
        });
    };

    const handleSubmitStudentKey = () => {
        const { studentKey, username } = formState;
        if (username.trim().length) {
            sessionStorage.clear();
            sessionStorage.setItem("devLogin", "devLogin");
            const myUrl =
                studentKey.length > 0
                    ? `/loadtest/edit/${username.trim()}/${studentKey.trim()}`
                    : `/loadtest/home/${username.trim()}`;
            navigate(myUrl);
        }
    };

    const handleSubmitStudentNumber = () => {
        const { studentNumber, username } = formState;
        if (username.trim().length && studentNumber.trim().length) {
            sessionStorage.clear();
            sessionStorage.setItem("devLogin", "devLogin");
            navigate(`/loadtest/view/${username.trim()}/${studentNumber.trim()}`);
        }
    };

    return (
        <>
            {/* STUDENT KEY */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "80%",
                    margin: "0 auto",
                    background: "#D3D3D3"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        margin: "0 auto",
                        marginBottom: "50px",
                        marginTop: "50px",
                        textAlign: "center"
                    }}
                >
                    <h1>StudentKey:</h1>
                    <h2>Welcome to Open Enrollment Backdoor!</h2>
                    <p>Please Log In Below</p>
                    <div style={{ marginTop: "50px", width: "100%" }}>
                        <label htmlFor="username">
                            Username:
                            <input
                                className="form-control"
                                id="username"
                                name="username"
                                onChange={handleOnChange}
                                type="text"
                            />
                        </label>
                    </div>
                    <div style={{ marginTop: "20px", width: "100%" }}>
                        <label htmlFor="studentKey">
                            Student Key:
                            <input
                                className="form-control"
                                id="studentKey"
                                name="studentKey"
                                onChange={handleOnChange}
                                type="text"
                                value={formState.studentKey}
                            />
                        </label>
                    </div>
                    <div className="m-5" />
                    <ActionButton label="Log In" onClick={handleSubmitStudentKey} />
                </div>
            </div>
            <br />
            <br />
            <br />
            {/* STUDENT NUMBER */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "80%",
                    margin: "0 auto",
                    background: "#D3D3D3"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        margin: "0 auto",
                        marginBottom: "50px",
                        marginTop: "50px",
                        textAlign: "center"
                    }}
                >
                    <h1>StudentNumber:</h1>
                    <h2>Welcome to Open Enrollment Backdoor!</h2>
                    <p>Please Log In Below</p>
                    <div style={{ marginTop: "50px", width: "100%" }}>
                        <label htmlFor="username">
                            Username:
                            <input
                                className="form-control"
                                id="username"
                                name="username"
                                onChange={handleOnChange}
                                type="text"
                            />
                        </label>
                    </div>
                    <div style={{ marginTop: "20px", width: "100%" }}>
                        <label htmlFor="studentNumber">
                            Student Number:
                            <input
                                className="form-control"
                                id="studentNumber"
                                name="studentNumber"
                                onChange={handleOnChange}
                                type="text"
                                value={formState.studentNumber}
                            />
                        </label>
                    </div>
                    <div className="m-5" />
                    <ActionButton label="Log In" onClick={handleSubmitStudentNumber} />
                </div>
            </div>
        </>
    );
};

export default Login;
