import React, { useContext } from "react";
import PropTypes from "prop-types";
import CheckboxBlock from "../formInputs/CheckboxBlock";
import GoldenRodBox from "../goldenRodBox/GoldenRodBox";
import { GlobalContext } from "../contextProvider/ContextProvider";

import "../../styles/ReasonsBlock.scss";

/**
 * A listing of checkboxes that the user can select within a background
 * @param {func} formDispatch
 * @param {obj} formState
 * @param {obj} oeReasons
 * @param {obj} studentIcData
 * @param {obj} studentOeData
 * @param {string} studentNumber
 * @return {JSX Element}
 */
const ReasonsBlock = ({ formDispatch, formState, oeReasons, studentIcData, studentOeData, studentNumber }) => {
    const { state } = useContext(GlobalContext);
    const { oeActiveDistrictWindow } = state;

    const handleChange = (e) => {
        const { checked, name, value } = e.target;
        const tempFormState = structuredClone(formState);
        const nameArr = name.split("-");
        let existingReason = [];
        const reasonKey = nameArr[1];

        if (studentOeData && studentOeData?.reasonsForLeaving && studentOeData?.reasonsForLeaving.length > 0) {
            existingReason = studentOeData?.reasonsForLeaving.filter(
                (obj) => parseInt(obj.questionId, 10) === parseInt(reasonKey, 10)
            );
        }

        if (nameArr[2] && nameArr[2] === "comments") {
            const otherReason = oeReasons.filter((obj) => obj.questionText.toLowerCase() === "other");
            let otherText = [];

            if (otherReason && otherReason.length > 0) {
                otherText = tempFormState.reasonsForLeaving.filter(
                    (obj) => parseInt(obj.questionId, 10) === parseInt(otherReason[0].key, 10)
                );
            }

            if (otherText && otherText.length > 0) {
                otherText[0].comments = value;
            }
            formDispatch({
                type: "reset",
                payload: { ...tempFormState }
            });

            return true;
        }

        if (checked) {
            const data = {
                comments: "",
                key: existingReason && existingReason.length > 0 ? existingReason[0].key : null,
                locationKey: studentOeData?.studentDto?.locationKey || null,
                questionId: value,
                questionnaireType: "REASON_FOR_LEAVING",
                schoolChoiceId: 0,
                schoolYearKey: oeActiveDistrictWindow.schoolYearKey,
                studentId: studentOeData?.studentDto?.key || null
            };
            tempFormState.reasonsForLeaving?.push(data);
        } else {
            const match = tempFormState.reasonsForLeaving?.filter(
                (obj) => parseInt(obj.questionId, 10) !== parseInt(value, 10)
            );
            tempFormState.reasonsForLeaving = match;
        }

        formDispatch({
            type: "reset",
            payload: { ...tempFormState }
        });

        return true;
    };

    return (
        <>
            <div className="mt-3 mb-5">
                <hr />
            </div>
            <div className="reasons-label">Reasons for Leaving</div>
            <div className="reasons-leaving-background">
                <div className="reasons-label">
                    {studentNumber && (
                        <div>
                            <div>
                                <GoldenRodBox label="Important" />
                            </div>
                            <div className="mt-1 mb-5">
                                What are the reasons for leaving <span>{studentIcData?.schoolName}</span>: *
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    {oeReasons?.map((reason, index) => {
                        const uniqueKey = `reason-${index}`;
                        const { reasonsForLeaving } = formState;
                        const match = reasonsForLeaving.filter(
                            (obj) => parseInt(obj.questionId, 10) === parseInt(reason.key, 10)
                        );
                        let comments = "";
                        let isChecked = false;

                        if (match && match.length > 0) {
                            isChecked = true;
                            comments = match[0].comments;
                        }

                        return (
                            <CheckboxBlock
                                comments={comments}
                                handleChange={handleChange}
                                isChecked={isChecked}
                                id={`checkbox-${reason.key}`}
                                key={uniqueKey}
                                label={reason?.questionText}
                                name={`checkbox-${reason.key}`}
                                value={reason.key}
                            />
                        );
                    })}
                </div>
                <div className="input-subtext">
                    Note: If you select &ldquo;Other&rdquo; as a reason for applying, you must enter a comment in order
                    to submit your school choice.
                </div>
            </div>
        </>
    );
};

ReasonsBlock.propTypes = {
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    oeReasons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
    studentIcData: PropTypes.oneOfType([PropTypes.object]),
    studentNumber: PropTypes.string.isRequired,
    studentOeData: PropTypes.oneOfType([PropTypes.object])
};

ReasonsBlock.defaultProps = {
    studentIcData: null,
    studentOeData: null
};

export default ReasonsBlock;
