import React from "react";
import PropTypes from "prop-types";
import DcsdDialog from "./DcsdDialog";
import ActionButton from "../formInputs/buttons/ActionButton";
import { GUARDIAN_DASHBOARD } from "../../utils/auth/config";

const WindowAndPermissionDialog = ({ id, open, sorryMessage, sorryTitle }) => {
    const getBackToDialogActions = () => {
        return (
            <ActionButton
                ariaLabel="Back to Guardian Dashboard"
                className="action-button-reg"
                label="Back to Dashboard"
                onClick={() => {
                    sessionStorage.clear();
                    window.location.replace(GUARDIAN_DASHBOARD);
                }}
            />
        );
    };

    return (
        <DcsdDialog actions={getBackToDialogActions()} hasCloseX={false} id={id} open={open} title={sorryTitle}>
            {sorryMessage}
        </DcsdDialog>
    );
};

WindowAndPermissionDialog.propTypes = {
    id: PropTypes.string,
    open: PropTypes.string,
    sorryMessage: PropTypes.string,
    sorryTitle: PropTypes.string
};

export default WindowAndPermissionDialog;
