import React from "react";
import PropTypes from "prop-types";
import SelectedSchoolTab from "./SelectedSchoolTab";
import SelectedSchoolReasons from "./SelectedSchoolReasons";

import "../../styles/SelectedSchools.scss";

const SelectedSchoolsBlock = ({
    feeders,
    formDispatch,
    formState,
    oeStudentInfo,
    reasons,
    selectedSchoolTab,
    setSelectedSchoolTab
}) => {
    /**
     * @name showSelectedSchoolReasons
     * @return {bool}
     */
    const showSelectedSchoolReasons = () => {
        return (
            reasons &&
            reasons.length > 0 &&
            formState.schoolChoiceDetailDtos.length > 0 &&
            formState.schoolChoiceDetailDtos[selectedSchoolTab]
        );
    };

    return (
        <section className="selected-schools-container">
            <h5 className="dark-blue-text">My Selected Schools</h5>
            <p className="dark-blue-text">
                These are the schools that you have selected for Open Enrollment. For each school you selected, you must
                click the &ldquo;Select Reasons&rdquo; button to let us know what attracted you to apply for Open
                Enrollment at the school. Please note that you will not be able to save/email your school selections
                until you have entered at least one reason for applying for each selected school. To remove a school
                selection, click the trash can icon in the upper right hand corner of the school card.
            </p>
            <div className="selected-schools-tab-container">
                {formState.schoolChoiceDetailDtos.map((school, index) => {
                    const uniqueKey = `selected-school-tab-${index}`;
                    const tmpClass =
                        selectedSchoolTab === index
                            ? "reasons-tab-card-container card-active"
                            : "reasons-tab-card-container";
                    return (
                        <SelectedSchoolTab
                            className={tmpClass}
                            feeders={feeders}
                            formDispatch={formDispatch}
                            formState={formState}
                            index={index}
                            key={uniqueKey}
                            oeStudentInfo={oeStudentInfo}
                            reasons={reasons}
                            school={school}
                            selectedSchoolTab={selectedSchoolTab}
                            setSelectedSchoolTab={setSelectedSchoolTab}
                        />
                    );
                })}
            </div>
            {showSelectedSchoolReasons() && (
                <SelectedSchoolReasons
                    formDispatch={formDispatch}
                    formState={formState}
                    reasons={reasons}
                    school={formState.schoolChoiceDetailDtos[selectedSchoolTab]}
                />
            )}
        </section>
    );
};

SelectedSchoolsBlock.propTypes = {
    feeders: PropTypes.oneOfType([PropTypes.object]).isRequired,
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    oeStudentInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
    reasons: PropTypes.instanceOf(Array).isRequired,
    selectedSchoolTab: PropTypes.number,
    setSelectedSchoolTab: PropTypes.func.isRequired
};

SelectedSchoolsBlock.defaultProps = {
    selectedSchoolTab: 0
};

export default SelectedSchoolsBlock;
