import React from "react";
import PropTypes from "prop-types";

import "../../styles/CheckboxBlock.scss";

/**
 * A Button that looks and acts like a checkbox
 * @name CheckboxReason
 * @param {func} handleClick
 * @param {string} id
 * @param {bool} isChecked
 * @param {bool} isDisabled
 * @param {string|null} label
 * @param {string} name
 * @param {string} valu
 * @return {JSX.Element}
 * @constructor
 */
const CheckboxReason = ({
    className,
    comments,
    handleChange,
    id,
    isChecked,
    isDisabled,
    label,
    name,
    priority,
    value
}) => {
    const checkClass = isChecked ? " checked-kinder" : "";

    return (
        <div className={`${className}${checkClass}`}>
            <label htmlFor={id} className="text-truncate">
                <input
                    aria-label={`${label} - checkbox`}
                    id={id}
                    checked={isChecked}
                    disabled={isDisabled}
                    name={name}
                    onChange={handleChange}
                    tabIndex={0}
                    title={label}
                    type="checkbox"
                    value={value}
                />
                {label}
                {priority && priority.length > 0 && <div className="priority">{priority}</div>}
            </label>
            {label.toLowerCase() === "other" && isChecked && (
                <div id="school-choice-other-input">
                    <input
                        aria-label={`Other - comments`}
                        className={comments.length > 0 ? "" : "input-warning"}
                        disabled={isDisabled}
                        id={`${id}-comments`}
                        maxLength={255}
                        name={`${name}-comments`}
                        onChange={handleChange}
                        placeholder="Please enter a comment"
                        title={label}
                        type="text"
                        value={comments}
                    />
                </div>
            )}
        </div>
    );
};

CheckboxReason.propTypes = {
    className: PropTypes.string,
    comments: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    otherReason: PropTypes.string,
    priority: PropTypes.string,
    value: PropTypes.string
};

CheckboxReason.defaultProps = {
    className: "checkbox-reason checkbox-button",
    comments: "",
    id: "",
    isChecked: false,
    isDisabled: false,
    label: "",
    name: "",
    otherReason: "",
    priority: "",
    value: ""
};

export default CheckboxReason;
