import React, { useContext } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "../contextProvider/ContextProvider";
import CheckboxButton from "../formInputs/CheckboxButton";

import "../../styles/FeederContainer.scss";

const FeederSchools = ({ formDispatch, formState, schools, setSelectedSchoolTab }) => {
    const { state } = useContext(GlobalContext);
    const { oeActiveDistrictWindow, oeStudentInfo } = state || {};

    const getStatus = () => {
        let status = "REQUEST";
        if (oeActiveDistrictWindow) {
            const { enrollmentRound } = oeActiveDistrictWindow;
            if (enrollmentRound === "ROUND2") {
                status = "WAIT_LIST";
            }
        }

        return status;
    };

    const getSchoolChoiceDetailDto = (schoolDto) => {
        const { schoolChoiceDetailDtos } = formState;
        return {
            key: null,
            kinderChoice: null,
            kinderOffer: null,
            languageChoice: null,
            languageOffer: null,
            notes: "",
            participatingSchoolId: schoolDto.key,
            priority: schoolChoiceDetailDtos.length + 1,
            randomNumber: null,
            reasonsForChoosing: [],
            schoolDto,
            score: null,
            status: getStatus(),
            student: oeStudentInfo.studentDto.key
        };
    };

    const handleChange = (e) => {
        const { enrollmentRound } = oeActiveDistrictWindow;
        const { checked, value } = e.currentTarget;
        const tmpFormState = structuredClone(formState);
        const { schoolChoiceDetailDtos } = tmpFormState;
        if (checked) {
            if (schoolChoiceDetailDtos.length < 4) {
                const match = schools.filter((obj) => parseInt(obj.key, 10) === parseInt(value, 10));
                if (match && match.length > 0) {
                    const dto = getSchoolChoiceDetailDto(match[0]);
                    schoolChoiceDetailDtos.push(dto);
                    setSelectedSchoolTab(schoolChoiceDetailDtos.length - 1);
                }
            }
        } else {
            const match = schoolChoiceDetailDtos.filter(
                (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(value, 10)
            );
            if (match && match?.length > 0) {
                if (["CHANGED_ENROLLMENT", "ENROLLED"].includes(match[0].status) && enrollmentRound === "ROUND2") {
                    return false;
                }
            }
            const newList = schoolChoiceDetailDtos.filter(
                (obj) => parseInt(obj.participatingSchoolId, 10) !== parseInt(value, 10)
            );
            tmpFormState.schoolChoiceDetailDtos = newList;
            setSelectedSchoolTab(newList.length - 1);
        }
        formDispatch({
            type: "reset",
            payload: { ...tmpFormState }
        });
    };

    return (
        <section className="schools-container">
            <div className="schools-body">
                {schools &&
                    schools.map((dto, index) => {
                        const { name } = dto.locationDto;
                        const { schoolChoiceDetailDtos } = formState;
                        const uniqueKey = `school-feeder-${dto.locationId}-${index}`;
                        return (
                            <CheckboxButton
                                isChecked={
                                    schoolChoiceDetailDtos.filter(
                                        (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(dto.key, 10)
                                    ).length > 0
                                }
                                id={`school-${dto.key}`}
                                key={uniqueKey}
                                label={name}
                                name={`school-${dto.key}`}
                                handleChange={handleChange}
                                school={dto}
                                value={dto.key.toString()}
                            />
                        );
                    })}
            </div>
        </section>
    );
};

FeederSchools.propTypes = {
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    schools: PropTypes.instanceOf(Array),
    setSelectedSchoolTab: PropTypes.func.isRequired
};

FeederSchools.defaultProps = {
    schools: []
};

export default FeederSchools;
