import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ActionButton from "../../formInputs/buttons/ActionButton";
import States from "../../../const/StatesConst";
import { GlobalContext } from "../../contextProvider/ContextProvider";
import OeAutoSuggest from "../../formInputs/autoSuggest/OeAutoSuggest";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

/**
 * A form that shows all student information. First, last, address, gender ect
 * @param {func} birthdateReset
 * @param {bool} birthdateFlag
 * @param {obj} formState
 * @param {func} formDispatch
 * @param {func} handleChange
 * @param {bool} hasArcAddress
 * @param {func} setHasArcAddress
 * @param {func} showDobResetButton
 * @param {obj} studentIcData
 * @returns {JSX.Element}
 */
const StudentForm = ({
    birthdateReset,
    birthdateFlag,
    formState,
    formDispatch,
    handleChange,
    hasArcAddress,
    setHasArcAddress, // this is (true/false) to disable fields in StudentForm.
    showDobResetButton,
    studentIcData
}) => {
    const { state } = useContext(GlobalContext);
    const { studentNumber, token } = state || {};

    const [day, setDay] = useState("");
    const [hasProfile, setHasProfile] = useState(false);
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");

    const getIsDisabled = () => {
        const { birthDay, birthMonth } = formState;
        if (birthDay?.length === 0 || birthMonth?.length === 0) {
            return true;
        }

        return formState.birthdate?.length > 0 || birthdateFlag;
    };

    const getTooltip = () => {
        return <span>Please fill in the Month and Day fields first!</span>;
    };

    /**
     * This useEffect is only for updating classNames
     */
    useEffect(() => {
        if (token && studentNumber) {
            setHasProfile(true);
        }
    }, [hasProfile, studentNumber, token]);

    useEffect(() => {
        if (formState.birthdate && formState.birthdate?.length > 0) {
            const parsedBirthday = formState.birthdate.split("-");
            if (parsedBirthday?.length > 0) {
                setMonth(parsedBirthday[1]);
                setDay(parsedBirthday[2]);
                setYear(parsedBirthday[0]);
            }
        }
    }, [day, formState, month, year]);

    return (
        <div className="gutter-90">
            <div className="student-info-container-outside">
                <div className="student-info-container">
                    <div className="input-container">
                        <label id="label" htmlFor="firstName">
                            Student Legal First Name: *
                        </label>
                        <input
                            className={hasProfile ? "disabledColor" : ""}
                            disabled={hasProfile}
                            id="firstName"
                            name="firstName"
                            onChange={handleChange}
                            type="text"
                            value={formState?.firstName || ""}
                        />
                        <div className="input-subtext">Use student&apos;s legal first name from birth certificate</div>
                    </div>
                    <div className="input-container">
                        <label id="label" htmlFor="middleName">
                            Student Legal Middle Name:
                        </label>
                        <input
                            className={hasProfile ? "disabledColor" : ""}
                            disabled={hasProfile}
                            id="middleName"
                            name="middleName"
                            onChange={handleChange}
                            type="text"
                            value={formState?.middleName || ""}
                        />
                        <div className="input-subtext">Use student&apos;s legal middle name from birth certificate</div>
                    </div>
                    <div className="input-container">
                        <label id="label" htmlFor="lastName">
                            Student Legal Last Name: *
                        </label>
                        <input
                            className={hasProfile ? "disabledColor" : ""}
                            disabled={hasProfile}
                            id="lastName"
                            name="lastName"
                            onChange={handleChange}
                            type="text"
                            value={formState?.lastName || ""}
                        />
                        <div className="input-subtext">Use student&apos;s legal last name from birth certificate</div>
                    </div>
                    <div className="input-container">
                        <div id="label">Gender: *</div>
                        <div>
                            <input
                                checked={formState.gender === "M"}
                                disabled={hasProfile}
                                id="male"
                                name="gender"
                                onChange={handleChange}
                                type="radio"
                                value="M"
                            />
                            <label className="radio-label" htmlFor="male">
                                Male
                            </label>
                        </div>
                        <div>
                            <input
                                checked={formState.gender === "F"}
                                disabled={hasProfile}
                                id="female"
                                name="gender"
                                onChange={handleChange}
                                type="radio"
                                value="F"
                            />
                            <label className="radio-label" htmlFor="female">
                                Female
                            </label>
                        </div>
                        <div>
                            <input
                                checked={formState.gender === "N"}
                                disabled={hasProfile}
                                id="none"
                                name="gender"
                                onChange={handleChange}
                                type="radio"
                                value="N"
                            />
                            <label className="radio-label" htmlFor="none" value={formState.gender}>
                                Non-binary
                            </label>
                        </div>
                    </div>
                    <div className="top-label dob-label">Student Birthdate: *</div>
                    <div className="input-container">
                        {studentIcData?.birthdate && (
                            <div className="dob-container">
                                <div className="dob-group">
                                    <label htmlFor="birthMonth">Month *</label>
                                    <input
                                        className={hasProfile ? "disabledColor" : ""}
                                        disabled={formState.birthdate?.length > 0 || birthdateFlag}
                                        id="birthMonth"
                                        maxLength={2}
                                        name="birthMonth"
                                        onChange={handleChange}
                                        type="text"
                                        value={month} // see global state variable"
                                    />
                                    <div className="input-subtext">Example: MM</div>
                                </div>
                                <div className="dob-group">
                                    <label htmlFor="birthDay">Day *</label>
                                    <input
                                        className={hasProfile ? "disabledColor" : ""}
                                        disabled={formState.birthdate?.length > 0 || birthdateFlag}
                                        id="birthDay"
                                        maxLength={2}
                                        name="birthDay"
                                        onChange={handleChange}
                                        type="text"
                                        value={day} // see global state variable
                                    />
                                    <div className="input-subtext">Example: DD</div>
                                </div>
                                <div className="dob-group">
                                    <label htmlFor="birthYear">Year *</label>
                                    <input
                                        className={hasProfile ? "disabledColor" : ""}
                                        disabled={formState.birthdate?.length > 0 || birthdateFlag}
                                        type="text"
                                        id="birthYear"
                                        maxLength={4}
                                        name="birthYear"
                                        onChange={handleChange}
                                        value={year} // see global state variable
                                    />
                                    <div className="input-subtext">Example: YYYY</div>
                                </div>
                            </div>
                        )}
                        {!studentIcData && (
                            <div className="dob-container">
                                <div className="dob-group">
                                    <label htmlFor="birthMonth">Month *</label>
                                    <input
                                        disabled={formState.birthdate?.length > 0 || birthdateFlag}
                                        id="birthMonth"
                                        name="birthMonth"
                                        onChange={handleChange}
                                        type="text"
                                        value={formState.birthMonth}
                                    />
                                    <div className="input-subtext">Example: MM</div>
                                </div>
                                <div className="dob-group">
                                    <label htmlFor="birthDay">Day *</label>
                                    <input
                                        disabled={formState.birthdate?.length > 0 || birthdateFlag}
                                        id="birthDay"
                                        name="birthDay"
                                        onChange={handleChange}
                                        type="text"
                                        value={formState.birthDay}
                                    />
                                    <div className="input-subtext">Example: DD</div>
                                </div>
                                {formState?.birthDay?.length === 0 || formState?.birthMonth?.length === 0 ? (
                                    <OverlayTrigger
                                        delay={{ hide: 5, show: 300 }}
                                        overlay={(props) => (
                                            <Tooltip className="tooltip-school" {...props}>
                                                {getTooltip()}
                                            </Tooltip>
                                        )}
                                        placement="top"
                                    >
                                        <div className="dob-group">
                                            <label htmlFor="birthYear">Year *</label>
                                            <input
                                                disabled={getIsDisabled()}
                                                id="birthYear"
                                                maxLength={4}
                                                name="birthYear"
                                                onChange={handleChange}
                                                type="text"
                                                value={formState.birthYear}
                                            />
                                            <div className="input-subtext">Example: YYYY</div>
                                        </div>
                                    </OverlayTrigger>
                                ) : (
                                    <div className="dob-group">
                                        <label htmlFor="birthYear">Year *</label>
                                        <input
                                            disabled={getIsDisabled()}
                                            id="birthYear"
                                            maxLength={4}
                                            name="birthYear"
                                            onChange={handleChange}
                                            type="text"
                                            value={formState.birthYear}
                                        />
                                        <div className="input-subtext">Example: YYYY</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {showDobResetButton && (
                        <div className="input-container">
                            <ActionButton
                                aira-label="Clear Birthdate Fields"
                                className="action-button-birthdate-reset"
                                label="Clear Birthdate Fields"
                                onClick={() => {
                                    birthdateReset();
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="student-info-container">
                    {!studentNumber && (
                        <div className="student-legal-address-container">
                            <div className="top-label">Student&apos;s Legal Address</div>
                            <div className="student-address-text">
                                If you reside in Douglas County or Elbert County, please begin entering your address and
                                select your address from the list of recommended addresses.
                            </div>
                            <div className="input-container ">
                                <OeAutoSuggest
                                    formDispatch={formDispatch}
                                    formState={formState}
                                    hasArcAddress={hasArcAddress}
                                    id="searchAddress"
                                    label="Search Address"
                                    name="searchAddress"
                                    setHasArcAddress={setHasArcAddress}
                                />
                            </div>
                        </div>
                    )}
                    <div className="input-container">
                        <label id="label" htmlFor="address1">
                            Address Line 1: *
                        </label>
                        <div className="search-address-container">
                            <input
                                className={hasProfile ? "disabledColor" : ""}
                                disabled={hasProfile || hasArcAddress}
                                type="text"
                                id="address1"
                                name="addressLine1"
                                onChange={handleChange}
                                value={formState?.addressLine1 || ""}
                            />
                        </div>
                        <div className="input-subtext">Example: 1234 Pine Lake Ave</div>
                    </div>
                    <div className="input-container">
                        <label id="label" className="" htmlFor="address2">
                            Address Line 2:
                        </label>
                        <input
                            className={hasProfile ? "disabledColor" : ""}
                            disabled={hasProfile}
                            type="text"
                            id="address2"
                            name="addressLine2"
                            onChange={handleChange}
                            value={formState?.addressLine2 || ""}
                        />
                        <div className="input-subtext">Example: apartment or unit number</div>
                    </div>
                    <div className="input-container">
                        <label id="label" htmlFor="city">
                            City: *
                        </label>
                        <input
                            className={hasProfile ? "disabledColor" : ""}
                            disabled={hasProfile || hasArcAddress}
                            id="city"
                            type="text"
                            name="city"
                            onChange={handleChange}
                            value={formState?.city || ""}
                        />
                        <div className="input-subtext">Example: Castle Rock</div>
                    </div>
                    <div className="input-container">
                        <label id="label" htmlFor="state">
                            State: *
                        </label>
                        {hasProfile && (
                            <input
                                className={hasProfile ? "disabledColor" : ""}
                                disabled
                                id="state"
                                name="state"
                                type="text"
                                value={formState?.state || "CO"}
                            />
                        )}
                        {!hasProfile && (
                            <select
                                className={hasProfile ? "disabledColor" : ""}
                                disabled={hasProfile || hasArcAddress}
                                id="state"
                                name="state"
                                onChange={handleChange}
                                required
                                value={formState?.state}
                            >
                                <option value="">Select</option>
                                {States &&
                                    States.map((obj, index) => {
                                        const optionKey = `opt-${index}`;
                                        return (
                                            <option key={optionKey} value={obj.value}>
                                                {obj.display}
                                            </option>
                                        );
                                    })}
                            </select>
                        )}
                    </div>
                    <div className="input-container">
                        <label id="label" htmlFor="zip">
                            Zip Code: *
                        </label>
                        <input
                            className={hasProfile ? "disabledColor" : ""}
                            disabled={hasProfile || hasArcAddress}
                            id="zip"
                            maxLength={5}
                            name="zip"
                            onChange={handleChange}
                            type="text"
                            value={formState?.zip || ""}
                        />
                        <div className="input-subtext">Example: 80662</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

StudentForm.propTypes = {
    birthdateFlag: PropTypes.bool,
    birthdateReset: PropTypes.func,
    clearBirthdateFlag: PropTypes.bool,
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]),
    guardianIcInfo: PropTypes.oneOfType([PropTypes.object]),
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hasArcAddress: PropTypes.bool,
    loader: PropTypes.bool,
    oeStudentInfo: PropTypes.oneOfType([PropTypes.object]),
    setClearBirthdateFlag: PropTypes.func,
    setHasArcAddress: PropTypes.func,
    showDobResetButton: PropTypes.bool,
    studentData: PropTypes.oneOfType([PropTypes.object]),
    studentIcData: PropTypes.oneOfType([PropTypes.object]),
    userDetails: PropTypes.oneOfType([PropTypes.object]),
    validatedBirthdayDto: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default StudentForm;
