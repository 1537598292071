import PropTypes from "prop-types";
import ServiceWrapper from "../utils/ServiceWrapper";
import {
    GUARDIAN_INFO_BY_USERNAME,
    STUDENT_INFO_BY_STUDENTNUMBER,
    STUDENT_INFO_STUDENT
} from "../const/StudentInfoConst";

/**
 * Data Access for the User API // based on STUDENT NUMBER
 * @name StudentInfoDao
 * @param props
 * @return {null|*}
 */
const StudentInfoDao = (props) => {
    const { action, username, schoolYearKey, studentNumber, token } = props;
    const bearer = `Bearer ${token}`;
    const options = {
        headers: {
            Authorization: bearer
        },
        withCredentials: true
    };
    switch (action) {
        case "guardianInfoRead":
            options.method = "GET";
            options.url = `${GUARDIAN_INFO_BY_USERNAME}/${username}/detail.json`;
            break;
        case "studentImageRead":
            options.method = "GET";
            options.responseType = "blob";
            options.url = `${STUDENT_INFO_STUDENT}/${studentNumber}/${schoolYearKey}/image`;
            break;
        case "studentInfoByNumberGet":
            options.method = "GET";
            options.url = `${STUDENT_INFO_BY_STUDENTNUMBER}/${studentNumber}/summary.json`;
            break;
        default:
            return null;
    }

    return ServiceWrapper.serviceCall({
        options,
        ...props
    });
};

StudentInfoDao.propTypes = {
    action: PropTypes.string.isRequired,
    params: PropTypes.oneOfType([PropTypes.object]),
    username: PropTypes.string,
    schoolYearKey: PropTypes.number,
    studentNumber: PropTypes.string,
    token: PropTypes.string.isRequired
};

StudentInfoDao.defaultProps = {
    params: null,
    schoolYearKey: null,
    studentNumber: null,
    username: null
};

export default StudentInfoDao;
