import React from "react";
import Icon from "./icon/Icon";

import "../styles/Toolbar.scss";

const Toolbar = () => {
    return (
        <div className="toolbar">
            <span className="oe">
                <Icon className="spaceLeft-spaceRight" height={155} iconName="OPEN_ENROLLMENT_GEARS" width={350} />
            </span>
        </div>
    );
};

export default Toolbar;
