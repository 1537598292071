import React from "react";
import PropTypes from "prop-types";

import "../../styles/CheckboxBlock.scss";

/**
 * A Button that looks and acts like a Radio button
 * @name CheckboxBlock
 * @param {func} handleClick
 * @param {string|null} label
 * @param {string} name
 * @param {string|null} type
 * @return {JSX.Element}
 * @constructor
 */
const CheckboxBlock = ({ comments, handleChange, isChecked, id, label, name, value }) => {
    const checkClass = isChecked ? " checked" : "";

    return (
        <div className={`checkbox-button checkbox-button${checkClass}`}>
            <label htmlFor={id}>
                <input
                    aria-label={`${label} - checkbox`}
                    id={id}
                    checked={isChecked}
                    name={name}
                    onChange={handleChange}
                    tabIndex={0}
                    title={label}
                    type="checkbox"
                    value={value}
                />
                {label}
            </label>
            {label === "Other" && isChecked && (
                <input
                    aria-label={`Other - comments`}
                    id={`${id}-comments`}
                    maxLength={255}
                    name={`${name}-comments`}
                    onChange={handleChange}
                    placeholder="Please enter a comment"
                    title={label}
                    type="text"
                    value={comments}
                />
            )}
        </div>
    );
};

CheckboxBlock.propTypes = {
    comments: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    isChecked: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    oeReasons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
    studentNumber: PropTypes.string,
    value: PropTypes.string
};

CheckboxBlock.defaultProps = {
    comments: "",
    id: "",
    isChecked: false,
    label: "",
    name: "",
    oeReasons: [],
    studentNumber: "",
    value: ""
};

export default CheckboxBlock;
