import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SchoolCardStatic from "../schoolCard/SchoolCardStatic";

import "../../styles/SelectedSchools.scss";

const SelectedSchools = ({ formDispatch, formState, isMutable, oeStudentInfo, parentOffer, schoolFinalize }) => {
    const { schoolChoiceDetailDtos } = formState;

    const [blockOneSchools, setBlockOneSchools] = useState([]);
    const [blockTwoSchools, setBlockTwoSchools] = useState([]);

    useEffect(() => {
        if (schoolChoiceDetailDtos && schoolChoiceDetailDtos.length > 0) {
            const match = schoolChoiceDetailDtos.filter((obj) =>
                ["ACCEPT_OFFER", "DENY_OFFER", "MAKE_OFFER"].includes(obj.status)
            );
            const match2 = schoolChoiceDetailDtos.filter(
                (obj) => !["ACCEPT_OFFER", "DENY_OFFER", "MAKE_OFFER"].includes(obj.status)
            );
            if (match && match?.length > 0) {
                setBlockOneSchools(match);
            }
            if (match2 && match2.length > 0) {
                setBlockTwoSchools(match2);
            }
        }
    }, [schoolChoiceDetailDtos]);

    return (
        <section className="selected-schools-container-static">
            <h5 className="dark-blue-text">My Selected Schools</h5>
            <p className="dark-blue-text">These are the schools that you have selected for Open Enrollment.</p>
            {parentOffer ? (
                <div className="selected-schools-blocks-container">
                    {blockOneSchools && blockOneSchools.length > 0 && (
                        <div className="selected-schools-blocks">
                            <div className="block-text">
                                <h6>
                                    You <u>received offers</u> from the following schools:
                                </h6>
                                <span>
                                    You may accept one school offer. All other offers will automatically be declined.
                                    You may also decline any/all school offers.
                                </span>
                            </div>
                            <div className="block-school">
                                {blockOneSchools.map((school, index) => {
                                    const uniqueKey = `selected-school-static-${index}`;
                                    return (
                                        <SchoolCardStatic
                                            formDispatch={formDispatch}
                                            formState={formState}
                                            isMutable={isMutable}
                                            key={uniqueKey}
                                            oeStudentInfo={oeStudentInfo}
                                            parentOffer={parentOffer}
                                            school={school}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {blockTwoSchools && blockTwoSchools.length > 0 && (
                        <div className="selected-schools-blocks">
                            <div className="block-text">
                                <h6>
                                    You will be added to the <u>waitlist</u> for the following schools:
                                </h6>
                                <span>
                                    During Round 2 of the Open enrollment Process, these school(s) may contact you
                                    directly to extend an offer of enrollment.
                                </span>
                            </div>
                            <div className="block-school">
                                {blockTwoSchools.map((school, index) => {
                                    const uniqueKey = `selected-school-static-${index}`;
                                    return (
                                        <SchoolCardStatic
                                            formDispatch={formDispatch}
                                            formState={formState}
                                            key={uniqueKey}
                                            oeStudentInfo={oeStudentInfo}
                                            parentOffer={parentOffer}
                                            school={school}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {!blockOneSchools.length > 0 && !blockTwoSchools.length > 0 && (
                        <h2>Your student does not have any school choices at the moment</h2>
                    )}
                </div>
            ) : (
                <div className="selected-schools-block">
                    {schoolChoiceDetailDtos && schoolChoiceDetailDtos.length > 0 ? (
                        schoolChoiceDetailDtos.map((school, index) => {
                            const uniqueKey = `selected-school-static-${index}`;
                            return (
                                <SchoolCardStatic
                                    formDispatch={formDispatch}
                                    formState={formState}
                                    key={uniqueKey}
                                    oeStudentInfo={oeStudentInfo}
                                    school={school}
                                    schoolFinalize={schoolFinalize}
                                />
                            );
                        })
                    ) : (
                        <h2>Your student does not have any school choices at the moment</h2>
                    )}
                </div>
            )}
        </section>
    );
};

SelectedSchools.propTypes = {
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    isMutable: PropTypes.bool,
    oeStudentInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
    parentOffer: PropTypes.oneOfType([PropTypes.object]),
    schoolFinalize: PropTypes.oneOfType([PropTypes.object])
};

SelectedSchools.defaultProps = {
    isMutable: true,
    parentOffer: null,
    schoolFinalize: null
};

export default SelectedSchools;
