import React from "react";
import PropTypes from "prop-types";

import "../../../styles/AutoSuggest.scss";
import "../../../styles/ActionButton.scss";

/**
 * Build the auto complete list
 * @name OptionList
 * @param {string} className
 * @param {[]} filteredOptions
 * @param {func} formDispatch
 * @param {{}} formState
 * @param {func} setActiveOption
 * @param {func} setSearchFlag
 * @param {func} setShowOptions
 * @param {bool} showOptions
 * @component
 */
const OptionList = ({
    className,
    filteredOptions,
    formDispatch,
    formState,
    message1,
    resetAutoSuggest,
    setActiveOption,
    setHasArcAddress,
    setSearchFlag,
    setShowOptions,
    showOptions
}) => {
    /**
     * When an item of the options list is clicked show
     * valid addresses from the api call
     * @name handleClick
     * @param {number} index
     */
    const handleClick = (index) => {
        setActiveOption(index);
        const selectedAddress = filteredOptions[index];
        const tempFormState = structuredClone(formState);

        tempFormState.addressLine1 = selectedAddress?.fullAddress;
        tempFormState.addressLine2 = null;
        tempFormState.city = selectedAddress?.city;
        tempFormState.searchAddress = selectedAddress?.fullAddress;
        tempFormState.state = "CO";
        tempFormState.zip = selectedAddress?.zip;

        formDispatch({
            type: "reset",
            payload: { ...tempFormState }
        });

        setShowOptions(false);
        if (setSearchFlag) {
            setSearchFlag(true);
        }
        setHasArcAddress(true); // this is (true/false) to disable fields in StudentForm.
    };

    if (showOptions && formState?.searchAddress?.length > 2) {
        if (filteredOptions?.length) {
            return (
                <div className="auto-suggest-contain">
                    <div className={className}>
                        <ul>
                            <li className="border-bottom">
                                <div
                                    style={{ fontSize: "1.2em" }}
                                    type="button"
                                    onClick={() => {
                                        resetAutoSuggest();
                                    }}
                                >
                                    <div className="option-close-button">
                                        <i className="bi bi-x-lg"></i>
                                    </div>
                                </div>
                            </li>
                            {filteredOptions.map((dto, index) => {
                                const uniqueKey = `autoSuggest-${index}`;
                                return (
                                    // <li className="names" key={uniqueKey}>
                                    <li key={uniqueKey}>
                                        <button
                                            className="btn"
                                            onClick={() => handleClick(index)}
                                            onKeyDown={() => handleClick(index)}
                                            type="button"
                                        >
                                            <div className="single">{`${dto.fullAddress}`}</div>
                                            <div className="single">{`${dto.city} ${dto.zip}`}</div>
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            );
        }
        return (
            <div className="auto-suggest-contain">
                <div className={className}>
                    <ul>
                        <li className="border-bottom" style={{ marginBottom: "20px" }}>
                            <div
                                type="button"
                                onClick={() => {
                                    resetAutoSuggest();
                                }}
                            >
                                <div style={{ fontSize: "1.2em" }}>
                                    <div className="option-close-button">
                                        <i className="bi bi-x-lg"></i>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="btn" style={{ cursor: "auto" }}>
                                {message1 ||
                                    "No valid address found for Douglas County or Elbert County. Please manually enter your home address in the fields below."}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    return null;
};

OptionList.propTypes = {
    className: PropTypes.string,
    filteredOptions: PropTypes.instanceOf(Array),
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    hasArcAddress: PropTypes.bool,
    message1: PropTypes.string,
    message2: PropTypes.string,
    message3: PropTypes.string,
    resetAutoSuggest: PropTypes.func.isRequired,
    setActiveOption: PropTypes.func.isRequired,
    setHasArcAddress: PropTypes.func,
    setProbate: PropTypes.func,
    setSearchFlag: PropTypes.func,
    setShowOptions: PropTypes.func.isRequired,
    showOptions: PropTypes.bool,
    showResetButton: PropTypes.bool
};

OptionList.defaultProps = {
    className: "auto-suggest",
    filteredOptions: [],
    hasArcAddress: false,
    message1: "",
    message2: "",
    message3: "",
    setProbate: null,
    setSearchFlag: null,
    showOptions: false,
    showResetButton: false
};

export default OptionList;
