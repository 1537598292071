import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FeederTab from "./FeederTab";
import FeederSchools from "./FeederSchools";

import "../../styles/FeederTabs.scss";

const FeederBlock = ({ currentFeeder, feeders, formDispatch, formState, oeStudentInfo, setSelectedSchoolTab }) => {
    const [isFeeder, setIsFeeder] = useState(0);
    const [schools, setSchools] = useState(feeders[Object.keys(feeders)[0]]);
    const [selectedTab, setSelectedTab] = useState(0);

    const handleClick = (feeder, index) => {
        setSchools(feeders[feeder]);
        setSelectedTab(index);
    };

    useEffect(() => {
        if (currentFeeder) {
            const index = Object.keys(feeders).findIndex((item) => item === currentFeeder);
            if (index !== -1) {
                setIsFeeder(index);
                setSchools(feeders[currentFeeder]);
                setSelectedTab(index);
            }
        }
    }, [currentFeeder, feeders]);

    return (
        <section className="feeder-container">
            <h5 className="dark-blue-text">Participating Schools by Region (select up to 4 schools)</h5>
            <p className="dark-blue-text">
                Please select a district region to see a list of schools available for open enrollment. You must select
                at least one (1) school and may select a maximum of four (4) schools for open enrollment.
            </p>
            <div className="feeder-tab-container">
                {feeders &&
                    Object.keys(feeders).map((feeder, index) => {
                        const uniqueKey = `feeder-tab-${index}`;
                        const { schoolChoiceDetailDtos } = formState;
                        const countArr = feeders[feeder].reduce((results, school) => {
                            const { key } = school;
                            const match = schoolChoiceDetailDtos.filter(
                                (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(key, 10)
                            );
                            if (match && match.length > 0) {
                                results.push(true);
                            }

                            return results;
                        }, []);
                        return (
                            <FeederTab
                                count={countArr.length}
                                feeder={feeder}
                                feederRegion={oeStudentInfo?.feederRegion}
                                handleClick={() => {
                                    handleClick(feeder, index);
                                }}
                                isFeeder={isFeeder === index}
                                isSelected={index == selectedTab}
                                key={uniqueKey}
                            />
                        );
                    })}
            </div>
            {schools.length > 0 && (
                <FeederSchools
                    formDispatch={formDispatch}
                    formState={formState}
                    schools={schools}
                    setSelectedSchoolTab={setSelectedSchoolTab}
                />
            )}
        </section>
    );
};

FeederBlock.propTypes = {
    currentFeeder: PropTypes.string,
    feeders: PropTypes.oneOfType([PropTypes.object]).isRequired,
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]),
    oeStudentInfo: PropTypes.oneOfType([PropTypes.object]),
    setSelectedSchoolTab: PropTypes.func.isRequired
};

FeederBlock.defaultProps = {
    currentFeeder: null,
    oeStudentInfo: null
};

export default FeederBlock;
