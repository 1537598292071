import { SERVICE_HOST } from "../utils/auth/config";

export const OE_SERVICE = `${SERVICE_HOST}/open-enrollment-sb/v0`;
export const OE_STUDENT = `${OE_SERVICE}/student`;

export const OE_ACTION_CONTROLS = `${OE_SERVICE}/action_controller`;
export const OE_ACTIVE_CONTROLS = `${OE_ACTION_CONTROLS}/active/by_differentiator`;
export const OE_ACTIVE_DISTRICT_WINDOW = `${OE_SERVICE}/district_window/active_window/detail.json`;
export const OE_GUARDIAN_EMAIL = `${OE_SERVICE}/school_choice/email_guardian`; // /<studentKey>/<schoolYearKey>/detail.json`
export const OE_GUARDIAN = `${OE_SERVICE}/guardian`;
export const OE_REASONS = `${OE_SERVICE}/questionnaire/question/index.json`;
export const OE_STUDENT_INFO = `${OE_STUDENT}/details`; // /<studentKey>/detail.json
export const OE_STUDENT_BIRTHDATE = `${OE_STUDENT}/expected_grades`; // /detail.json?birthDate={YYYY-mm-dd}&&extWindowStartDate={YYYY-mm-dd HH:ii:ss}&&studentKey=<studentKey>&&oeRecCreateDt={YYYY-mm-dd HH:ii:ss}&&schoolYearId=<schoolYearKey>
export const OE_NEW_STUDENT = `${OE_SERVICE}/student`; // detail.json

export const OE_SCHOOLS_BY_STUDENT_AND_ROUND = `${OE_SERVICE}/participating_school/by_student`; // /<studentKey>/<schoolYearKey>/index.json?enrollmentRound={round1 or round2}
