import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import RbA from "../components/rba/RbA.jsx";
import Header from "../components/header/Header.jsx";
import StudentForm from "../components/formComponents/forms/StudentForm.jsx";
import { GlobalContext } from "../components/contextProvider/ContextProvider.jsx";
import ActionButton from "../components/formInputs/buttons/ActionButton.jsx";
import SaveBlock from "../components/saveBlock/SaveBlock.jsx";
import FormReducer from "../utils/FormReducer.jsx";
import { GUARDIAN_DASHBOARD } from "../utils/auth/config.js";
import StudentInfoDao from "../dao/StudentInfoDao.jsx";
import OpenEnrollmentDao from "../dao/OpenEnrollmentDao.jsx";
import BreadCrumb from "../components/breadCrumb/BreadCrumb.jsx";
import ValidationPatterns from "../utils/ValidationPatterns.jsx";
import GuardianAddressBlock from "../components/addressBlock/GuardianAddressBlock.jsx";
import StudentAddressBlock from "../components/addressBlock/StudentAddressBlock.jsx";
import DcsdFormModal from "../components/modals/DcsdFormModal.jsx";
import DcsdDialog from "../components/modals/DcsdDialog.jsx";
import InformationIncorrectListForm from "../components/formComponents/modalForms/InformationIncorrectListForm.jsx";
import NoEscape from "../utils/NoEscape.jsx";
import ReasonsBlock from "../components/reasonsBlock/ReasonsBlock.jsx";
import { gradeTranslator, nextGrade, numberGradeToString } from "../const/Translations.jsx";
import UserDao from "../dao/UserDao.jsx";
import Toolbar from "../components/Toolbar.jsx";
import WindowAndPermissionDialog from "../components/modals/WindowAndPermissionDialog.jsx";
import { getTodayWithTime, getEpoch } from "../utils/DateFormatter.jsx";
import GradeBlock from "../components/gradeLevelBlock/GradeBlock.jsx";
import LoadingSvg from "../components/loadingSvg/LoadingSvg.jsx";

import "../styles/Guardian_StudentForm.scss";

/**
 * This will render the form and components for showing an existing Open Enrollment record.
 * Existing Guardian + Existing Student (IC data)
 * @name StudentNumber
 * @return {JSX.Element}
 */
const StudentNumber = () => {
    const { number } = useParams();
    const { dispatch, state } = useContext(GlobalContext);
    const { oeActiveDistrictWindow, studentNumber, token, userDetails } = state || {};
    const navigate = useNavigate();

    const initialFormState = {
        addressLine1: "",
        addressLine2: "",
        birthdate: "",
        city: "",
        firstName: "",
        gender: "",
        gradeApplying: "",
        gradeName: "",
        lastName: "",
        middleName: "",
        reasonsForLeaving: [], // pushing data here from ReasonsBlock
        schoolYearKey: null,
        state: "",
        studentNumber: null,
        zip: ""
    };
    const allowedRolesArray = ["EXTERNAL_GUARDIAN", "GUARDIAN"];

    const [formState, formDispatch] = useReducer(FormReducer, initialFormState);

    const [day, setDay] = useState("");
    const [enableButton, setEnableButton] = useState(false);
    const [extensionWindow, setExtensionWindow] = useState(false);
    const [guardianInfo, setGuardianInfo] = useState(null);
    const [hasReasons, setHasReasons] = useState(false);
    const [loader, setLoader] = useState(false);
    const [month, setMonth] = useState("");
    const [oeActionControls, setOeActionControls] = useState(null);
    const [oeReasons, setOeReasons] = useState(null);
    const [oeReasonsFlag, setOeReasonsFlag] = useState(true);
    const [open, setOpen] = useState("false");
    const [outOfBounds, setOutOfBounds] = useState(false);
    const [preSchool, setPreSchool] = useState(false);
    const [schoolYearRange, setSchoolYearRange] = useState(null); // used to show 2004 - 2005 range
    const [sorryMessage, setSorryMessage] = useState("");
    const [sorryTitle, setSorryTitle] = useState("");
    const [studentInfoGate, setStudentInfoGate] = useState(false);
    const [studentOeData, setStudentOeData] = useState(null);
    const [studentIcData, setStudentIcData] = useState(null);
    const [validatedBirthdayDto, setValidatedBirthdayDto] = useState(null);
    const [year, setYear] = useState("");

    const isNewStudent = true;

    /**
     * NoEscape prevents the escape key from closing modals
     */
    NoEscape();

    const nextGradeToString = (grade) => {
        if (formState) {
            if (["PRE", "K"].includes(gradeTranslator(grade))) {
                return nextGrade(gradeTranslator(grade));
            }
            const number = parseInt(grade, 10) + 1;
            const padNumber = number.toString().padStart(2, "0");

            return padNumber;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "zip") {
            if (!ValidationPatterns.digitOnly.test(value) && value !== "") {
                return false;
            }
        }
        formDispatch({
            type: "text",
            field: name,
            payload: value
        });
    };

    const handleDelete = () => {
        const options = {
            action: "oeByStudentKeyDelete",
            key: studentOeData?.studentDto?.key,
            token
        };
        setLoader(true);
        OpenEnrollmentDao(options).then((response) => {
            if (response) {
                const { errors } = response.data;
                if (errors && errors?.length > 0) {
                    let errorMessage = "Unable to Delete Student Open Enrollment.";
                    errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                    toast.error(`${errorMessage}`, {
                        autoClose: false,
                        closeOnClick: true,
                        style: { width: "100%", whiteSpace: "break-spaces" }
                    });
                    setOpen("false");

                    return false;
                }
                toast.success(
                    "Open Enrollment application successfully deleted for student. Redirecting to Parent Dashboard. ",
                    {
                        autoClose: false
                    }
                );
                setOpen("false");
                setTimeout(() => {
                    sessionStorage.clear();
                    window.location.replace(GUARDIAN_DASHBOARD);
                }, 3000);

                return true;
            }
            setLoader(false);
        });
    };

    const getDeleteActions = () => {
        return (
            <>
                <ActionButton
                    className="action-button-cancel"
                    label="Cancel"
                    onClick={() => {
                        setOpen("false");
                    }}
                />
                <ActionButton
                    className="action-button-delete"
                    disable={loader}
                    label="Delete Open Enrollment"
                    onClick={() => {
                        handleDelete();
                    }}
                />
            </>
        );
    };

    /**
     * First: need to POST a new student (if noStudentOeData)
     *  Then: need to Get that student by key
     *  Then submit
     */
    const saveIcDataToOeData = () => {
        if (token && !studentOeData) {
            const newStudentData = {
                address: {
                    addressLine1: formState.addressLine1,
                    addressLine2: formState.addressLine2,
                    city: formState.city,
                    state: formState.state,
                    zip: formState.zip
                },
                birthdate: formState.birthdate,
                birthMonth: month,
                birthDay: day,
                birthYear: year,
                feederLocationKey: null,
                firstName: formState?.firstName,
                gender: formState.gender,
                gradeApplying: numberGradeToString(formState.gradeApplying),
                gradeName: numberGradeToString(formState.gradeName),
                gridCode: null,
                key: null,
                lastName: formState?.lastName,
                locationKey: null,
                middleName: formState?.middleName,
                oodSchoolName: null,
                oodSchoolCity: null,
                oodSchoolState: null,
                overrideStatus: "DEFAULT_LOCATION_CHECK",
                parentGuid: userDetails.uid,
                personId: studentIcData ? studentIcData.key : "",
                schoolYearKey: oeActiveDistrictWindow ? oeActiveDistrictWindow.schoolYearKey : "",
                studentNumber: studentNumber
            };
            const options = {
                action: "oeNewStudentPost",
                data: newStudentData,
                token
            };
            setLoader(true);
            // **** POST IC DATA TO OE FOR THE FIRST TIME **********************************************************
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload && payload !== null) {
                        /**
                         * If saved to OE data table is good, then use the response key
                         * to GET the student info from OE below.
                         * This is needed (for submission) after the initial POST to OE
                         */
                        if (payload) {
                            const options2 = {
                                action: "oeByStudentKeyRead",
                                key: payload.key,
                                token
                            };
                            // **** GET STUDENT DATA FROM OE *******************************************************
                            OpenEnrollmentDao(options2).then((response2) => {
                                if (response2) {
                                    if (response2.data.payload) {
                                        const tempDto = response2.data.payload;
                                        tempDto.reasonsForLeaving = formState.reasonsForLeaving;

                                        tempDto.reasonsForLeaving.forEach((item) => {
                                            item.locationKey = tempDto.studentDto.locationKey;
                                            item.studentId = tempDto.studentDto.key;
                                        });

                                        const options3 = {
                                            action: "existingStudentPut",
                                            data: tempDto,
                                            key: tempDto.studentDto.key,
                                            token
                                        };
                                        // **** PUT ****************************************************************
                                        OpenEnrollmentDao(options3).then((response3) => {
                                            if (response3) {
                                                const { studentDto } = response3.data.payload;
                                                const { key } = studentDto;

                                                toast.success(
                                                    "Successfully saved student information for Open Enrollment",
                                                    { autoClose: 3000 }
                                                );
                                                setTimeout(() => {
                                                    navigate(`/school-choice/${key}`);
                                                }, 3000);
                                                const { errors } = response3.data;
                                                if (errors) {
                                                    toast.error(errors[0], { autoClose: false });
                                                }
                                                setStudentOeData(response3.data.payload);
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    } else if (errors && errors.length > 0) {
                        setEnableButton(false);
                        toast.error(errors[0], { autoClose: false });
                    }
                }
                setLoader(false);
            });
        }
    };

    const handleSubmit = () => {
        if (!studentOeData) {
            // If its the first time on Open Enrollment then we need to POST to OE. See below method.
            saveIcDataToOeData();
        } else {
            // else then copy OE data and change the reasonsForLeaving
            const newStudentData = studentOeData;
            newStudentData.reasonsForLeaving = formState.reasonsForLeaving;
            newStudentData.studentDto.gradeApplying = formState.gradeApplying;
            const options = {
                action: "existingStudentPut",
                data: newStudentData,
                key: studentOeData?.studentDto?.key,
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        toast.success("Successfully updated student information", { autoClose: 3000 });
                        setTimeout(() => {
                            navigate(`/school-choice/${payload.studentDto?.key}`);
                        }, 3000);
                    }
                }
                setLoader(false);
            });
        }
    };

    const showGradeBlock = () => {
        return formState && formState.gradeName?.length > 0 && formState.gradeApplying?.length > 0 && schoolYearRange;
    };

    /**
     * Get the list of reasons to leave or attend a school
     */
    useEffect(() => {
        if (oeReasonsFlag && !oeReasons) {
            setOeReasonsFlag(false);
            const options = {
                action: "oeGetReasons",
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload) {
                        const { payload } = response.data;
                        payload.sort((a, b) => {
                            return a.questionText > b.questionText ? 1 : -1;
                        });
                        const other = payload.filter((obj) => obj.questionText.toLowerCase() === "other");
                        if (other && other.length > 0) {
                            const match = payload.filter((obj) => obj.questionText.toLowerCase() !== "other");
                            match.push(other[0]);

                            setOeReasons(match);
                        }
                    } else {
                        toast.error(errors[0]);
                    }
                }
                setLoader(false);
            });
        }
    }, [oeReasons, oeReasonsFlag, token]);

    /**
     * Get IC guardian information (infinite campus)
     */
    useEffect(() => {
        if (token && userDetails && !guardianInfo) {
            const { roleDtos } = userDetails;
            const match = roleDtos.filter((obj) => obj.role === "GUARDIAN");
            if (match && match.length > 0) {
                const options = {
                    action: "guardianInfoRead",
                    username: userDetails?.username,
                    token
                };
                StudentInfoDao(options).then((response) => {
                    if (response) {
                        const { errors, payload } = response.data;
                        if (payload) {
                            setGuardianInfo(payload);
                        } else if (errors && errors.length > 0) {
                            setGuardianInfo(null);
                            toast.error("There was a problem loading your data. Please reload the page and try again");
                        }
                    }
                });
            } else {
                setGuardianInfo(userDetails);
            }
        }
    }, [guardianInfo, token, userDetails]);

    /**
     * Get the schoolYearKey and dispatch to state (contextProvider)
     * this call is from OE current district window
     */
    useEffect(() => {
        if (token && !oeActiveDistrictWindow) {
            const options = {
                action: "oeActiveDistrictWindowRead",
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        dispatch({
                            type: "OeActiveDistrictWindow",
                            oeActiveDistrictWindow: payload
                        });
                    } else if (payload === null) {
                        setOutOfBounds(true);
                        setSorryTitle("Open Enrollment Window Closed");
                        setSorryMessage(
                            "The Open Enrollment window is now closed. Please return during the district's parent-access windows. Thank you."
                        );
                        setOpen("out-of-bounds");
                    }
                }
                setLoader(false);
            });
        }
    }, [dispatch, oeActiveDistrictWindow, token]);

    /**
     * Get the school year range from DB
     * Used in Edit page in GradeLevelBlock area to display range (ex: 2025 - 2026)
     */
    useEffect(() => {
        if (token && oeActiveDistrictWindow && !schoolYearRange) {
            const nextYear = parseInt(oeActiveDistrictWindow.schoolYearKey, 10) + 1;
            const options = {
                action: "userSchoolYearRange",
                schoolYearKey: nextYear,
                token
            };
            setLoader(true);
            UserDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        setSchoolYearRange(payload);
                    }
                }
                setLoader(false);
            });
        }
    }, [oeActiveDistrictWindow, schoolYearRange, token]);

    /**
     * Get the Action Controls for Open Enrollment
     * Should I check the start and end dates?
     */
    useEffect(() => {
        if (token && oeActiveDistrictWindow && !oeActionControls) {
            const options = {
                action: "oeActionControls",
                params: {
                    differentiator: oeActiveDistrictWindow.key
                },
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        const match = payload.filter((obj) => obj.action === "PARENT_REQUEST");
                        if (match && match.length > 0) {
                            setOeActionControls(payload);
                        } else {
                            setOutOfBounds(true);
                            setSorryTitle("Open Enrollment Window Closed");
                            setSorryMessage(
                                "The Open Enrollment window is now closed. Please return during the district's parent-access windows. Thank you."
                            );
                            setOpen("out-of-bounds");
                        }
                    }
                }
                setLoader(false);
            });
        }
    }, [token, oeActionControls, oeActiveDistrictWindow]);

    /**
     * Dont need to make this call if existing student
     */
    useEffect(() => {
        if (token && formState?.birthdate?.length > 0 && !validatedBirthdayDto && !isNewStudent) {
            // If the student is new then dont need to make this call. !isNewStudent defaults to true
            const options = {
                action: "oeStudentBirthdate",
                params: {
                    birthDate: formState.birthdate
                },
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload) {
                        setValidatedBirthdayDto(payload);
                    } else {
                        setValidatedBirthdayDto(errors[0]);
                    }
                }
                setLoader(false);
            });
        }
    }, [formState, isNewStudent, token, validatedBirthdayDto]);

    /**
     * Check OE table data, if no OE data, go get IC data
     */
    useEffect(() => {
        if (token && oeActiveDistrictWindow && !studentIcData) {
            // studentNumber
            const options = {
                action: "studentInfoByNumberGet",
                studentNumber,
                token
            };
            setLoader(true);
            StudentInfoDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    setStudentIcData(payload);
                } else if (studentNumber && !studentIcData) {
                    setOutOfBounds(true);
                    setSorryMessage(
                        "Sorry, but you do not have permission to view this student. Please return to Parent Dashboard."
                    );
                    setSorryTitle("Access Denied");
                    setOpen("out-of-bounds");
                }
                setLoader(false);
            });
        }
    }, [number, oeActiveDistrictWindow, studentIcData, studentNumber, token]);

    /**
     * Get studentKey from Open Enrollment DB
     * if we have OE data.. use that
     * else go get IC data... and use that
     */
    useEffect(() => {
        if (number && oeActiveDistrictWindow && token && !studentOeData) {
            // studentKey
            const options = {
                action: "oeByStudentNumberRead",
                schoolYearKey: oeActiveDistrictWindow.schoolYearKey,
                params: {
                    studentNumber: number
                },
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload && payload.length > 0) {
                        // call the detailed studentInfo api for larger DTO
                        const options = {
                            action: "oeByStudentKeyRead",
                            key: payload[0].key,
                            token
                        };
                        OpenEnrollmentDao(options).then((response) => {
                            if (response) {
                                const { payload } = response.data;
                                if (payload) {
                                    setStudentOeData(payload);
                                    setStudentInfoGate(true);
                                }
                            }
                        });
                    } else {
                        const options = {
                            action: "studentInfoByNumberGet",
                            studentNumber,
                            token
                        };
                        StudentInfoDao(options).then((response) => {
                            if (response) {
                                const { payload } = response.data;
                                setStudentIcData(payload);
                            }
                        });
                    }
                }
                setLoader(false);
            });
        }
    }, [number, oeActiveDistrictWindow, studentNumber, studentOeData, token]);

    /**
     * Pluck out the month, day, year from birthdate
     */
    useEffect(() => {
        if (formState.birthdate) {
            const parsedBirthday = formState.birthdate ? formState.birthdate.split("-") : "";

            setMonth(parsedBirthday[1]);
            setDay(parsedBirthday[2]);
            setYear(parsedBirthday[0]);
        }
    }, [day, formState, month, year]);

    /**
     * Check if the Open Enrollment window is open or closed
     * If outOfBounds === false, then the window is open
     */
    useEffect(() => {
        if (oeActiveDistrictWindow && !outOfBounds) {
            const { endDate, startDate } = oeActiveDistrictWindow;
            const today = getTodayWithTime();
            if (getEpoch(endDate) > getEpoch(today) && getEpoch(startDate) < getEpoch(today)) {
                setOutOfBounds(false);
            } else {
                setOutOfBounds(true);
                setSorryTitle("Open Enrollment Window Closed");
                setSorryMessage(
                    "The Open Enrollment window is now closed. Please return during the district's parent-access windows. Thank you."
                );
                setOpen("out-of-bounds");
            }
        }
    }, [oeActiveDistrictWindow, outOfBounds]);

    /**
     * Check to make sure they have reasonsForLeaving before they can save
     */
    useEffect(() => {
        if (oeReasons && formState.reasonsForLeaving.length > 0) {
            setHasReasons(true);
            const { reasonsForLeaving } = formState;
            const otherReason = oeReasons.filter((obj) => obj.questionText.toLowerCase() === "other");
            if (otherReason && otherReason.length > 0) {
                const other = reasonsForLeaving.filter(
                    (obj) => parseInt(obj.questionId, 10) === parseInt(otherReason[0].key, 10)
                );
                if (other && other.length > 0) {
                    if (other[0].comments?.length < 1) {
                        // if the user has selected "Other" as a reasons for leaving, make sure they entered a comment
                        setHasReasons(false);
                    }
                }
            }
        } else {
            setHasReasons(false);
        }
    }, [formState, oeReasons]);

    /**
     * This useEffect is checking the formState for validation.
     * if any of the fields below are not filled out, the save button will not be active
     */
    useEffect(() => {
        if (studentNumber) {
            setEnableButton(true);
        }
        if (
            formState.zip &&
            formState.birthdate &&
            formState.city?.trim().length > 0 &&
            formState.city &&
            formState.firstName?.trim().length > 0 &&
            formState.gender?.trim().length &&
            formState.lastName?.trim().length > 0 &&
            formState.state?.trim().length > 0 &&
            formState.zip?.trim().length > 0 &&
            gradeTranslator(formState.gradeName) !== "-" &&
            gradeTranslator(formState.gradeApplying) !== "-"
        ) {
            setEnableButton(true);
        } else {
            setEnableButton(false);
        }
    }, [formState, studentNumber]);

    /**
     * Dispatch params
     */
    useEffect(() => {
        if (number && !studentNumber) {
            dispatch({
                type: "StudentNumber",
                studentNumber: number
            });
        }
    }, [dispatch, number, studentNumber]);

    /**
     * Send values to formState
     */
    useEffect(() => {
        if (oeActiveDistrictWindow && userDetails && (studentOeData || studentIcData)) {
            const updatedFormState = structuredClone(formState);

            if (studentOeData) {
                const { reasonsForLeaving, studentDto } = studentOeData;
                const { address, birthdate, firstName, gender, gradeApplying, gradeName, lastName, middleName } =
                    studentDto;
                updatedFormState.addressLine1 = address?.addressLine1?.trim();
                updatedFormState.addressLine2 = address?.addressLine2;
                updatedFormState.birthdate = birthdate?.split("T")[0];
                updatedFormState.city = address?.city;
                updatedFormState.firstName = firstName?.trim();
                updatedFormState.gender = gender;
                updatedFormState.gradeApplying = gradeApplying;
                updatedFormState.gradeName = gradeName;
                updatedFormState.lastName = lastName?.trim();
                updatedFormState.middleName = middleName?.trim();
                updatedFormState.reasonsForLeaving = reasonsForLeaving;
                updatedFormState.state = address?.state;
                updatedFormState.zip = address?.zip;
            } else {
                const {
                    address,
                    birthdate,
                    firstName,
                    gender,
                    gradeName,
                    guardians,
                    lastName,
                    middleName,
                    schoolName,
                    schoolYearName
                } = studentIcData;
                updatedFormState.addressLine1 = address?.addressLine1?.trim();
                updatedFormState.addressLine2 = address?.addressLine2?.trim();
                updatedFormState.birthdate = birthdate.split("T")[0];
                updatedFormState.city = address?.city;
                updatedFormState.firstName = firstName?.trim();
                updatedFormState.gender = gender;
                updatedFormState.gradeApplying = extensionWindow
                    ? gradeTranslator(studentIcData.gradeName)
                    : nextGradeToString(studentIcData?.gradeName);
                updatedFormState.gradeName = gradeTranslator(gradeName);
                updatedFormState.guardians = guardians;
                updatedFormState.lastName = lastName?.trim();
                updatedFormState.middleName = middleName?.trim();
                updatedFormState.reasonsForLeaving = [];
                updatedFormState.schoolName = schoolName;
                updatedFormState.schoolYearName = schoolYearName;
                updatedFormState.state = address?.state;
                updatedFormState.zip = address?.zip;
            }

            formDispatch({
                type: "reset",
                payload: { ...updatedFormState }
            });
        }
        // eslint-disable-next-line
    }, [extensionWindow, preSchool, studentIcData, studentOeData, userDetails]);

    useEffect(() => {
        if (oeActionControls) {
            const match = oeActionControls.filter((obj) => obj.action === "SCHOOL_YEAR_EXTENSION_WINDOW");
            if (match && match.length > 0) {
                setExtensionWindow(true);
            }
        }
    }, [oeActionControls]);

    /**
     * Dont show the reasons if the grade applying is PRE or K
     */
    useEffect(() => {
        if (!preSchool) {
            const { gradeApplying } = formState;
            if (["PRE", "K"].includes(gradeApplying)) {
                setPreSchool(true);
            }
        }
    }, [formState, preSchool]);

    return (
        <RbA allowedRoles={allowedRolesArray} redirect="/notFound">
            <Header />
            <Toolbar />
            <BreadCrumb label="Open Enrollment > Student & Guardian Information" />
            <div className="gutter-90">
                {(studentIcData || studentOeData) && (
                    <>
                        <div className="address-block-container">
                            {guardianInfo && (
                                <GuardianAddressBlock
                                    formState={formState}
                                    guardianInfo={guardianInfo}
                                    label1="Guardian's"
                                    name="view"
                                />
                            )}
                            {studentNumber && (
                                <StudentAddressBlock label1="Student's" name="icData" studentIcData={studentIcData} />
                            )}
                        </div>
                        <div className="mt-5 mb-3">
                            <hr />
                        </div>
                        <StudentForm
                            formState={formState}
                            formDispatch={formDispatch}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            setStudentInfoGate={setStudentInfoGate}
                            studentInfoGate={studentInfoGate}
                            studentIcData={studentIcData}
                            studentNumber={studentNumber}
                        />
                        {showGradeBlock() && <GradeBlock formState={formState} schoolYearRange={schoolYearRange} />}
                        {studentInfoGate && !preSchool && (
                            <ReasonsBlock
                                formDispatch={formDispatch}
                                formState={formState}
                                oeReasons={oeReasons}
                                studentIcData={studentIcData}
                                studentOeData={studentOeData}
                                studentNumber={studentNumber}
                            />
                        )}
                        <SaveBlock
                            enableButton={enableButton}
                            formState={formState}
                            handleSubmit={handleSubmit}
                            hasReasons={hasReasons}
                            includeDelete={studentOeData !== null}
                            open={open}
                            preSchool={preSchool}
                            setOpen={setOpen}
                            setStudentInfoGate={setStudentInfoGate}
                            studentInfoGate={studentInfoGate}
                            studentNumber={studentNumber}
                        />
                        <ToastContainer style={{ width: "50%" }} />
                        <DcsdFormModal
                            ariaLabel="Popup modal listing 1-3 ways fix students Open Enrollment Record"
                            buttonCount="1"
                            button1Label="Back to Dashboard"
                            id="looksIncorrect"
                            title="Incorrect Student Information"
                            open={open}
                            setOpen={setOpen}
                        >
                            <InformationIncorrectListForm formState={formState} />
                        </DcsdFormModal>
                        {studentOeData && (
                            <DcsdDialog
                                actions={getDeleteActions()}
                                ariaLabel="Delete Open Enrollment Confirmation Dialog"
                                hasCloseX
                                id="delete"
                                onHide={() => {
                                    setOpen("false");
                                }}
                                open={open}
                                title="Delete Open Enrollment Confirmation"
                            >
                                <p>
                                    Please confirm that you would like to delete the Open Enrollment application for
                                    Student{" "}
                                    <span>
                                        {studentOeData?.studentDto?.firstName} {studentOeData?.studentDto?.lastName}
                                    </span>
                                    . This action will remove any associated Open Enrollment school choice data for the
                                    student.{" "}
                                </p>
                                {loader && (
                                    <div className="dialog-loader">
                                        Processing... Please Wait <LoadingSvg />
                                    </div>
                                )}
                            </DcsdDialog>
                        )}
                    </>
                )}
                {loader && <LoadingSvg />}
                <WindowAndPermissionDialog
                    id="out-of-bounds"
                    open={open}
                    setOpen={setOpen}
                    sorryTitle={sorryTitle}
                    sorryMessage={sorryMessage}
                />
            </div>
        </RbA>
    );
};

export default StudentNumber;
