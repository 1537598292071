import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import RbA from "../components/rba/RbA";
import { toast, ToastContainer } from "react-toastify";
import BreadCrumb from "../components/breadCrumb/BreadCrumb";
import StudentForm from "../components/formComponents/forms/StudentForm";
import FormReducer from "../utils/FormReducer";
import SaveBlock from "../components/saveBlock/SaveBlock";
import { GlobalContext } from "../components/contextProvider/ContextProvider";
import ValidationPatterns from "../utils/ValidationPatterns";
import GuardianAddressBlock from "../components/addressBlock/GuardianAddressBlock.jsx";
import StudentInfoDao from "../dao/StudentInfoDao";
import OpenEnrollmentDao from "../dao/OpenEnrollmentDao";
import UserDao from "../dao/UserDao";
import DcsdFormModal from "../components/modals/DcsdFormModal.jsx";
import InvalidDobModalForm from "../components/formComponents/modalForms/InvalidDobModalForm.jsx";
import { isValidDate } from "../utils/DateFormatter.jsx";
import Header from "../components/header/Header.jsx";
import NoEscape from "../utils/NoEscape.jsx";
import Toolbar from "../components/Toolbar.jsx";
import { gradeTranslator } from "../const/Translations.jsx";
import WindowAndPermissionDialog from "../components/modals/WindowAndPermissionDialog.jsx";
import { getTodayWithTime, getEpoch } from "../utils/DateFormatter.jsx";
import GradeBlock from "../components/gradeLevelBlock/GradeBlock.jsx";
import LoadingSvg from "../components/loadingSvg/LoadingSvg.jsx";

import "react-toastify/dist/ReactToastify.css";

/**
 * This will render the form and components for showing and submitting a new Open Enrollment record
 * Existing & External Guardian + New Student (IC data save to OE data)
 * @name StudentNew
 * @returns {JSX.Element}
 */
const StudentNew = () => {
    const { dispatch, state } = useContext(GlobalContext);
    const { oeActiveDistrictWindow, token, userDetails } = state;
    const navigate = useNavigate();

    const initialFormState = {
        addressLine1: "",
        addressLine2: "",
        birthdate: "",
        birthMonth: "",
        birthDay: "",
        birthYear: "",
        city: "",
        firstName: "",
        gender: "",
        gradeName: "",
        gradeApplying: "",
        key: null,
        lastName: "",
        middleName: "",
        searchAddress: "",
        schoolYearKey: oeActiveDistrictWindow ? oeActiveDistrictWindow.schoolYearKey : "",
        state: "",
        studentNumber: null,
        zip: ""
    };

    const allowedRolesArray = ["EXTERNAL_GUARDIAN", "GUARDIAN"];

    const [birthdateFlag, setBirthdateFlag] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [formState, formDispatch] = useReducer(FormReducer, initialFormState);
    const [guardianInfo, setGuardianInfo] = useState(null);
    const [hasArcAddress, setHasArcAddress] = useState(false); // this is (true/false) to disable fields in StudentForm.
    const [loader, setLoader] = useState(false);
    const [newOeStudent, setNewOeStudent] = useState(false);
    const [oeActionControls, setOeActionControls] = useState(null);
    const [open, setOpen] = useState("false");
    const [outOfBounds, setOutOfBounds] = useState(false);
    const [schoolYearRange, setSchoolYearRange] = useState(null);
    const [showDobResetButton, setShowDobResetButton] = useState(false);
    const [sorryMessage, setSorryMessage] = useState("");
    const [sorryTitle, setSorryTitle] = useState("");
    const [studentInfoGate, setStudentInfoGate] = useState(false);
    const [validatedBirthdayDto, setValidatedBirthdayDto] = useState(null);

    /**
     * NoEscape prevents the escape key from closing modals
     */
    NoEscape();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (["birthMonth", "birthDay", "birthYear"].includes(name)) {
            let newVal = value;
            setBirthdateFlag(false);
            if (!ValidationPatterns.digitOnly.test(value) && value !== "") {
                return false;
            }
            if (["birthDay", "birthMonth"].includes(name)) {
                newVal = value !== "" ? parseInt(value, 10) : "";
                if (name === "birthDay") {
                    if (newVal !== "" && newVal > 31) {
                        return false;
                    }
                } else if (name === "birthMonth") {
                    if (newVal !== "" && newVal > 12) {
                        return false;
                    }
                }
                if (newVal.toString().length > 0 && newVal > 0) {
                    newVal = newVal.toString().padStart(2, "0");
                }
                formDispatch({
                    type: "text",
                    field: name,
                    payload: newVal
                });

                return true;
            }
        }
        if (name === "zip") {
            if (!ValidationPatterns.digitOnly.test(value) && value !== "") {
                return false;
            }
        }
        formDispatch({
            type: "text",
            field: name,
            payload: value
        });

        return true;
    };

    const handlePreSubmit = () => {
        if (
            formState.firstName?.trim().toLowerCase() === userDetails?.givenName?.trim().toLowerCase() &&
            formState.lastName?.trim().toLowerCase() === userDetails?.surname?.trim().toLowerCase()
        ) {
            setOpen("duplicateParentName");
        } else {
            handleSubmit();
        }
    };

    /**
     * @name handleSubmit
     * @returns
     */
    const handleSubmit = () => {
        const newStudentData = {
            address: {
                addressLine1: formState.addressLine1,
                addressLine2: formState.addressLine2,
                city: formState.city,
                state: formState.state,
                zip: formState.zip
            },
            birthdate: formState.birthdate,
            birthMonth: formState.birthMonth,
            birthDay: formState.birthDay,
            birthYear: formState.birthYear,
            feederLocationKey: null,
            firstName: formState?.firstName,
            gender: formState.gender,
            gradeApplying: formState.gradeApplying,
            gradeName: formState.gradeName,
            gridCode: null,
            key: null,
            lastName: formState?.lastName,
            locationKey: null,
            middleName: formState?.middleName,
            oodSchoolName: null,
            oodSchoolCity: null,
            oodSchoolState: null,
            overrideStatus: "DEFAULT_LOCATION_CHECK",
            parentGuid: userDetails.uid,
            personId: null,
            schoolYearKey: oeActiveDistrictWindow?.schoolYearKey,
            studentNumber: null
        };

        if (token && !newOeStudent) {
            const options = {
                action: "oeNewStudentPost",
                data: newStudentData,
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload && payload !== null) {
                        toast.success("Student successfully added for Open Enrollment.", { autoClose: 3000 });
                        // change session storage so that we can redirect to school-choice
                        sessionStorage.setItem("orig-path", `/school-choice/${payload.key}`);
                        sessionStorage.setItem("student-key", JSON.stringify(parseInt(payload.key, 10)));
                        setTimeout(() => {
                            navigate(`/school-choice/${payload.key}`);
                        }, 3000);
                    }
                    if (errors) {
                        setEnableButton(false);
                        toast.error(errors[0], { autoClose: false });
                    }
                    setNewOeStudent(true); // -> disable text fields
                }
                setLoader(false);
            });
        }
    };

    /**
     * Reset the birthdate on button click
     */
    const birthdateReset = () => {
        setValidatedBirthdayDto(null); // reset the DTO to null
        setBirthdateFlag(false); // flag for waiting on api call to validate dob
        setShowDobResetButton(false); // shows reset button

        const tempFormState = formState;
        tempFormState.birthdate = "";
        tempFormState.birthDay = "";
        tempFormState.birthMonth = "";
        tempFormState.birthYear = "";
        tempFormState.gradeApplying = "";
        tempFormState.gradeName = "";

        formDispatch({
            type: "reset",
            payload: { ...tempFormState }
        });
    };

    /**
     * When formState.birthdate value is true make a call to BE service
     * that will validate and provide current & applying grades
     * @name getValidDOB
     * @param {string} dob
     * @callback
     */
    const getValidDOB = useCallback(
        (dob) => {
            const options = {
                action: "oeStudentBirthdate",
                params: {
                    birthDate: dob
                },
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload) {
                        setValidatedBirthdayDto(payload);
                    } else {
                        setValidatedBirthdayDto({ error: errors[0] });
                        setEnableButton(false);
                    }
                }
                setLoader(false);
            });
        },
        [token]
    );

    const showGradeBlock = () => {
        return formState && formState.gradeName?.length > 0 && formState.gradeApplying?.length > 0 && schoolYearRange;
    };

    /**
     * Get the schoolYearKey and dispatch to state (contextProvider)
     * this call is from OE current district window
     */
    useEffect(() => {
        if (token && !oeActiveDistrictWindow) {
            const options = {
                action: "oeActiveDistrictWindowRead",
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        dispatch({
                            type: "OeActiveDistrictWindow",
                            oeActiveDistrictWindow: payload
                        });
                    } else if (payload === null) {
                        setOutOfBounds(true);
                        setSorryTitle("Open Enrollment Window Closed");
                        setSorryMessage(
                            "The Open Enrollment window is now closed. Please return during the district's parent-access windows. Thank you."
                        );
                        setOpen("out-of-bounds");
                    }
                }
                setLoader(false);
            });
        }
    }, [dispatch, oeActiveDistrictWindow, token]);

    /**
     * Get IC guardian information (infinite campus)
     */
    useEffect(() => {
        if (token && userDetails && !guardianInfo) {
            const { roleDtos } = userDetails;
            const match = roleDtos.filter((obj) => obj.role === "GUARDIAN");
            if (match && match.length) {
                const options = {
                    action: "guardianInfoRead",
                    username: userDetails?.username,
                    token
                };
                setLoader(true);
                StudentInfoDao(options).then((response) => {
                    if (response) {
                        const { errors, payload } = response.data;

                        if (payload) {
                            setGuardianInfo(payload);

                            // If there is user info then dispatch it to formState
                            // and show the below fields on page load.
                            const { addressDto } = payload;
                            const { addressLine1, city, state, zip } = addressDto;
                            const tempFormState = formState;
                            tempFormState.addressLine1 = addressLine1;
                            tempFormState.city = city;
                            tempFormState.state = state;
                            tempFormState.zip = zip;
                        }
                        if (errors && errors.length) {
                            setGuardianInfo(null);
                            toast.error("There was a problem loading your data. Please reload the page and try again");
                        }
                    }
                    setLoader(false);
                });
            } else {
                setGuardianInfo(userDetails);
            }
        }
    }, [formState, guardianInfo, token, userDetails]);

    /**
     * Get the Action Controls for Open Enrollment
     */
    useEffect(() => {
        if (oeActiveDistrictWindow && token && !oeActionControls) {
            const options = {
                action: "oeActionControls",
                params: {
                    differentiator: oeActiveDistrictWindow.key
                },
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        const match = payload.filter((obj) => obj.action === "PARENT_REQUEST");
                        if (match && match.length > 0) {
                            setOeActionControls(payload);
                        } else {
                            setOutOfBounds(true);
                            setSorryTitle("Open Enrollment Window Closed");
                            setSorryMessage(
                                "The Open Enrollment window is now closed. Please return during the district's parent-access windows. Thank you."
                            );
                            setOpen("out-of-bounds");
                        }
                    }
                }
                setLoader(false);
            });
        }
    }, [token, oeActionControls, oeActiveDistrictWindow]);

    /**
     * Get the school year range from DB
     */
    useEffect(() => {
        if (token && oeActiveDistrictWindow && !schoolYearRange) {
            const nextSchoolYear = parseInt(oeActiveDistrictWindow.schoolYearKey, 10) + 1;
            const options = {
                action: "userSchoolYearRange",
                schoolYearKey: `${nextSchoolYear}`, // needs to be sent as a string
                token
            };
            setLoader(true);
            UserDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        setSchoolYearRange(payload);
                    }
                }
                setLoader(false);
            });
        }
    }, [oeActiveDistrictWindow, schoolYearRange, token]);

    /**
     * Check if the Open Enrollment window is open or closed
     * If outOfBounds === false, then the window is open
     *  */
    useEffect(() => {
        if (oeActiveDistrictWindow && !outOfBounds) {
            const { endDate, startDate } = oeActiveDistrictWindow;
            const today = getTodayWithTime();
            if (getEpoch(endDate) > getEpoch(today) && getEpoch(startDate) < getEpoch(today)) {
                setOutOfBounds(false);
            } else {
                setOutOfBounds(true);
                setSorryTitle("Open Enrollment Window Closed");
                setSorryMessage(
                    "The Open Enrollment window is now closed. Please return during the district's parent-access windows. Thank you."
                );
                setOpen("out-of-bounds");
            }
        }
    }, [oeActiveDistrictWindow, outOfBounds]);

    /**
     * This is only for showing the "Clear Birthdate Fields" button
     */
    useEffect(() => {
        if (formState.birthYear || formState.birthMonth || formState.birthDay) {
            setShowDobResetButton(true);
        } else {
            setShowDobResetButton(false);
        }
    }, [formState, showDobResetButton]);

    /**
     * Call getValid DOB if UI month, day, year is filled out.
     */
    useEffect(() => {
        const { birthDay, birthMonth, birthYear } = formState;
        if (parseInt(birthDay, 10) !== 0 || parseInt(birthMonth, 10) !== 0) {
            const studentBirthDate = `${birthYear}-${birthMonth}-${birthDay}`;
            if (studentBirthDate && studentBirthDate.length === 10 && !birthdateFlag) {
                if (isValidDate(studentBirthDate)) {
                    setBirthdateFlag(true);
                    getValidDOB(studentBirthDate);
                } else if (!isValidDate(studentBirthDate)) {
                    toast.error("Please enter a valid date for student birthdate (MM-DD-YYYY).", { autoClose: 5000 });
                }
            }
        }
    }, [birthdateFlag, formDispatch, formState, getValidDOB]);

    /**
     * If a valid birthdate is entered in UI then get the values from validateBirthdteDto
     * and dispatch  (inject) them into formState
     */
    useEffect(() => {
        if (validatedBirthdayDto && validatedBirthdayDto?.currentGrade) {
            const updatedFormState = formState;
            updatedFormState.gradeName = validatedBirthdayDto?.currentGrade;
            updatedFormState.gradeApplying = validatedBirthdayDto?.gradeApplying;
            updatedFormState.birthdate = `${formState.birthYear}-${formState.birthMonth}-${formState.birthDay}`;

            formDispatch({
                type: "reset",
                payload: { ...updatedFormState }
            });
        } else if (validatedBirthdayDto?.error) {
            setOpen("dobRange");
        }
        // eslint-disable-next-line
    }, [formDispatch, validatedBirthdayDto]);

    /**
     * This useEffect is checking the formState for validation.
     * if any of the fields below are not filled out, the save button will not be active
     */
    useEffect(() => {
        if (
            formState.addressLine1?.trim().length &&
            formState.birthdate &&
            formState.birthMonth &&
            formState.birthDay &&
            formState.birthYear &&
            formState.city?.trim().length > 0 &&
            formState.firstName?.trim().length > 0 &&
            formState.gender?.trim().length &&
            formState.lastName?.trim().length > 0 &&
            formState.state?.trim().length > 0 &&
            formState.zip?.trim().length > 0 &&
            gradeTranslator(formState.gradeName) !== "-" &&
            gradeTranslator(formState.gradeApplying) !== "-"
        ) {
            setEnableButton(true);
        } else {
            setEnableButton(false);
        }
    }, [formState, enableButton]);

    return (
        <RbA allowedRoles={allowedRolesArray} redirect="/notFound">
            <Header />
            <Toolbar />
            <BreadCrumb label="Open Enrollment > New Student Create" />
            <div className="gutter-90">
                <GuardianAddressBlock
                    formState={formState}
                    guardianInfo={guardianInfo}
                    label1="Guardian's"
                    name="new"
                    userDetails={userDetails}
                />
                <div className="mt-5 mb-3">
                    <hr />
                </div>
                <StudentForm
                    birthdateFlag={birthdateFlag}
                    birthdateReset={birthdateReset}
                    formState={formState}
                    formDispatch={formDispatch}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    hasArcAddress={hasArcAddress}
                    setHasArcAddress={setHasArcAddress}
                    setShowDobResetButton={setShowDobResetButton}
                    showDobResetButton={showDobResetButton}
                />
                {showGradeBlock() && <GradeBlock formState={formState} schoolYearRange={schoolYearRange} />}
            </div>
            <SaveBlock
                enableButton={enableButton}
                formState={formState}
                handleSubmit={handlePreSubmit}
                loader={loader}
                open={open}
                setOpen={setOpen}
                setStudentInfoGate={setStudentInfoGate}
                studentInfoGate={studentInfoGate}
                validatedBirthdayDto={validatedBirthdayDto}
            />
            <ToastContainer style={{ width: "50%" }} />
            {validatedBirthdayDto?.error && (
                <DcsdFormModal
                    ariaLabel="Birthdate Dialog"
                    birthdateReset={birthdateReset}
                    buttonCount="1"
                    button1Label="Close"
                    button1Labe2="button2"
                    id="dobRange"
                    open={open}
                    setOpen={setOpen}
                    title="Date of Birth Ineligible for Open Enrollment"
                >
                    <InvalidDobModalForm />
                </DcsdFormModal>
            )}
            {/* Show this if student (first / last) name matches guardian (first / last) names */}
            <DcsdFormModal
                ariaLabel="Pop up modal if user name is same as student name"
                button1Label="Names Should Not Match"
                button2Label="Accept and Proceed"
                handleSubmit={handleSubmit}
                id="duplicateParentName"
                loader={loader}
                open={open}
                setOpen={setOpen}
                title="Student/Guardian Names Match Warning"
            >
                <form>
                    <div>
                        <strong>
                            The first and last name you entered for the student matches the first and last name
                            associated to your guardian account.
                        </strong>
                    </div>
                    <br />
                    <div
                        className="d-flex justify-content-between"
                        style={{ maxWidth: "500px", borderBottom: "1px solid #0669B3" }}
                    >
                        <div>Student Name Entered:</div>
                        <div>
                            <strong>
                                {formState?.firstName} {formState?.lastName}
                            </strong>
                        </div>
                    </div>
                    <div
                        className="mt-3 d-flex justify-content-between"
                        style={{ maxWidth: "500px", borderBottom: "1px solid #0669B3" }}
                    >
                        <div>Guardian Account Name: </div>
                        <div>
                            {/* Below is for Existing Parent */}
                            <strong>
                                {guardianInfo?.givenName} {guardianInfo?.surname}
                            </strong>
                            {/* Below is for External (new) Parent */}
                            <strong>
                                {guardianInfo?.firstName} {guardianInfo?.lastName}
                            </strong>
                        </div>
                    </div>
                    <span
                        className="d-flex flex-row-reverse"
                        style={{ maxWidth: "500px", fontSize: ".8rem", fontStyle: "oblique" }}
                    >
                        Note: Case sensitivity is disregarded
                    </span>
                </form>
            </DcsdFormModal>
            {/* If student (first / last) name matches guardian (first / last) names and its not supposed to
                show this modal. Do not allow to close screen. Only option is back to dashboard button
            */}
            <DcsdFormModal
                ariaLabel="popup modal, if username and student name are same then seek help by calling number below"
                button1Label="Back to Dashboard"
                handleSubmit={handleSubmit}
                id="incorrect-name"
                loader={loader}
                open={open}
                setOpen={setOpen}
                title="Matching Names Correction"
            >
                <form>
                    <div>
                        You indicated that the external guardian account you created used your student&apos;s first name
                        and last name instead of your first name and last name. Before proceeding with Open Enrollment,
                        please contact the IT Support Center at{" "}
                        <strong>
                            <u>303-387-0001</u>
                        </strong>{" "}
                        to have your account information corrected.
                    </div>
                    <br />
                    <div>
                        <u>
                            Please note that the information you entered for the student will not be saved and will need
                            to be re-entered after your account information is corrected.
                        </u>
                    </div>
                </form>
            </DcsdFormModal>
            {loader && <LoadingSvg />}
            <WindowAndPermissionDialog
                id="out-of-bounds"
                open={open}
                setOpen={setOpen}
                sorryTitle={sorryTitle}
                sorryMessage={sorryMessage}
            />
        </RbA>
    );
};

export default StudentNew;
