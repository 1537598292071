// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.toolbar {
  border-top: 1px solid #0669B3;
  margin-top: 8px;
  height: 60px;
  width: 100%;
}
.toolbar .oe {
  position: relative;
  left: -85px;
  top: 5px;
  font-size: 2.5rem;
  color: #808080;
  letter-spacing: -8px;
}`, "",{"version":3,"sources":["webpack://./src/styles/global/Colors.scss","webpack://./src/styles/Toolbar.scss"],"names":[],"mappings":"AAAA;;;EAGI,sBAAA;ACCJ;;ADJA;;;EAGI,sBAAA;ACOJ;;AAPA;EACI,6BAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AAUJ;AATI;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,iBAAA;EACA,cDQA;ECPA,oBAAA;AAWR","sourcesContent":["*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n// Open Enrollment colors\n$lt-blue: #7DB7CC;\n// $dk-blue: #094166;\n$dk-blue: #19608f;\n$information: #00AEEF;\n$information-hover: #02BFFF;\n$text: #414042;\n$white: #EDEDED;\n$shadow: #525151;\n$red: #CB321A;\n$green: #C7CC68;\n\n$important: #FEBF31;\n$grey0: #EDf0F1;\n$grey1: #D1D3D4;\n$grey2: #A7A9AC;\n$grey3: #808080;\n$grey3a: #707070;\n$grey4: #636161;\n$grey4a: #444343;\n$pearl-blue: #0669B3;\n$pearl-blue-hover: #1786DB;\n$lt-pearl-blue: #63AEDE;\n$lt-pearl-blue-hover: #4589B3;\n","@import \"../styles/global/Colors\";\n@import \"../styles/global/globalMixins\";\n\n.toolbar {\n    border-top: 1px solid $pearl-blue;\n    margin-top: 8px;\n    height: 60px;\n    width: 100%;\n    .oe {\n        position: relative;\n        left: -85px;\n        top: 5px;\n        font-size: 2.5rem;\n        color: $grey3;\n        letter-spacing: -8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
