import React from "react";
import PropTypes from "prop-types";
import ActionButton from "../formInputs/buttons/ActionButton";

import "../../styles/SchoolCard.scss";

const SchoolCardStatic = ({
    formDispatch,
    formState,
    isMutable,
    oeStudentInfo,
    parentOffer,
    school,
    schoolFinalize
}) => {
    const { studentDto } = oeStudentInfo;
    const { gradeApplying } = studentDto;
    const { kinderChoice, kinderOffer, languageChoice, languageOffer, schoolDto, status } = school;
    const { educationalTrack, locationDto, locationId } = schoolDto;

    const getSchoolName = (name) => {
        let schoolName = name;
        if (educationalTrack !== "NOT_APPLICABLE") {
            const tmpArr = educationalTrack.split("_");
            const tmp = `${tmpArr[0].charAt(0).toUpperCase()}${tmpArr[0].slice(1).toLowerCase()} ${tmpArr[1].charAt(0).toUpperCase()}${tmpArr[1].slice(1).toLowerCase()}`;
            schoolName = `${name} - ${tmp}`;
        }

        return schoolName;
    };

    const getKinderWords = (arr) => {
        let strArr = arr.reduce((results, item) => {
            let str = item;
            if (str === "K_HALF_AM") {
                str = "Half Day AM";
            } else if (str === "K_HALF_PM") {
                str = "Half Day PM";
            } else if (str === "K_FULL") {
                str = "Full Day";
            }
            results.push(str);

            return results;
        }, []);

        return strArr.join(", ");
    };

    const getLanguageWords = (arr) => {
        let strArr = arr.reduce((results, item) => {
            const newWord = `${item.charAt(0).toUpperCase()}${item.substr(1).toLowerCase()}`;
            results.push(newWord);

            return results;
        }, []);

        return strArr.join(", ");
    };

    const getStatusWords = (status) => {
        let stat = status;
        switch (status) {
            case "ACCEPT_OFFER":
                stat = "Accepted Offer";
                break;
            case "CANCELLED_REQUEST":
                stat = "Cancelled Request";
                break;
            case "CHANGED_ENROLLMENT":
                stat = "Unenrolled";
                break;
            case "CHOICE_CREATED":
                stat = "School Choice Created";
                break;
            case "CHOICE_INCOMPLETE":
                stat = "Choice Incomplete";
                break;
            case "DENY_OFFER":
                stat = "Denied Offer";
                break;
            case "DENY_REQUEST":
                stat = "Denied Request";
                break;
            case "ENROLLED":
                stat = "Enrolled";
                break;
            case "INVALID":
                stat = "Invalid";
                break;
            case "MAKE_OFFER":
                stat = "Offer Made";
                break;
            case "MAKE_OFFER_PENDING":
                stat = "Make Offer Pending";
                break;
            case "NO_RESPONSE":
                stat = "No Response";
                break;
            case "WAIT_LIST":
                stat = "On Wait List";
                break;
            case "REQUEST":
                stat = "Request Made";
                break;
            case "UNENROLLED":
                stat = "Unenrolled";
                break;
            default:
                stat = "Unavailable";
                break;
        }

        return stat;
    };

    const handleClick = (e) => {
        const { name } = e.target;
        const tmpFormState = structuredClone(formState);
        const { schoolChoiceDetailDtos } = tmpFormState;
        const match = schoolChoiceDetailDtos.filter(
            (obj) => parseInt(obj.participatingSchoolId, 10) === parseInt(school.participatingSchoolId)
        );
        if (match && match?.length > 0) {
            match[0].status = name;
            if (name === "ACCEPT_OFFER") {
                const match2 = schoolChoiceDetailDtos.filter(
                    (obj) =>
                        parseInt(obj.participatingSchoolId, 10) !== parseInt(school.participatingSchoolId) &&
                        ["ACCEPT_OFFER", "DENY_OFFER", "MAKE_OFFER"].includes(obj.status)
                );
                if (match2 && match2?.length > 0) {
                    match2.map((obj) => {
                        obj.status = "DENY_OFFER";
                    });
                }
            }
        }
        formDispatch({
            type: "reset",
            payload: { ...tmpFormState }
        });
    };

    return (
        <div className="static-card-container">
            <div className="school-photo-container">
                <div className="school-logo">
                    <img
                        alt={locationDto.name}
                        height="130"
                        src={`${window.location.origin}/images/${locationId}.png`}
                        width="130"
                    />
                </div>
            </div>
            <div className="school-info-container">
                <div className="school-name">{getSchoolName(locationDto.name)}</div>
                <div className="school-grade">
                    Applying for Grade:
                    <span>{gradeApplying}</span>
                </div>
                <div className="static-options">
                    {languageChoice && (
                        <>
                            Language Options:
                            <span>{getLanguageWords(languageChoice)}</span>
                        </>
                    )}
                </div>
                {parentOffer && (
                    <div className="static-options">
                        {languageChoice && languageOffer && (
                            <>
                                Language Offered:
                                <span style={{ backgroundColor: "yellow", fontWeight: "600" }}>
                                    {getLanguageWords([languageOffer])}
                                </span>
                            </>
                        )}
                    </div>
                )}
                <div className="static-options">
                    {kinderChoice && (
                        <>
                            Kindergarten Time Selections:
                            <span>{getKinderWords(kinderChoice)}</span>
                        </>
                    )}
                </div>
                {parentOffer && (
                    <div className="static-options">
                        {kinderChoice && kinderOffer && (
                            <>
                                Kinder Offered:
                                <span style={{ backgroundColor: "yellow", fontWeight: "600" }}>
                                    {getKinderWords([kinderOffer])}
                                </span>
                            </>
                        )}
                    </div>
                )}

                <div className="status-container">
                    {(schoolFinalize || parentOffer) && (
                        <>
                            Status:
                            <span>{getStatusWords(status)}</span>
                        </>
                    )}
                </div>
                <div className="center-button">
                    {isMutable && ["ACCEPT_OFFER", "DENY_OFFER", "MAKE_OFFER"].includes(status) && (
                        <>
                            <ActionButton
                                label="Accept"
                                name="ACCEPT_OFFER"
                                onClick={handleClick}
                                status={school.status === "ACCEPT_OFFER" ? "COMPLETE" : null}
                            />
                            <ActionButton
                                label="Decline"
                                name="DENY_OFFER"
                                onClick={handleClick}
                                status={school.status === "DENY_OFFER" ? "NOT_STARTED" : null}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

SchoolCardStatic.propTypes = {
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    isMutable: PropTypes.bool,
    oeStudentInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
    parentOffer: PropTypes.oneOfType([PropTypes.object]),
    school: PropTypes.oneOfType([PropTypes.object]).isRequired,
    schoolFinalize: PropTypes.oneOfType([PropTypes.object])
};

SchoolCardStatic.defaultProps = {
    isMutable: false,
    parentOffer: null,
    schoolFinalize: null
};

export default SchoolCardStatic;
