import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon/Icon";
import { GlobalContext } from "../contextProvider/ContextProvider";
import StudentInfoDao from "../../dao/StudentInfoDao";

/**
 * Return a student's picture, or if it doesn't exist, return a default placeholder
 * @name StudentImage
 * @param {string} className
 * @param {string} height
 * @param {string} width
 * @return {JSX.Element}
 */
const StudentImage = ({ className, height, width }) => {
    const { dispatch, state } = useContext(GlobalContext);
    const { schoolYearDto, studentImageUrl, icStudentInfo, token } = state || {};

    const [retrieveUrl, setRetrieveUrl] = useState(true);

    /**
     * get the student's picture
     */
    useEffect(() => {
        if (schoolYearDto && icStudentInfo && token && !studentImageUrl) {
            if (retrieveUrl) {
                setRetrieveUrl(false);
                const options = {
                    action: "studentImageRead",
                    ignoreError: true,
                    schoolYearKey: schoolYearDto.key,
                    studentNumber: icStudentInfo.studentNumber,
                    token
                };
                StudentInfoDao(options).then((response) => {
                    if (response) {
                        const urlCreator = window.URL || window.webkitURL;
                        dispatch({
                            type: "StudentImageUrl",
                            studentImageUrl: urlCreator.createObjectURL(
                                new Blob([response.data], {
                                    type: "image/jpeg"
                                })
                            )
                        });
                    }
                });
            }
        }
    }, [dispatch, icStudentInfo, retrieveUrl, schoolYearDto, studentImageUrl, token]);

    return (
        <div className="d-flex flex-row me-2">
            {studentImageUrl ? (
                <img
                    alt={`${icStudentInfo.firstName} ${icStudentInfo.lastName}`}
                    className={className}
                    height={`${height}px`}
                    src={studentImageUrl}
                />
            ) : (
                <Icon iconName="NO_STUDENT_IMAGE" fill="#19608F" height={height} width={width} />
            )}
        </div>
    );
};

StudentImage.propTypes = {
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
};

StudentImage.defaultProps = {
    className: "",
    height: 90,
    width: 90
};

export default StudentImage;
